import React from 'react'
import {Container, Typography, Grid} from '@material-ui/core'
import {H1} from '../template/headers'
import Reveal from '../reveal'
import Image from '../common/image'
import RightBlock from './right-block'
import Breadcrumbs from '../breadcrumbs'
import DateFormat from '../common/date'

const defaultImage = "no-image.png";
const imageRoot = process.env.REACT_APP_MEDIA + "/images/content/"

class ArticleTemplate extends React.Component{

        
    render() {

        const {
            title, 
            subtitle, 
            html, 
            images = [ defaultImage ], 
            publishing_id, 
            published_from,
            breadcrumbs
        } = this.props

        const image =  `${imageRoot}${Array.isArray(images) && images.length > 0 ? images[0] : defaultImage}`

        return(
            <>
                { breadcrumbs && <Breadcrumbs items={breadcrumbs} /> }
                <Container className="blog-article">
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={9}>                    
                            <H1>{title}</H1>
                            {subtitle ? <Typography variant="subtitle1">{subtitle}</Typography> : "" }  
                            {published_from && <Typography variant="subtitle1"><DateFormat value={published_from} /></Typography>}                      
                            <Image image={image} imageClass="ratio-image my-2"/>
                            <Typography variant="body1" dangerouslySetInnerHTML={{__html: html}}></Typography>                                                    
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Reveal>
                                <RightBlock current_id={publishing_id} />
                            </Reveal>
                        </Grid>
                    </Grid>
                    
                </Container>
            </>
        
        )
    }
}

export default ArticleTemplate