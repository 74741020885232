import React from 'react'
import {Container, Typography, Grid} from '@material-ui/core'
import {H} from '../template/headers'
import Reveal from '../reveal'

// media renderers
import Image from '../common/image'
import Video from '../common/video'
import Youtube from '../youtube-multi'

import './card-template.scss'

const defaultImage = "no-image.png";
const imageRoot = process.env.REACT_APP_MEDIA + "/images/content/"

const Title = props => (
    <>
        <H level={props.header_level || 2} className="pt-0 mt-0">{props.title}</H>
        {props.subtitle ? <Typography variant="subtitle1">{props.subtitle}</Typography> : "" }                        
    </>
)


class ArticleTemplate extends React.Component{

        
    render() {

        let {
            title, 
            subtitle, 
            
            html, 
            images = [ defaultImage ],                         
            video, 
            youtube,
            playerVars = {
                enablejsapi: 1,                
                mute: true,                
            },
            

            media_width = 4, 
            title_position = "top",
            media_position = "left",
            show_title = true,
            show_media = true,
            show_text = true,

            header_level = 2,
            full_width_level = "sm",
            

            // grid props
            spacing = 4,

            // css
            card_class = ""
        } = this.props

        const image =  `${imageRoot}${Array.isArray(images) && images.length > 0 ? images[0] : defaultImage}`
        // console.log("media_position", media_position, ["top", "bottom"].includes( media_position )  )

        // if it's image top or image bottom, than it's with 12, both of them.
        media_width = ["top", "bottom"].includes( media_position ) ? 12 : media_width
        const text_width = show_media ? 12 - media_width : 12;

        const text_props = 
            show_media ?
                show_text ? 
                    { xs: 12, ...{...full_width_level === "xs" ? {} : { [full_width_level] : text_width } } } 
                    : {} 
            : { xs: 12 };

        const media_props = 
            show_text ?
                show_media ? 
                    { xs: 12, ...{...full_width_level === "xs" ? {} : { [full_width_level] : media_width } } } 
                    : {} 
            : { xs: 12 };

        return(
            // class name consist of:
            // - media possition
            // - title position
            // - additional class, if passed
            // Card contents can be styled according to this.
            <div className={`article-card media-${media_position} title-${title_position}${card_class ? ` ${card_class}` : "" }`}>
                <Grid container spacing={spacing} className="card-wrapper">                                
                    { show_title && title_position === "top" ? 
                    <Grid item xs={12} className="title-container"><Title {...{title, subtitle, header_level}} /></Grid> 
                    : "" }            
                    { show_media ?                     
                        // <Grid item xs={12} sm={media_width} className="media-container">
                        <Grid item {...media_props} className="media-container">
                            { video && video.length > 0 ?  <Video /> : 
                                youtube && youtube.length > 0  ? <Youtube id={youtube} playerVars={playerVars} /> : 
                             image ? <Image image={image} imageClass="ratio-image"/>  : "" 
                             }                
                        </Grid>
                    : ""
                    }
                    { show_text ? 
                    // <Grid item xs={12} sm={text_width} className="text-container">
                    <Grid item {...text_props} className="text-container">
                        { show_title && title_position === "top-text" ? <Title {...{title, subtitle, header_level}} /> : "" }            
                        <Typography component="div" variant="body1" dangerouslySetInnerHTML={{__html: html}}></Typography>                                                    
                    </Grid>
                    : "" 
                    }
                </Grid>                
            </div>
        
        )
    }
}

export default ArticleTemplate