import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { Typography, Grid, Paper, Container } from '@material-ui/core';
import { Link } from 'react-router-dom'

import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import GridListTile from '@material-ui/core/GridListTile';

import Reveal from '../../reveal'

import {Pageview, Info} from '@material-ui/icons'
import Image from '../../horizontal-card/image'

import {connect} from 'react-redux'
import A from '../../../redux/actionTypes'

import './course-experiment.scss'




class ExperimentListItem extends React.Component {
  
  popUp = item => {
    const {showModal,closeModal} = this.props
    const {title, largeImage, description, link, code } = item
    console.log(" pop up", item)
    showModal( (
        <Container className="pop-up-experiment">
            <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                    <Image image={largeImage} />         
                </Grid>
                <Grid item xs={12} md={8}>
                    <Grid container>                            
                        <Grid item xs={12}>
                            <Typography variant="h3" color="primary">{title}</Typography>
                            <Typography variant="h5">{code}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography >{description}</Typography>
                            <hr/>
                            <Button variant="contained" color="primary" component={Link} to={link} onClick={closeModal}> <Pageview/> To experiment page</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    ))
  }
  

  render(){

    const {item, height = 140, showPopup} = this.props
  
    
    const { name, title, code, category, categoryTitle, image } = item
    
      console.log("Image: ", image)
  
    return (
      <Reveal className="h-100">
      <Card className="card-root"> 
      {/* //style={{bacgkroundColor: theme.palette.secondary.main, height: "100%"}}> */}
        
        <CardActionArea className="card-action-area" onClick={() => this.popUp( item )}>
          <CardMedia
            component="img"
            alt={title}
            height={height}
            src={image}
            title={title}
          />
          <CardContent className="card-content">
            <Typography variant="body1" color="primary" gutterBottom align="left"> 
              {title}
            </Typography>         
            <Typography variant="" color="textSecondary" align="left">Code: {code}</Typography>
          </CardContent>
        </CardActionArea>      
      </Card>
      </Reveal>
    );
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
      showModal: content => dispatch( {type: A.SHOW_MODAL, payload: content} ),
      closeModal: () => dispatch( {type: A.HIDE_MODAL })
  };
};
export default connect(
  null,
  mapDispatchToProps
)(ExperimentListItem)