import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { Typography, Grid, Paper } from '@material-ui/core';
import { Link } from 'react-router-dom'

import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import GridListTile from '@material-ui/core/GridListTile';

import Reveal from '../reveal'

import './experiments.scss'

const useStyles = makeStyles(theme => ({

  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  card: {
    root: {
      backgroundColor: theme.palette.secondary.main,
      
    }
  },
}));




export default function ExperimentListItem(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { item, imageRoot } = props

  const height = props.height || 140

  console.log("item", item)
  const { name, title, code, category, categoryTitle } = item

  const link = `/experiments/${category}/${name}`
  const image = Array.isArray(item.images) && item.images.length ? item.images[0] : "no-image.png"

  return (
    <Reveal className="h-100">
    <Card className="card-root"> 
    {/* //style={{bacgkroundColor: theme.palette.secondary.main, height: "100%"}}> */}
      
      <CardActionArea className="card-action-area" component={Link} to={link}>
        <CardMedia
          component="img"
          alt=""
          height={height}
          image={`${imageRoot}${image}`}
          title={title}
        />
        <CardContent className="card-content">
          <Typography variant="body1" color="primary" gutterBottom align="left"> 
            {title}
          </Typography>         
          <Typography variant="" color="textSecondary" align="left">Code: {code}</Typography>
        </CardContent>
      </CardActionArea>      
      {/* <CardActions className="card-actions">
        <Button size="small" color="primary" component={Link} to={link} >
          Learn More
            </Button>
      </CardActions>       */}
    </Card>
    </Reveal>
  );
}