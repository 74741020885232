import React from 'react'
import { Container, Typography, Box, Grid, CircularProgress } from '@material-ui/core'
import List from '../../list/simpleList'
import Service from '../../../services/sets'
import P404 from '../404'
import Hero from '../../hero/hero.display'
import CourseItem from '../../horizontal-card/index'

const mediaRoot = process.env.REACT_APP_MEDIA

export default class Lab extends React.Component {

    state = {
        id: null,
        lab: null,
        courses: null
    }

    // static getDerivedStateFromProps(props, state) {
    //     const { id } = props.match.params

    //     if (id) {
    //         const lab = labs[id] || null
    //         return { id, lab }
    //     }
    //     return null
    // }

    componentDidMount(){
        const { id } = this.props.match.params

        if (id) {
            this.fetchData(id)
            // const lab = labs[id] || null
            // return { id, lab }
        }
    }

    componentDidUpdate(prevProps){
        if( prevProps.match.params.id !== this.props.match.params.id ){
            this.fetchData(this.props.match.params.id)
        }
    }

    fetchData = async( id ) => {
        const lab = await Service.getOne(id)
        if( lab ){
            this.setState({id, lab})
            this.fetchCourses(id)

        } else {
            this.setState({id:null, lab: false })
        }
    }

    fetchCourses = async( id ) => {
        const courses = await Service.getCourses(id)
        if( courses ){
            console.log("courses:",courses)
            this.setState({courses})
        } else {
            this.setState({courses: false })
        }
    }


    render() {
        const { id, lab, courses } = this.state

        if( lab === null ) return <><CircularProgress /></>
        if( lab === false ) return <><Typography variant="h4" color="error">Error fetching data, please try reloading the page.</Typography></>

        if (lab) {
            const { title, description, short_description, image } = lab
            return (
                <div>                
                <Box>
                    <Hero title={title} 
                    subtitle={"Training Lab"} 
                    images={[image]} 
                    description={short_description} 
                    imgRoot={process.env.REACT_APP_MEDIA + "/images/"}
                    />
                    <Container>
                        <Typography variant="body1">{ description || "" }</Typography>
                    </Container>
                    <Container>
                        <Typography variant="h3" color="primary">Courses</Typography>
                        {courses ?                         
                            <List items={courses}  ItemComponent={CourseItem} />
                            // <List items={courses.map( course => ({item: {...course, 
                            //     link: `/labs/${course.set_slug}/${course.slug}`, 
                            //     image: mediaRoot + (course.images[0] || "/images/content/no-image.png"),
                            //     subtitle: course.grade_from && course.grade_to ? `Grade ${course.grade_from} to ${course.grade_to}` : null,
                            // } }))}  ItemComponent={CourseItem}/>
                        // courses.map( item => (<Typography >{item.title}</Typography>) )
                            : courses === null ? <CircularProgress/> : <Typography color="error">Error fetching courses</Typography>
                        }
                    </Container>
                </Box>
                </div>

            )
        } else {
            return <P404 />
        }


    }
}