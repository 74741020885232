import axios from  'axios'

const mediaRoot = process.env.REACT_APP_MEDIA

export default class SetsService {

    static async getAll(){

        if( !SetsService.sets ){
            try{
                let {data} = await axios.get(process.env.REACT_APP_API + "/sets")
                
                data = data.map( item => { return {...item, images: item.images ? JSON.parse(item.images) : [] } } )
                
                SetsService.sets = data

                // console.log(data)
                return data
            } catch ( e ){
                //TODO: log
                console.log(e)
                SetsService.error = e.response ? e.response.data : e.message
                return false;
            }
        } else {
            return SetsService.sets
        }

    }

    
    
    static async getCourses( slug ){

        if( !SetsService.relatedCourses[ slug ] ){
            try{
                let {data} = await axios.get(process.env.REACT_APP_API + "/sets/courses/" + slug)
                
                data = data.map( course => ({item: {...course, 
                    link: `/labs/${course.set_slug}/${course.slug}`, 
                    image: mediaRoot + (course.images[0] || "/images/content/no-image.png"),
                    subtitle: course.grade_from && course.grade_to ? `Grade ${course.grade_from} to ${course.grade_to}` : null,
                } }))
                SetsService.relatedCourses[ slug ] = data

                // console.log(data)
                return data
            } catch ( e ){
                //TODO: log
                console.log("Error fetching related products:", e.code, e.message)    
                SetsService.error = e.response ? e.response.data : e.message
                return false;
            }
        } else {
            return SetsService.relatedCourses[ slug ]
        }

    }

    static async getOne( id ){
        // if( !SetsService.sets ){
        //     await ExperimentsService.getAll()
        //     return ExperimentsService.sets.find( item => item.code === id ) || {}
        // } else {
        //     return ExperimentsService.sets.find( item => item.code === id ) || {}
        // }

        if( !SetsService.items[id] ){
            try{
                let {data} = await axios.get(process.env.REACT_APP_API + "/sets/item/"+id)
                
                // data = data.map( item => { return {...item, images: JSON.parse(item.images) } } )
                console.log(data)
                SetsService.items[id] = data

                // console.log(data)
                return SetsService.items[id]
            } catch ( e ){
                //TODO: log
                console.log(e)
                SetsService.error = e.response ? e.response.data : e.message
                return false;
            }
        } else {
            return SetsService.items[id]
        }
    }

}

SetsService.categories = null
SetsService.error = null
SetsService.items = {}
SetsService.relatedCourses = {}