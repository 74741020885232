import React from 'react'
import {Container, Grid, Typography, CircularProgress } from '@material-ui/core'
import {Link} from 'react-router-dom'
import './home.scss'
// import widgets from './widgets'
import ModulesService from '../../../services/modules'
import Display from '../../display4'
import Blog from '../../blog/categoryPreview'
import BlogItem from '../../blog/item'
import Fade from '../../reveal'
import Service from '../../../services/blog'
import Item from '../../blog/item'
import {connect} from 'react-redux'


import Card from '../../blog/card-template'




const classes = {
    "bg-dark" : {
        backgroundImage: process.env.REACT_APP_MEDIA + "/content/bg-dark.jpg",
        backgroundSize: "cover",
        backgroundPosition: "center"
    }
}

class Home extends React.Component {
    
    state = {
        article: null,
        widgets: null,
        widgetsData: null,
        news: null,
        blog: null,
        widgetsError: null,
        newsError: null,
        blogError: null
    }
    
    componentDidMount(){
        this.fetchArticle()
        this.fetchWidgets()     
        this.fetchNews()
        // this.fetchBlog()        
    }

    fetchArticle = async () => {
        const article = await Service.getStatic("home")        
        this.setState({article})            
    }

    fetchWidgets = async () => {
        const widgetsData = await ModulesService.get("front-widgets")
        let widgetsError
        if( widgetsData ){
            let widgets;
            try {
                widgets = widgetsData ? JSON.parse( widgetsData.config ) : null
            } catch (e){
                widgets = false;            
                widgetsError = "Error fetching data, please try reloading the page."
            }

            this.setState({widgets, widgetsError})            
        } else {
            widgetsError = ModulesService.error
            this.setState({widgets: false,  widgetsError })
        }
    }
    fetchNews = async () => {
        const news = await Service.getCategoryPreview("news", 1, 3)
        if( news ){
            this.setState({news})            
        } else {
            this.setState({newsError: Service.error})
        }
    }

    fetchBlog = async () => {
        const blog = await Service.getCategoryPreview("blog", 1, 3)
        if( blog ){
            this.setState({blog})            
        } else {
            this.setState({blogError: Service.error})
        }
    }

    render() {
        const {widgets , widgetsError, newsError, blogError, news, blog, article } = this.state
        const { tpl } = this.props
        // let widgets;
        // try {
        //     widgets = widgetsData ? JSON.parse( widgetsData.config ) : null
        // } catch (e){
        //     widgets = false;            
        //     widgetsError = "Error fetching data, please try reloading the page."
        // }
        // console.log(widgets)
        return (
            <Container>
                <Fade>
                    <Grid container spacing={2} className={`tpl-${tpl}`}>
                        <Grid item xs={12}>
                            {article === null ? <CircularProgress/> : article === false ? <Typography variant="h3" color="error">Network error. Please, reload the page.</Typography> :                        
                                <Card {...article} show_media={false} show_title={false} card_class="welcome mt-4"/>                        
                            }
                        </Grid>
                        {widgets ? 

                            tpl === 0 ?
                            <>                                
                                <Grid item xs={12} md={6}>
                                    <Display  data={widgets.neulog}/>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Display  data={widgets.sense}/>
                                </Grid>
                            </> :
                            <>                                
                                <Grid item xs={12} >
                                    <Display  data={widgets.neulog}/>
                                </Grid>
                                <Grid item xs={12} >
                                    <Display  data={widgets.sense}/>
                                </Grid>
                            </> 
                            : widgets === null ?
                            <Grid item xs={12}>
                                <CircularProgress/>                                            
                            </Grid>             
                            :
                            <Grid item xs={12}>
                                <Typography variant="h3" color="error">{widgetsError}</Typography>                                            
                            </Grid>             
                        }

                            <Grid item xs={12}>   
                                <Blog 
                                    showPagination={false}
                                    items={news}
                                    titleClass="bg-texture01 mb-1"
                                    itemProps={{xs:12, sm:4 }}
                                    ItemComponent = {BlogItem}
                                    title={"NeuLog News"}
                                    category={{publish_category_id: 2}}
                                    readMoreLink="/about/news"
                                    bottomLinkText="More NeuLog News"
                                    bottomLinkClass="bottom-link bg-texture01"
                                />
                            </Grid>
                        {/* <Grid item xs={12} md={4}>   
                            <Blog 
                                showPagination={false}
                                items={news}
                                titleClass="bg-texture01"
                                itemProps={{xs:12}}
                                title={"NeuLog News"}
                                category={{publish_category_id: 2}}
                                readMoreLink="/about/news"
                                bottomLinkText="More NeuLog News"
                                bottomLinkClass="bottom-link bg-texture01"
                            />

                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Blog 
                                showPagination={false}
                                items={blog}
                                titleClass="bg-primary-light bg-texture01"
                                itemProps={{xs:12, sm: 4}}
                                title={"NeuLog Blog"}
                                category={{publish_category_id: 2}}
                                readMoreLink="/about/blog"
                                bottomLinkText="More NeuLog Blog"
                                bottomLinkClass="bg-primary-light bottom-link bg-texture01"
                            />
                        </Grid> */}
                    </Grid>
                </Fade>             
            </Container>

            // <Container>
            //     <p>Welcome to the new age of experimental data acquisition with fully autonomous logger sensors.</p>
            //     <p>NeuLog logger sensors offer a myriad of didactic tools to meet your needs in the field of scientific studies.</p>
            //     <p>NeuLog is a set of independent computer based modules, complete with a data logger, flash memory and a sensor
            //       – all in one single smart unit. This autonomous plug-and-play concept allows for an easy, precise and fun way
            //   of conducting classroom and field experiments.</p>                
            // </Container>            
        )
    }
}

const mapStateToProps = (state) => {
    const { tpl } = state
    return { tpl };
}


export default connect(
    mapStateToProps,
    null
)(Home)