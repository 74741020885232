import React from 'react'
import { Typography } from '@material-ui/core'
import List from './itemList'
import itemComp from './productListItem'


export default class multiListItem extends React.Component {

    
    render(){

        let { 
            title, description, items, wrapperComponent, 
            titleVariant, descriptionVariant, imageRoot, 
            itemComponent,
            itemsPerRow,
            bgcolor, 
            descriptionClass = "py-3",      
            showSearch,
            searchProps                  
        } = this.props.item

        const {height = "140px"} = this.props
        
        wrapperComponent = wrapperComponent || "div"
        titleVariant = titleVariant || "h3"
        imageRoot = imageRoot || process.env.REACT_APP_MEDIA 
        descriptionVariant = descriptionVariant || "body1"
        itemComponent = itemComponent || itemComp        
        bgcolor = bgcolor || ""
        
        let xs = 12, sm = 6, md, lg, xl
        
        switch( itemsPerRow ){
            case 6:
                xl = 2
                lg = 2
                md = 4
                sm = 4
                xs = 6
                break;
            case 4:
                xl = 3
                lg = 3
                md = 4
                break;
            case 3:
            default:
                xl = 4
                lg = 4
                md = 4
                break;
        }

        


        return(
            <div component={wrapperComponent}>
                <Typography variant={titleVariant} color="textSecondary">{title}</Typography>
                <Typography variant={descriptionVariant} className={descriptionClass} >{description}</Typography>
                
                    <List                 
                    height={height}
                    xs={xs} sm={sm} md={md} lg={lg} xl={xl}
                    items={items} 
                    imageRoot={imageRoot} 
                    ItemComponent={itemComponent} 
                    bgcolor={bgcolor}
                    showSearch={showSearch}
                    searchProps={searchProps}
                    />
                
            </div>
        )

    }
}