import axios from 'axios'

/**
 * A base client-side service class with maneagable cache and axios as .api property.
 * To create a descendant:
 * class NewClass extends baseClientService{
 *  ...
 * }
 * 
 * baseClientService.extend(NewClass) // this effectively copies the properties and methods
 * 
 */
class baseClientService {    

    static cache(index, value){
        this._CACHE[index] = value
    }
  
    static getCached (index, defaultValue ){ return this.cacheExists(index) ? this.Copy( this._CACHE[index] ) : defaultValue}

    static cacheExists(index) { return this._CACHE.hasOwnProperty(index)}

    static clearCache( index ) { 
        if( index ) {
            if( this.cacheExists(index) ){
                delete this._CACHE[index]
            }
        } else {
            this._CACHE = {}             
        }
    }
    static Copy( x ){
        return JSON.parse( JSON.stringify(x) );
    }

    static printCache(){ console.debug( "\n\n\nCache:\n", this._CACHE) }
    
    static Error( message ){ this.error = message }

    static extend( ancestor ) {
        Object.assign( ancestor, this )
    }

}

baseClientService._CACHE = {}
baseClientService.api = axios

export default baseClientService