import React from 'react'
import { Typography, Container } from '@material-ui/core'

export default function page404(props){
    return(
        <Container>
            <Typography color="primary" variant={"h2"}>Error 404: Page not found</Typography>
            <Typography variant="body1">This page does not exist, probably it was misplaced or simply banished into oblivion. We're deeply sorry for this inconvenience.</Typography>
            {props.children}
        </Container>
    )
}