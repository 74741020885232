import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import {Typography, Grid} from '@material-ui/core';
import {Link} from 'react-router-dom'

import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import GridListTile from '@material-ui/core/GridListTile';

const useStyles = makeStyles(theme => ({
       
    icon: {
      color: 'rgba(255, 255, 255, 0.54)',
    },
    card: {    
      height: "100%"
    },
  }));



export default function ExperimentCatItem(props) {
  const classes = useStyles();

  const {item, imageRoot} = props

  console.log("item", item)
  const {slug, title, short_description, additional = {} } = item

  let image =  item.image || "no-image.png"

  const link = `/labs/${slug}`

  const variant = additional.variant || "normal"

  let headerComponent = "h2"
  let headerVariant = "h5"
  let subtitleVariant = "subtitle"

  switch(variant){
    case "small":  
    default:    
          headerVariant = "body2"
          headerComponent = "div"
          subtitleVariant = "caption"
        break;
  }

  
  return (
   
    
        // <Card className={classes.card} >
        <Card >
        <CardActionArea component={Link} to={link}>
            <CardMedia
            component="img"
            alt=""
            height="140"
            image={`${imageRoot}${image}`}
            title={title}
            />
            <CardContent>
            <Typography variant="h3" color="primary" className="my-0">
                {title}
            </Typography>
            <Typography gutterBottom variant={subtitleVariant}>
                {short_description}
            </Typography>
            {/* <Typography variant="body2" color="textSecondary" component="p">
                Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
                across all continents except Antarctica
            </Typography> */}
              {/* <Button size="small" color="primary" variant="contained" component={Link} to={link} >
              Learn More
              </Button> */}
            </CardContent>
            
        </CardActionArea>
        {/* <CardActions>        
            
        </CardActions> */}
        </Card>
    
  );
}