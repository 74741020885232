import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import Category from './category'
import './preview.scss'

class Preview extends React.Component{

    render(){
        const categoryProps = {
            ...this.props, 
            itemClass:"category preview"
        }
        return <Category {...categoryProps}/>
    }
}

export default Preview