import axios from  'axios'

export default class ExperimentsService {

    static async getAll(){

        if( !ExperimentsService.experiments ){
            try{
                let {data} = await axios.get(process.env.REACT_APP_API + "/experiments/all")
                
                data = data.map( item => { return {...item, images: JSON.parse(item.images) } } )
                
                ExperimentsService.experiments = data

                // console.log(data)
                return data
            } catch ( e ){
                //TODO: log
                console.log(e)
                ExperimentsService.error = e.response ? e.response.data : e.message
                return false;
            }
        } else {
            return ExperimentsService.experiments
        }

    }

    static async getCategories(){

        if( !ExperimentsService.categories ){
            try{
                let {data} = await axios.get(process.env.REACT_APP_API + "/experiments/categories")
                
                // data = data.map( item => { return {...item, images: JSON.parse(item.images) } } )
                
                ExperimentsService.categories = data

                // console.log(data)
                return data
            } catch ( e ){
                //TODO: log
                ExperimentsService.error = e.response ? e.response.data : e.message
                console.log("Error fetching categories:", e.code, e.message)    
                return false;
            }
        } else {
            return ExperimentsService.categories
        }

    }
    
    static async getRelatedProducts( id ){

        if( !ExperimentsService.relatedProducts[ id ] ){
            try{
                let {data} = await axios.get(process.env.REACT_APP_API + "/experiments/related/" + id)
                ExperimentsService.relatedProducts = data

                // console.log(data)
                return data
            } catch ( e ){
                //TODO: log
                console.log("Error fetching related products:", e.code, e.message)    
                ExperimentsService.error = e.response ? e.response.data : e.message
                return false;
            }
        } else {
            return ExperimentsService.relatedProducts[ id ]
        }

    }

    static async getOne( id ){
        // if( !ExperimentsService.experiments ){
        //     await ExperimentsService.getAll()
        //     return ExperimentsService.experiments.find( item => item.code === id ) || {}
        // } else {
        //     return ExperimentsService.experiments.find( item => item.code === id ) || {}
        // }

        if( !ExperimentsService.items[id] ){
            try{
                let {data} = await axios.get(process.env.REACT_APP_API + "/experiments/by-slug/"+id)
                
                // data = data.map( item => { return {...item, images: JSON.parse(item.images) } } )
                console.log(data)
                ExperimentsService.items[id] = data[0]

                // console.log(data)
                return data
            } catch ( e ){
                //TODO: log
                console.log(e)
                ExperimentsService.error = e.response ? e.response.data : e.message
                return false;
            }
        } else {
            return ExperimentsService.items[id]
        }
    }

}

ExperimentsService.categories = null
ExperimentsService.error = null
ExperimentsService.items = {}
ExperimentsService.relatedProducts = {}