import React from 'react'
import {Link} from 'react-router-dom'
import { Box, Typography, Button } from '@material-ui/core'
import {ChevronRight} from '@material-ui/icons'




const ProductCategory = props => {


    const {html, read_more, read_more_text = "Read more" , title, subtitle} = props

    // if( html || read_more ){
        return(
            <Box className="mb-4">
                <Typography variant="h3" color="primary">{title}{subtitle ? ` ${subtitle}` : "" } </Typography>
                {html ? <div dangerouslySetInnerHTML={{__html: html}}></div> : "" }
                {read_more && read_more !== "" ? 
                <div>
                    <Link component={Button} variant="contained" color="primary" to={read_more}>
                        {read_more_text} <ChevronRight/>
                    </Link>
                </div> 
                : "" }
            </Box>
            
        )
    // } else return "";

}

export default ProductCategory;