import React, {useState} from 'react'
import { makeStyles } from "@material-ui/core/styles"
import { AppBar, Toolbar, IconButton, Button, Container } from '@material-ui/core'
import { Menu as MenuIcon, Close as CloseButton, Textsms } from '@material-ui/icons'
import Logo from '../../images/logo-white.png'
import { Link, NavLink } from 'react-router-dom'
import menuItems from '../../data/main-menu'

import A from '../../redux/actionTypes'
import {connect} from 'react-redux'
// import Selector from './template-selector'
// import TopMenu from '../top-menu'

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    container: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        justifyContent: "flex-end"
    },

    menuButton: {
        display: "none",
        // marginRight: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            display: "inherit"
        }
    },

    contactButton: {
        
        color: theme.palette.common.white,
        fontSize: "20px"
        
    },
    title: {
        flexGrow: 1,
    },
    link: {
        color: theme.palette.primary.contrastText,
        "&:hover": {
            color: theme.palette.common.white
        },
        "&.active": {
            color: theme.palette.common.white
        }
    },

    header: {        
        backgroundImage: `url(${process.env.REACT_APP_MEDIA}/images/content/header-bg.jpg)`,
        backgroundSize: "cover",
        backgroundPosition:"bottom",
        transition: 'height 0.3s ease-out',
        height: '10em',
        ".scrolled &": {
            height: '5em'
          }
    },
    menu: {
        zIndex: 200
    },
    buttonHolder: {
        display: "block",
        width: "100%",
        textAlign: "right"
    },
    toolbar: {        
        [theme.breakpoints.down('sm')]: {
            display: "none",
            
        }
    },
    toolbarExpand: {
        [theme.breakpoints.down('sm')] : {
            display: "flex",
            position: "fixed",
            left: 0,
            top: 0,
            width: "0%",
            background: theme.palette.common.overlay,
            flexDirection: "column",
            height: "100%",
            transition: "width 0.3s ease-out, padding-top 0.3s ease-out",
            overflow: "hidden",
            zIndex: 100,
            paddingTop: "160px",
            "&.show" : {
               width: "100%"
            },
            ".scrolled &" : {
                paddingTop: "90px"
            }
        },
        [theme.breakpoints.up('md')]: {
            display: "none"
        }
    }
    ,
    spacer: {
        flexGrow: 1
    }
}));



const Navbar = props => {

    let [showHiddenMenu, setHiddenMenu] = useState(false)

    const {showContact, showForm} = props
    
    
    const classes = useStyles();
    const { location } = props
    console.log(location)

    const closeMenu =  ev =>{ ev.stopPropagation(); setHiddenMenu(false)}
    const showMenu =  ev =>{ ev.stopPropagation(); setHiddenMenu(true)}

    return (

        <AppBar position="fixed" className={classes.header } elevation={0}>
            <Container className={classes.container}>
                <Toolbar disableGutters={true} className={classes.menu} onClick={closeMenu} >
                    <Link to="/">
                        <img src={Logo} />
                    </Link>
                    <div className={classes.toolbar}>
                    {/* <div className={classes.toolbar + " show"}> */}
                        {menuItems.map((item, ind) => {
                            return <Button key={ind} className={classes.link} component={NavLink} to={item.link}>{item.title}</Button>
                        })}                                  
                        {/* <Selector /> */}                        
                    </div>
                    

                    
                    
                    <div className={classes.spacer}/>                    
                    { showContact ? "" :
                            <>
                                <IconButton onClick={showForm} className={classes.contactButton} aria-label="menu" >
                                    <Textsms fontSize="large" />
                                </IconButton>
                            </>                    
                        }

                    {showHiddenMenu ?   
                        <IconButton edge="start" className={classes.menuButton} color="secondary" aria-label="menu" onClick={() => setHiddenMenu(false)}>
                            <CloseButton fontSize="large" />
                        </IconButton>
                        :                      
                        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={showMenu} >                            
                            <MenuIcon fontSize="large" />
                        </IconButton>
                    }                    
                </Toolbar>
            </Container>
            <div className={classes.toolbarExpand + (showHiddenMenu ? " show" : "")} onClick={ closeMenu }>
                {/* <div className={classes.buttonHolder}>
                    <CloseButton edge="start" className={classes.menuButton} color="primary" aria-label="menu">
                        <MenuIcon onClick={() => setHiddenMenu(false)}/>
                    </CloseButton>
                </div> */}
            {menuItems.map((item, ind) => {
                return <Button key={ind} className={classes.link} component={NavLink} to={item.link}>{item.title}</Button>
            })}
            {/* <Selector /> */}
            </div>
        </AppBar>
    )

}

// export default Navbar

const mapStateToProps = (state) => {
    const { showContact } = state
    return { showContact };
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
        showForm : () => dispatch( { type: A.SHOW_CONTACT } )
    };
  };
  export default connect(
    mapStateToProps, 
    mapDispatchToProps
  )(Navbar);