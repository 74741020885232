import axios from  'axios'

const imageRoot = process.env.REACT_APP_MEDIA

export default class DownloadsService {



    static async byProduct(){

        if( ! DownloadsService.products ){
            try{
                let {data} = await axios.get(process.env.REACT_APP_API + "/downloads/products")
                
                // data = data.map( item => { return {...item, images: item.images && item.images !== "" ? JSON.parse(item.images) : [] } } )
                
                console.log(data)
                 DownloadsService.products = data

                return data
            } catch ( e ){
                //TODO: log
                console.log(e)
                DownloadsService.error = e.response ? e.response.data : e.message
    
                return false;
            }
        } else {
            return  DownloadsService.products
        }

    }

    static async byTypes( types = [] ){
        if( !Array.isArray(types) ){ 
            this.error = "Wrong types passed"
            return false
        }
        const slug = types.join(",")

        if( ! this.types[ slug ]  ){
            try{
                let {data} = await axios.get(process.env.REACT_APP_API + "/downloads/by-types", {params: { types } })
                
                if( !Array.isArray(data) ){
                    this.error = "Unknown data error"
                    return false;
                }

                // parse images
                data  = data.map( i => ({
                    ...i,
                    // add image
                    image: i.image ? i.image :
                    i.download_type === 5 ? 
                    `/images/products/sensor-icons/${i.product_id}.png` : 
                    '/images/software/software-default.png'
                }))

                this.types[slug] = data

                return data
            } catch ( e ){
                //TODO: log
                console.log(e)
                DownloadsService.error = e.response ? e.response.data : e.message
    
                return false;
            }
        } else {
            return this.types[ slug ]
        }

    }
    
    static async byExperiment(){

        if( ! DownloadsService.experiments ){
            try{
                let {data} = await axios.get(process.env.REACT_APP_API + "/downloads/experiments")
                
                // data = data.map( item => { return {...item, images: JSON.parse(item.images) } } )
                
                DownloadsService.experiments = data

                // console.log(data)
                return data
            } catch ( e ){
                //TODO: log
                console.log(e)
                DownloadsService.error = e.response ? e.response.data : e.message
                return false;
            }
        } else {
            return  DownloadsService.experiments
        }

    }

    static async getExperimentDownloads( code ){

        if( ! DownloadsService.experimentDownloads[code] ){
            try{
                let {data} = await axios.get(process.env.REACT_APP_API + "/downloads/experiment/" + code)
                
                // data = data.map( item => { return {...item, images: JSON.parse(item.images) } } )
                
                DownloadsService.experimentDownloads[code] = data

                // console.log(data)
                return data
            } catch ( e ){
                //TODO: log
                console.log(e)
                DownloadsService.error = e.response ? e.response.data : e.message
                return false;
            }
        } else {
            return  DownloadsService.experimentDownloads[code]
        }

    }

    static async getProductDownloads( code ){
        
        if( ! DownloadsService.productDownloads[code] ){
            console.debug("Fetching product downloads from server")
            try{
                let {data} = await axios.get(process.env.REACT_APP_API + "/downloads/product/" + code)                
                DownloadsService.productDownloads[code] = data

                // console.log(data)
                return data
            } catch ( e ){
                //TODO: log
                console.log(e)
                DownloadsService.error = e.response ? e.response.data : e.message
                return false;
            }
        } else {
            console.debug("Returning product downloads from cache")
            return  DownloadsService.productDownloads[code]
        }

    }

    static async getOne( slug){

        if( ! DownloadsService.downloads[slug] ){
            try{

                let {data} = await axios.get(process.env.REACT_APP_API + "/downloads/item/" + slug )                                               
                DownloadsService.downloads[slug] = data
                // console.log(data)
                return data
            } catch ( e ){
                //TODO: log
                console.log(e.response)
                DownloadsService.error = e.response ? e.response.data : e.message
    
                return false;
            }
        } else {
            return  DownloadsService.downloads[slug]
        }

    }


}

DownloadsService.experimentDownloads = {}
DownloadsService.productDownloads = {}
DownloadsService.downloads  = []
DownloadsService.types  = {}
DownloadsService.error = null