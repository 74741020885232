import React from 'react'
import Hero from '../hero/hero.display'
// import experiments from "../../data/experiments-total.json"
// import pdflist from "../../data/experiments-pdf.json"
// import solutions from "../../data/labs.json"
import { Container, Grid, Typography, Box, CircularProgress } from '@material-ui/core'
import Breadcrumbs from '../breadcrumbs'
import { makeStyles } from '@material-ui/core/styles'
// import SolutionList from '../featuredSolutions'
import ProductList from '../featuredProducts'

import List from '../list/simpleList'
import Placeholder  from '../common/placeholder'
// import {PictureAsPdfSharp as PdfIcon} from '@material-ui/icons';
// import {Link} from 'react-router-dom'
import Service from '../../services/experiments'
import CourseService from '../../services/courses'
import Downloads from '../../services/downloads'

import DownloadItem from '../horizontal-card/download'
import Courses from '../pages/courses'

// const breadcrumbsStyle = {
//     "paddingTop": "1em",
//     "paddingBottom": "1em",
//     position: "absolute",
//     top: "-2em"

// }

// const styles = makeStyles(
//     theme => ({
//         breadcrumbsStyle: {
//             paddingTop: "1em",
//             paddingBottom: "1em",
//             position: "absolute",
//             top: "-4em",
//             width: "100%"

//         },
//         container: {
//             position: "relative"
//         }
//     })
// )






export default class Experiment extends React.Component {


    state ={
        item: null,
        relatedProducts: null,
        downloads: null,
        courses: null
    }

    // constructor(props) {
    //     super(props)
        // console.log(props)
        // const { experiment } = props.match.params || null

        // experiments.map( item => {
        //     console.log( "code",  item.code, pdflist[item.code] )
        //     if( pdflist[item.code] ){
        //         item.manual = pdflist[item.code]
        //     }
        // })

        // if (experiment) {
        //     this.item = experiments.find(exp => exp.name === experiment) || {}
        // }
    // }

    componentDidMount(){
        this.fetchData()
    }   

    componentDidUpdate( oldProps) {
        if( oldProps.match.params.experiment !== this.props.match.params.experiment)
            this.fetchData();
    }

    fetchData = async () => {
        const { experiment } = this.props.match.params
        //  console.log("Fetching data for", experiment)
        const item = await Service.getOne( experiment )
        this.setState({item})

        if( item.code ){
            const relatedProducts = await Service.getRelatedProducts( item.code )
            // this.setState({relatedProducts})

            const downloads = await Downloads.getExperimentDownloads( item.code )
            
            const courses = await CourseService.getByExperiment(item.code)

            this.setState({relatedProducts, downloads, courses})

        }
    }


    render() {

        let {item, relatedProducts, downloads, courses } = this.state

        // downloads = downloads ? [...downloads, ...downloads, ...downloads ] : downloads 

        if( item === null ) return <Container><Typography variant="h1" color="secondary">Loading experiment...</Typography><CircularProgress color="secondary" /></Container>
        if( item === false ) return <Container><Typography variant="h1" color="error">Error loading experiment</Typography><CircularProgress color="secondary" /></Container>

        console.log( "Data:", item)
        let { title, code, manual, images, category, description, html, solutions, used, categoryTitle } = item

        // const pdfRoot = process.env.REACT_APP_MEDIA + process.env.REACT_APP_PDF_EXPERIMENTS_ROOT

        images = Array.isArray(images) && images.length ? images : [ "no-image.png"]

        const crumbs = [
            { title: "Home", link: "/" },
            { title: "Experiments", link: "/experiments" },
            { title: categoryTitle, link: `/experiments/${category}/` },
            { title: title }
        ]

        return (
            <Box style={{position: "relative"}}>
                <Breadcrumbs items={crumbs} />
                <Hero title={title} images={images} description={description} subtitle={`Experiment ${code}`} />
                <Container>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={6} >                                                        
                            <Container className="bg-opaque-6 bg-rounded py-1">
                                <Typography variant={'h4'} align={"center"} color="primary">Objectives</Typography>
                                { 
                                    html === null || html.trim() === "" ? 
                                    <Placeholder text="This experiment's data is being updated" />
                                    // <Typography variant="body2" align="center">
                                    //     This experiment's data is being updated
                                    // </Typography>
                                    :
                                    <div dangerouslySetInnerHTML={{ __html: html }}></div>
                                }
                            </Container>                           
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div align={"center"}>
                                <Typography color="primary" variant="h4" gutterBottom>Downloads</Typography>
                                <List 
                                xs={12} sm={12} md={12} lg={12} xl={12}
                                items={ downloads ? downloads.map(dwnl =>{ return {...dwnl, link: process.env.REACT_APP_MEDIA + dwnl.download_link}}) : null }
                                ItemComponent={DownloadItem}
                                />
                                {/* <Typography variant={'h4'} align={"center"} color="primary">Manual</Typography>
                                { (manual) ?  
                                <a href={`${pdfRoot}${manual}`} target="_blank">
                                    <Grid direction={"column"} align={"center"} >

                                        <PdfIcon fontSize="large" color="primary"/> 
                                        <Typography>
                                        Download Manual in PDF
                                        </Typography>
                                    </Grid>
                                </a> :
                                <Typography variant="h6" color="primary">No manual is uploaded</Typography>
                                } */}
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {/* <SolutionList items={solutions || [] }/>                             */}
                            <ProductList items={relatedProducts}/>
                            
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {/* <SolutionList items={solutions || [] }/>                             */}
                            <Courses items={courses} inContainer={false} headerProps={{level: 4, align: "center"}}/>
                            
                        </Grid>
                    </Grid>
                </Container>

            </Box>
        )
    }
}