import React from 'react'
import { Typography, Container, CircularProgress } from '@material-ui/core'
import Item from './multilistItem'


export default class multiList extends React.Component {

    state = {
        title: "",
        titleVariant: "h2",
        wrapperComponent: "Container",
        items: []
    }

    static getDerivedStateFromProps( props, state ){
        return props || null ;
    }

    render(){

        const {loadingText = "Loading..."} = this.props 

        let {title, titleVariant, titleColor, wrapperComponent, items, height="140px"} = this.state
        titleColor = titleColor || "primary"

        if(!items || items.length === 0 )
    return <Container><CircularProgress color="secondary"/> {loadingText}</Container>
        return(
            <div component={wrapperComponent}>                
                <Typography color={titleColor} variant={titleVariant}>{title}</Typography>
                { items.map( (item, ind) => {
                    
                    return <Item key={`mlist${ind}`} item={item} height={height}/>
                })}                
            </div>
        )

    }
}