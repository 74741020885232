import axios from  'axios'
import {randomFromArray} from '../utils/functions'
import catalog from '../data/catalog.json'
import baseClientService from './baseClientService'

const sensorIds = catalog.items.products.items.neulog.items.sensors.products
const kitIds = catalog.items.products.items.neulog.items["science-kits"].products
const imgRoot = process.env.REACT_APP_MEDIA
const API = process.env.REACT_APP_API

class ProductsService extends baseClientService {



    static async getAll(){

        if( !ProductsService.products ){
            try{
                let {data} = await axios.get( API + "/products/all-neulog")
                
                // data = data.map( item => { return {...item, images: JSON.parse(item.images) } } )
                if( Array.isArray(data) ){

                    data = data.map( p => ({
                        ...p, 
                        image: imgRoot + '/images/products/140x140/' + ( p["images"][0] || "no-image.png"),
                        largeImage: imgRoot + '/images/products/466x466/' + ( p["images"][0] || "no-image.png"),
                        link: `/products/item/${p['id']}`                 
                    }) )

                    ProductsService.products = data

                    // console.log(data)
                    return data
                } else {
                    console.log("Wrong data returned")
                    ProductsService.error = "Wrong data returned. Please, try reloading page."
                    return false;
                }
            } catch ( e ){
                //TODO: log
                console.log(e)
                ProductsService.error = e.response ? e.response.data : e.message
                return false;
            }
        } else {
            return ProductsService.products
        }

    }

    static async getOne( id ){
        // if( !ProductsService.products ){
            const products = await ProductsService.getAll()
            if( products ){
                const result = ProductsService.products.find( item => item.id === id ) || {}
                if( !result ){
                    ProductsService.error = `Product with id ${id} not found`
                    return false
                } else {
                    return result;
                }
            } else {
                // return {}
                // ProductsService.error = "Error fetching data from server. Please, try reload the page."
                return false
            }
            
        // } else {
        //     return ProductsService.products.find( item => item.id === id ) || {}
        // }

    }

    static async getProductExperiments(id){
        console.log("Service experiments: fetching for id="+id)
        if( id ) {
            if( !ProductsService.experiments[id] ){
                const {data} = await axios.get(API + "/products/experiments/" + id )
                if( data ){
                    
                    
                    ProductsService.experiments[id] = data.map( item => { return {...item, images: JSON.parse(item.images )}} )
                    return ProductsService.experiments[id]
                } else {
                    console.log("Data not received!")
                    return false;
                }
            } else {
                return ProductsService.experiments[id]
            }
        } else {
            console.log("Id not passed")
            return false;
        }
    }
    static async getProductCourses(id){
        console.log("Fetching courses for product id="+id)
        if( id ) {
            if( !ProductsService.courses[id] ){
                let {data} = await axios.get(API + "/courses/by-product/" + id )
                if( data && Array.isArray(data)){

                    data = data.map( c => ({
                        ...c,
                        link: `/labs/${c.set_slug}/${c.slug}`,
                        image: imgRoot + (c.images[0] || "/images/content/no-image.png"),
                        subtitle: c.grade_from && c.grade_to ? `Grade ${c.grade_from} to ${c.grade_to}` : null
                    }))

                    ProductsService.courses[id] = data
                    return ProductsService.courses[id]
                } else {
                    console.log("Data not received!")
                    return false;
                }
            } else {
                return ProductsService.courses[id]
            }
        } else {
            console.log("Id not passed")
            return false;
        }
    }

    /**
     * Returns numOfProducts random sensors
     * @param {*} numOfProducts      
     */
    static async randomSensors( numOfProducts ){
        const products = await ProductsService.getAll()
        console.log("sensors", products, sensorIds )
        if( products && Array.isArray(products)) {
            if( numOfProducts > products.length )
                throw new Error("Number of random elements required is greater than array length")
            // if(categories && categories.length > 0){
            return randomFromArray( products.filter( p => sensorIds.includes(p.id) ), numOfProducts )
            // } else {
            //     return randomFromArray( products, numOfProducts )
            // }
        } else {
            
            return false
        }
    }

    static async getCategories(){
        const _cache = "prod.neulog-categories"
        if( !this.cacheExists(_cache)){

            try{
                const {data} = await this.api.get(`${API}/products/neulog-categories`) // axios.get(`${API}/products/neulog-categories`)
                // return categories        
                const recursive = id => {
                    // index of item with id
                    const index = data.findIndex( c => c.slug === id )
    
                    // children of such item
                    const children = data.filter( c => c.parent === id )
    
                    // add nested categories, including self, to a new field                
                    data[index].category_includes = [id, ...children.reduce( (res, c) => ([...res, ...recursive(c.slug)]), [] ) ]
    
                    // return the new field's value as result
                    return data[index].category_includes
                }    
                // parse the categories and add nested categories to each item
                recursive("neulog")
    
                console.debug("Parsed categories:", data)
    
                return data
            }catch(e){
                console.error("Get categories: ", e.response ? e.response.data : e.message)
                this.error = "Error fetching categories, please try reloading the page"
                return false;
            }
        } else {
            return this.getCached(_cache)
        }
    }

    static async getCategoryTree(){     
        const _cache = "prod.categoryTree"  

        if( !this.cacheExists(_cache)){
            const categories = await this.getCategories()
            if( categories ){
    
                const recursive = id => {
                    const data = categories.find( i => i.slug === id )                
                    
                    if( data ){
                        
                        let { slug, path, ...item} = data
    
                        // add link to item
                        item = {...item, link: `/products${path}`}
    
                        // find children 
                        let children = categories.filter( i => i.parent === id )
                        // let category_includes = [ slug ]                    
                        
                        // if found
                        if(children.length > 0 ){
                            
                            children = children.map( c => recursive( c.slug ) )
    
                            // console.debug("category_includes", category_includes, children)
                            // gather all nested categories, including self, and state them as a property.
                            // reduce starts with a copy of category_includes variable.
                            // category_includes = children.reduce( (res, c) => ([...res, ... c.category_includes ? c.category_includes : [] ]), [...category_includes] )
                            // categories[index].category_includes = 
                            //     [...categories[index].category_includes, 
                            //     ...children.reduce( (res, c) => 
                            //     ([...res, ... categories[] ? c.category_includes : [] ]), [] )
                            //     ]
                                
    
                            // console.log("Returning tree item:", {...item, children, category_includes })
                            // return {...item, children, category_includes }
                            return {...item, children }
                        } else {                        
                            // console.log("Returning tree item:",  {...item, category_includes })
                            // return {...item, category_includes}
                            return {...item}
                        }                    
                    } else {
                        console.error("slug", id, "not found ")
                        return null
                    }
                }
    
                return [recursive( "neulog" )]
            } else {
                return false
            }
        } else {
            return this.getCached(_cache)
        }
        
    }
    
    /**
     * Returns numOfProducts random kits
     * @param {*} numOfProducts      
     */
    static async randomKits( numOfProducts ){
        const products = await ProductsService.getAll()
        console.log("kits", products, kitIds )
        if( products && Array.isArray(products)) {
            if( numOfProducts > products.length )
                throw new Error("Number of random elements required is greater than array length")
            // if(categories && categories.length > 0){
            return randomFromArray( products.filter( p => kitIds.includes(p.id) ), numOfProducts )
            // } else {
            //     return randomFromArray( products, numOfProducts )
            // }
        } else {
            
            return false
        }
    }


}



ProductsService.products = null
ProductsService.experiments = {}
ProductsService.courses = {}

baseClientService.extend(ProductsService)

export default ProductsService