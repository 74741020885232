import React from 'react'
import { Grid, Container, Link, Typography, Button  } from '@material-ui/core'
import { NavLink, Link as RLink } from 'react-router-dom'
import {PictureAsPdf as Pdf, LaptopWindows as Win, LaptopMac as Mac, GetApp, Info, SettingsApplications, Apple} from '@material-ui/icons'
import './styles.scss'

const Icons = {
    1: Pdf,
    2: Pdf,
    3: Win,
    4: Apple,
    5: SettingsApplications,
    6: SettingsApplications,
    7: Pdf
}

// const IconSelector = props => <Box component={Icons[props.index]} {...props}></Box> 

const Actions = props => {
    console.log("Actions", props)
    switch (props.download_type) {
        case 1:            
        case 2:            
            return <Button fullWidth variant="contained" className="btn download" component={Link} target="_blank" href={props.link}><GetApp/> {props.linkTitle ? props.linkTitle : "Download"}</Button>
            break;
        
        case 3:            
        case 4:            
        case 6:                    
            return <>
            <Button fullWidth size="small" variant="contained" className="btn download" component={Link} href={props.link}><GetApp/> {props.linkTitle ? props.linkTitle : "Download"}</Button>
            <Button fullWidth size="small" variant="contained" className="btn readmore" component={RLink} to={props.slug_link}><Info/> {props.readMoreTitle ? props.readMoreTitle : "Read more..."}</Button>
            </>
            break;

            // firmware update: don't show download
        case 5:
            return <Button fullWidth size="small" variant="contained" className="btn readmore" component={RLink} to={props.slug_link}><Info/> {props.readMoreTitle ? props.readMoreTitle : "Read more..."}</Button>
            
            break;

        default:
            return <Button size="small" fullWidth variant="contained" className="btn download" component={Link} href={props.link}><GetApp/> {props.linkTitle ? props.linkTitle : "Download"}</Button>
            break;
    }
}

const Wrap = props => {
    
    switch (props.download_type) {

        case 2:
            return <NavLink to={props.link || ""} className="card" exact={props.exact || false}>{props.children}</NavLink>
            break;

        default:
        case 1:
            return <Link className="card" href={props.link}>{props.children}</Link> //<NavLink to={link} className="card" exact={exact}></NavLink>
            break;
    }
}

const HorizontalCardDownload = props => {


    const { title, download_type, download_type_title, slug_link } = props
    const { leftPartSize = 2, midPartSize = 8, rightPartSize = 4, spacing = 2 } = props
    console.log("horizontal:", props)

    switch (download_type) {

        // case 2:

        //     return (

        //         <NavLink to={props.link || ""} className="card download" exact={props.exact || false}>
        //             <Grid container spacing={spacing}>
        //                 <Grid item xs={leftPartSize}>
        //                     <Grid container>
        //                         <Win color="primary" fontSize="large" />
        //                     </Grid>
        //                 </Grid>
        //                 <Grid item xs={12 - leftPartSize}>
        //                     <Grid container>
        //                         <Grid xs={12} sm={midPartSize}>
        //                             <Typography className="title">{title}</Typography>
        //                         </Grid>
        //                         <Grid xs={12} sm={rightPartSize}>
        //                             <Actions {...props.item} />
        //                         </Grid>
        //                     </Grid>
        //                 </Grid>
        //             </Grid>
        //         </NavLink>
        //     )
        //     break;

        default:
        case 1:
            return (
                // <Link className="card download" href={props.link} target="_blank">
                <Container className="card download py-2">
                    <Grid container spacing={spacing} className="parts-holder">                        
                        <Grid item xs={leftPartSize} className="part1">
                            {/* <Grid container spacing={spacing}> */}
                                {/* <Grid xs={12}> */}
                                    {/* <div component={Icons[download_type]} color="primary" fontSize="large" /> */}
                                    {slug_link ?  
                                    <NavLink to={slug_link}>
                                        <Typography component={Icons[download_type]} color="white" className="download-icon" ></Typography>
                                    </NavLink>
                                    : 
                                    <Typography component={Icons[download_type]} color="white" className="download-icon" ></Typography>
                                    } 
                                {/* </Grid> */}
                            {/* </Grid> */}
                        </Grid>
                        <Grid item xs={12 - leftPartSize} className="part2-holder">
                            <Grid container spacing={spacing} className="part2">
                                <Grid xs={12} sm={midPartSize}>
                                    <Typography fullWdth className="title">{title}</Typography>
                                    <Typography fullWdth className="subtitle">{download_type_title}</Typography>
                                </Grid>
                                <Grid xs={12} sm={rightPartSize} className="part3">
                                    <Actions {...props} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
                // </Link>
            )
            break;
    }


    // return (
    //     <Wrap {...props} >
    //     {/* // <NavLink to={link} className="card" exact={exact}> */}
    //         <Grid container spacing={spacing}>
    //             <Grid item xs={leftPartSize}>
    //                 {/* <CardActionArea link={link}> */}                    
    //                     <Image image={image} />                    
    //                 {/* </CardActionArea> */}
    //             </Grid>
    //             <Grid item xs={12 - leftPartSize}>
    //                 <Grid container spacing={spacing}>
    //                     <Grid xs={12} sm={midPartSize}>
    //                         <Typography className="title">{title}</Typography>
    //                     </Grid>
    //                     <Grid xs={12} sm={rightPartSize}>
    //                         <Actions {...props.item} />
    //                     </Grid>
    //                 </Grid>
    //             </Grid>
    //         </Grid>
    //     {/* </NavLink> */}
    //     </Wrap>

    // )


}


export default HorizontalCardDownload