import React from 'react'
import {Grid, FormControl} from '@material-ui/core'
import Select from '../common/select'
import {H6} from '../template/headers'

const _hardware = [
    {id: "USB-200 module"},
    {id: "BLT202 Bluetooth module", connection: [ "USB", "Bluetooth" ] },
    {id: "Wifi201 module"},
    {id: "Wifi202 module"},
]

const _os = [
    {id: "Windows", icon: null},
    {id: "macOS", icon: null},
    {id: "Android (Samsung, Xiaomi, etc.)", icon: null},
    {id: "IOS (iPhone, iPad)", icon: null},
    {id: "Linux", icon: null},
    {id: "Other", icon: null},
]

const _os_version = {
    "Windows": [
        "Windows 10",
        "Windows 8",
        "Windows 7",
        "Not sure"
    ],
    "macOS": [
        "macOS 10.15 Catalina",
        "macOS 10.14 Mojave",
        "macOS 10.13 High Sierra",
        "macOS 10.12 Sierra",
        "macOS 10.11 el capitan",
        "macOS 10.10 Yosemite",
        "Not sure"
    ]
}
   


class ConfigSelect extends React.Component {


    state = {
        hardware: null,
        connection: null,        
        os: null,
        version: null,
        connections: null,
        os_versions: null        
    }


    updateValue = ({target}) => {
        const {name, value} = target
        let item, result
        const self = this
        console.log( "changing", name, value)

        switch( name ){
            case "hardware":
                item = _hardware.find( h => h.id === value )
                if( item ){
                    if( item.connection ){
                        // TODO: show connection selector
                        result = {[name]: value, connection: null, connections: item.connection }
                    } else {
                        // TODO: hide connection selector                        
                        result = {[name]: value, connection: null, connections: null }
                    }
                    
                    
                } else {
                    console.error("Wrong value passed:", value)
                }
                break;
            case "os":
                item = _os.find( h => h.id === value )
                if( item ){
                    const os_versions = _os_version[value]
                    if( os_versions ){
                        // show version selector
                        result = {[name]: value, version: null, os_versions }
                    } else {
                        // TODO: hide connection selector                        
                        result = {[name]: value, version: null, os_versions: null }
                    }
                    
                    
                } else {
                    console.error("Wrong value passed:", value)
                }
                break;
            default:
                result = {[name]: value}                
                break;
        }

        this.setState( result, this.validate )
    }

    validate = () => {
        let valid = false
        console.log("State:", this.state )
        
        const {
            hardware,
            connection,        
            os,
            version,
            connections,
            os_versions
        } = this.state

        if( hardware ){
            if( connections ){
                if( connection ) {
                    if( os ) valid = true
                }
            } else {
                if( os ) valid = true
            }
        }

        const result = valid ? `${hardware}${ connection ? ` + ${connection}`: ""}, ${ version ? version : os}` : null
        console.debug( "Value is ", valid ? `valid` : "NOT valid" )
        const {callback} = this.props 
        
        // if valid, call callback
        if( callback && typeof callback === "function" )
            callback(result)

    }


    render(){
        const {hardware,
            connection,        
            os,
            version,
            connections,
            os_versions
            } = this.state

        const swidth = ( hardware && connections ) ? 6 : 12    
        
        return(
            <div className="bg-primary-light p-2" >
            <Grid container spacing={2} >
                <Grid item xs={12}>
                    <H6 className="color-white">Please, describe the configuration you're having problems with</H6>
                </Grid>
                <Grid item xs={12} md={swidth}>
                    <Select 
                    items={_hardware} 
                    valueField="id"
                    titleField="id"
                    label="Specify the NeuLog Connectivity module you are using" 
                    onChange={this.updateValue}
                    value={hardware}
                    name="hardware"
                    required={true}
                    fullWidth
                    />
                </Grid>
                {
                    connections ?
                    <Grid item xs={12} md={swidth}>
                        <Select 
                        items={connections} 
                        label="Select the connection" 
                        onChange={this.updateValue}
                        value={connection}
                        name="connection"
                        required
                        fullWidth
                        />
                    </Grid>
                    : ""
                }
                {
                    // if harware and connection is set (or no need to select connections), show OS selection
                    (hardware && !connections) || (hardware && connections && connection )?
                    <Grid item xs={12} md={6}>
                        <Select 
                        items={_os} 
                        valueField="id"
                        titleField="id"
                        label="Select the OS you are using" 
                        onChange={this.updateValue}
                        value={os}
                        name="os"
                        required
                        fullWidth
                        />
                    </Grid>
                    : ""
                }
                {
                    os_versions ?
                    <Grid item xs={12} md={6}>                        
                        <Select 
                        items={os_versions} 
                        label="Select your OS version if you know it" 
                        onChange={this.updateValue}
                        value={version}                        
                        name="version"
                        fullWidth
                        />                        
                    </Grid>
                    : ""
                }
            </Grid>
            </div>
        )
    }
}

export default ConfigSelect;