import axios from  'axios'
import {strFormat} from '../utils/functions'

const api = process.env.REACT_APP_API
class ModulesService {

    static async get( id ){
        if( !ModulesService.items[id] )
            try{
                const {data} = await axios.get(  strFormat("%api%/modules/item/%id%", {"%api%": api, "%id%": id} ) )

                ModulesService.items[id] = data
                return data;
                
            }catch(e){
                ModulesService.error = e.response ? e.response.data : e.message
                return false
            }
        else {
            return ModulesService.items[id]
        }

    } 

   
}

ModulesService.items = {}
ModulesService.error = null

export default ModulesService