import React from 'react'
import {FormControl, InputLabel, Select, MenuItem} from '@material-ui/core'


export default  props => {
    let {
        name, 
        items, 
        onChange, 
        valueField ="value", 
        titleField="title", 
        idPrefix="select-", 
        label, 
        id,
        labelId,
        fullWidth,
        required,
        ...other
    } = props
    
    id = id ? id :`${idPrefix}-${name}`    
    labelId= labelId ? labelId : `${id}-label` 
    items = Array.isArray(items) ? 
        // if it's an array of strings, parse it to right format
        typeof items[0] === "string" ? 
            items.map( i => ({ [titleField]: i, [valueField]: i }))  
        : items 
    : []
    onChange = onChange ? onChange : ev => console.debug("Value changed to " + ev.target.value )
    
    const updatedProps = {
        ...other,
        onChange, id, labelId, required, name, fullWidth
    }

    console.debug("Select properties:", updatedProps )
    return(
        <FormControl fullWidth={fullWidth} required={required}>
            <InputLabel id={labelId}>{label}</InputLabel>
            <Select             
            {...updatedProps}           
            >
                {items.map( item => {
                    const title = item[titleField] ? item[titleField] : `Wrong item title field`
                    const itemValue =  item[valueField] ? item[valueField] : ""
                    return(
                        <MenuItem value={itemValue}>{title}</MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}