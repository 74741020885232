import React, {useState} from 'react'
import { Box, Typography, Container, Grid, CircularProgress } from "@material-ui/core"
import List from './list/itemList'
import SimpleList from './list/simpleList'
import products from '../data/products.json'
// import downloads from '../data/downloads.json'
import Service from '../services/downloads'
import ProductItem from './list/productListItem'
import LinkListItem from './list/linkListItem'
import DownloadItem from './horizontal-card/download'
import { Alert } from '@material-ui/lab'
import {H3, H4} from './template/headers'


const fetchDownloads = async setState => {
    let downloads = await Service.byProduct()
    if( Array.isArray(downloads) ){

        downloads = downloads.filter( item => [3,4].includes(item.download_type) )  // TODO: get from server by types
        .map(dwnl =>{ return {...dwnl, link: process.env.REACT_APP_MEDIA + dwnl.link}})
        setState(downloads)
    } else {
        setState(false)
    }
}

export default function Requirements(props) {

    let [software, setSoftware] = useState(null)
    let { title, product } = props

    if( software === null ) fetchDownloads( setSoftware )

    title = title || "Requirements"
    product = product || {}

    const dataRetrieval = products.filter(item => ["USB-200", "WIFI-204", "WIFI-202"].includes(item.id))

    // const software = Object.keys(downloads.software).reduce((result, key) => {
    //     const item = { ...downloads.software[key], id: key }
    //     return [...result, item]
    // }, [])


    const hideRequired = products.reduce( (result, item) => {
        return ["kit", "coding", "module", "sense"].includes(item.type) ? [...result, item.id] : result;
    }, [])

    const showRequired = !hideRequired.includes(product.id)
    // whether software will be full screen or half screen
    const sSm = showRequired ? 6 : 12


    return (
        <div className="requirements">
            
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <H3 align="center">{title}</H3>
                </Grid>
                {
                    showRequired ?
                    <Grid item xs={12} sm={6}>
           
                        <Container>
                        <H4>Connection</H4>
                        <Alert variant="filled" severity="info" className="mb-4 bg-primary-dark">You will need one of the next items to connect sensors to your PC.</Alert>
                            
                            <List height={80} lg={4} items={dataRetrieval} ItemComponent={ProductItem}
                                imageRoot={process.env.REACT_APP_MEDIA + process.env.REACT_APP_PRODUCTS_SMALL_ROOT}
                            />
                        </Container>
                            
                    </Grid> : ""
                }
                
                <Grid item xs={12} sm={sSm}>
                    <Container>                        
                    <H4>Software</H4>
                        

                        <Alert variant="filled" severity="info" className="mb-4 bg-primary-dark">Use this software to connect NeuLog products to your devices.</Alert>
                        
                        
                        {/* <List lg={3} height={80} items={software} ItemComponent={LinkListItem} */}
                        {
                            software === null ? <CircularProgress /> :
                            software === false ? "Error loading software links" :
                            <SimpleList xs={12} sm={12} md={12} xl={12} lg={12} spacing={2}
                                // add media root to download link
                                items={ software } 
                                ItemComponent={DownloadItem} 
                                imageRoot={process.env.REACT_APP_MEDIA + process.env.REACT_APP_PRODUCTS_SMALL_ROOT}
                            />
                        }
                        
                    </Container>
                </Grid>
            </Grid>                
            
        </div>
    )
}
