import Home from '../components/pages/home/home'
import Sense from '../components/pages/home/sense'
import Sensors from '../components/pages/home/sensors'
import ExpCategory from '../components/experiments/experimentCat'
import Experiment from '../components/experiments/experiment'
import Labs from '../components/pages/sets/solutions'
import Lab from '../components/pages/sets/lab'
import Course from '../components/pages/sets/course'
import LabMain from '../components/pages/sets/main'
import Downloads from '../components/pages/software/index'
import Firmware from '../components/pages/software/firmware'
import DownloadsItem from '../components/pages/software/softwareItem'
import Catalog from '../components/pages/catalog'
import Product from '../components/pages/products/product'
import P404 from '../components/pages/404'
import Blog from '../components/blog'
import About from '../components/blog/about'
import Article from '../components/blog/article'


import Service from '../services/map'

// TODO: probably lazy-load these using a list fetched via API.
const components = {
    Home,
    Sense, 
    Sensors, 
    ExpCategory, 
    Experiment, 
    Firmware,
    Labs, 
    Lab, 
    LabMain,
    Course, 
    Downloads, 
    DownloadsItem, 
    Catalog, 
    Product, 
    P404, 
    Blog, 
    About, 
    Article
}

// const defaultReveal  = {distance: "30px", bottom: true }


// TODO: get these from API, add Suspense to route component to show loader while the data is loaded.
// export const sets = ["robotics", "science", "science-and-robotics"]
// export const experimentCategories = ["physics", "biology", "chemistry", "environment", "children"]

// const routesArray = [
//     { exact: true, path: "/" , component: "Home", bodyClass: "bg-dark" },    
//     { exact: true, path: "/sensors" , component: "Sensors"},
//     { exact: true, path: "/sense" , component: "Sense"},
//     { exact: true, path: "/products" , key: "catalog", component: "Catalog"},  
//     { exact: true, path: "/products/item/:id" , component: "Product"},
//     { exact: true, path: "/products/:path(.*)" , key: "catalog", component: "Catalog"},    
//     { exact: true, path: `/labs/:id(${sets.join("|")})/:course` , component: "LabMain"  },        
//     { exact: true, path: `/labs/:id(${sets.join("|")})` , component: "LabMain" },
//     { exact: true, path: "/labs" , component: "Labs"  },
//     { exact: true, path: "/experiments" , component: "ExpCategory"},
//     { exact: true, path: `/experiments/:name(${experimentCategories.join("|")})` , component: "ExpCategory"},
//     { exact: true, path: `/experiments/:name(${experimentCategories.join("|")})/:experiment` , component: "Experiment"},
//     { exact: true, path: "/downloads" , component: "Downloads"},
//     { exact: true, path: "/downloads/firmware" , component: "Firmware"},
//     { exact: true, path: "/downloads/firmware/:id" , component: "DownloadsItem"},
//     { exact: true, path: "/downloads/:id" , component: "DownloadsItem"},
//     { exact: true, path: "/about" , component: "About"},
//     { exact: true, path: "/about/:cat(news)" , component: "Blog"},
//     { exact: true, path: "/about/:cat(news)/p/:page" , component: "Blog"},
//     { exact: true, path: "/about/:cat(news)/:slug" , component: "Article"},
//     { exact: true, path: "/:cat(articles)" , component: "Blog", active: false},
//     { exact: true, path: "/:cat(articles)/p/:page" , component: "Blog", active: false},
//     { exact: true, path: "/:cat(articles)/:slug" , component: "Article", active: false},    
//     { exact: false, path: "*" , component: "P404"},
// ]



/**
 * Export promise
 */ 
const fetchRoutes = async () => {
    // await new Promise(r => setTimeout(r, 2000));

    // get the complete sitemap with routes
    const map = await Service.getCompleteMap()
    if( map && Array.isArray(map.routes) ){
        // return the routes with corresponding components 
        // TODO: add default component to deal with non-found component name
        return map.routes.map( r => ({...r, component: components[r.component] }))
    } else {        
        return false
    }    
}

export default fetchRoutes

// export const routes = routesArray.map( r => ({...r, component: components[r.component] }))

// const routes = [
//     { exact: true, path: "/" , component: components["Home"], bodyClass: "bg-dark" },
//     // { exact: true, path: "/:id(panda)" , component: Static },
//     { exact: true, path: "/sensors" , component: components["Sensors"]},
//     { exact: true, path: "/sense" , component: components["Sense"]},
//     { exact: true, path: "/products" , key: "catalog", component: components["Catalog"]},  
//     { exact: true, path: "/products/item/:id" , component: components["Product"]},
//     { exact: true, path: "/products/:path(.*)" , key: "catalog", component: components["Catalog"]},
//     // { exact: true, path: `/labs/:id(${sets.join("|")})/:course` , component: components["Course"]  },        
//     // { exact: true, path: `/labs/:id(${sets.join("|")})` , component: components["Lab"] },
//     { exact: true, path: `/labs/:id(${sets.join("|")})/:course` , component: components["LabMain"]  },        
//     { exact: true, path: `/labs/:id(${sets.join("|")})` , component: components["LabMain"] },
//     { exact: true, path: "/labs" , component: components["Labs"]  },
//     { exact: true, path: "/experiments" , component: components["ExpCategory"]},
//     { exact: true, path: `/experiments/:name(${experimentCategories.join("|")})` , component: components["ExpCategory"]},
//     { exact: true, path: `/experiments/:name(${experimentCategories.join("|")})/:experiment` , component: components["Experiment"]},
//     { exact: true, path: "/downloads" , component: components["Downloads"]},
//     { exact: true, path: "/downloads/firmware" , component: components["Firmware"]},
//     { exact: true, path: "/downloads/firmware/:id" , component: components["DownloadsItem"]},
//     { exact: true, path: "/downloads/:id" , component: components["DownloadsItem"]},
//     { exact: true, path: "/about" , component: components["About"]},
//     { exact: true, path: "/about/:cat(news)" , component: components["Blog"]},
//     { exact: true, path: "/about/:cat(news)/p/:page" , component: components["Blog"]},
//     { exact: true, path: "/about/:cat(news)/:slug" , component: components["Article"]},
//     { exact: true, path: "/:cat(articles)" , component: components["Blog"], active: false},
//     { exact: true, path: "/:cat(articles)/p/:page" , component: components["Blog"], active: false},
//     { exact: true, path: "/:cat(articles)/:slug" , component: components["Article"], active: false},
//     // { exact: true, path: "/about/news" , component: Blog, revealProps: defaultReveal},
//     // { exact: true, path: "/about/news/:slug" , component: Article, revealProps: defaultReveal},
//     { exact: false, path: "*" , component: components["P404"]},
// ]

// console.log("routes:", routes )
// export const routes = [
//     { exact: true, path: "/" , component: Home, revealProps: defaultReveal },
//     { exact: true, path: "/products" , component: Products, revealProps: defaultReveal },
//     { exact: true, path: "/products/labs" , component: Labs, revealProps: defaultReveal},
//     { exact: true, path: "/products/labs/:id(science|robotics|kid)" , component: Lab, revealProps: defaultReveal},
//     { exact: true, path: "/products/catalog/item/:id" , component: Product, revealProps: defaultReveal},
//     { exact: false, path: "/products/catalog:path(.*)" , component: Catalog, revealProps: defaultReveal},
//     { exact: true, path: "/experiments" , component: Experiments, revealProps: defaultReveal},
//     { exact: true, path: "/experiments/:name(physics|biology|chemistry|environment|children)" , component: ExpCategory, revealProps: defaultReveal},
//     { exact: true, path: "/experiments/:name(physics|biology|chemistry|environment|children)/:experiment" , component: Experiment, revealProps: defaultReveal},
//     { exact: true, path: "/downloads" , component: Downloads, revealProps: defaultReveal},
//     { exact: true, path: "/downloads/:id" , component: DownloadsItem, revealProps: defaultReveal},
//     { exact: false, path: "*" , component: p404, revealProps: defaultReveal},
// ]


// export default routes;