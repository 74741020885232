import React from 'react'
import {Container, Typography, Grid, CircularProgress, Button} from '@material-ui/core'
import Service from '../../../../services/blog'
import ProductService from '../../../../services/products'
import CourseService from '../../../../services/courses'
import ExpService from '../../../../services/experiments'
import Image from '../../../common/image'
import Product from '../../../list/productListItem'
import ExpCat from '../../../horizontal-card'
import List from '../../../list/itemList'
import { Link } from 'react-router-dom'
import Youtube from '../../../youtube-multi'
import Courses from '../../courses'
import Card from '../../../blog/card-template'
import {H1, H2, H3 } from '../../../template/headers'

import './sensors.scss'


const imageRoot = process.env.REACT_APP_MEDIA


const ItemTemplate = item => (
    <Grid container spacing={4} >
        <Grid item xs={12}>
            <Typography variant="h2" color="primary">{item.title}</Typography>
        </Grid>
        <Grid item xs={12} md={4} className="card bg-none">
            { item.youtube && item.youtube.length > 0 ? 
            <Youtube 
            id={item.youtube} 
            playerVars={{
                enablejsapi: 1,
                autoplay: 1,
                mute: true,
                loop: 1
            }}
            
            />
            :
            <Image image={ `${imageRoot}/images/content/${item.images[0] || "/no-image.png"}`} />
        }
        </Grid>
        <Grid item xs={12} md={8}>
            <Typography variant="body1" dangerouslySetInnerHTML={{__html: item.html || ""}}></Typography>
        </Grid>
    </Grid>
)

class Sensors extends React.Component{

    state = {
        items: null,
        randomProducts: null,
        randomKits: null,
        courses: null,
        experimentCategories: null
    }

    componentDidMount(){
        this.fetchData()
        this.fetchRandomProducts()
        this.fetchExperimentCategories()
    }


    fetchRandomProducts = async () => {
        const randomProducts = await ProductService.randomSensors(6)
        if( randomProducts ){
            this.setState({randomProducts})
        } else {
            this.setState({randomProducts:false})
        }
        
        const randomKits = await ProductService.randomKits(6)
        if( randomKits ){
            this.setState({randomKits})
        } else {
            this.setState({randomKits:false})
        }
    }

    fetchExperimentCategories = async () => {
        let experimentCategories = await ExpService.getCategories()
        if( experimentCategories ){
            experimentCategories = experimentCategories.map(
                cat => ({
                    ...cat,
                    link: `/experiments/${cat.name}`,
                    image: `${imageRoot}/${cat.images && cat.images[0] ? cat.images[0] : "no-image.png"}`,
                    subtitle: cat.description
                    
                })
            )
            this.setState({experimentCategories})
        } else {
            this.setState({experimentCategories: ExpService.error })
        }
    }

    fetchData = async () => {
        const item1 = await Service.getStatic("sensors")
        const item2 = await Service.getStatic("sensors-configuration")
        const item3 = await Service.getStatic("sensors-outdoor")

        if( item1 && item2 && item3 ){
            this.setState({items:[
                {...item1, title_position: "top-text", images: [], media_width: 7, header_level: 3, full_width_level: "md", 
                playerVars:  {
                    enablejsapi: 1,
                    autoplay: 1,
                    mute: true,
                    loop: 1
                } }, 
                {...item2, media_position: "right", media_width: 8,  full_width_level: "md"  }, 
                // { slug: "single-image", show_title: false, show_text: false, media_position: "top", images: ["outdoors-02.jpg"] },
                {...item3, itemId: "outdoors", full_width_level: "md"}
            ]})
        } else {
            this.setState({items: false})
        }

        const courses = await CourseService.getAll( [10,11,12,13] )
        console.log( "Courses:", courses)
        this.setState({courses})
    }

    render(){
        const {items, randomProducts,randomKits, courses, experimentCategories} = this.state
        console.log(randomProducts)

        const coursesConfig = {
            title: "Complete courses with Neulog Sensors",            
        }
        return(
            <Container className="neulog-sensors">
                <H1 gutterBottom>NeuLog Logger Sensors</H1>
                {
                    items === null ? <CircularProgress/> :
                    items === false ? <H3 color="error">Error loading data. Please, try reloading the page.</H3> :
                    items.map( item =>     
                            <div id={item.slug} key={item.slug}>
                                {/* <Anchor id={item.slug}><div></div></Anchor> */}
                                <Card {...item} />
                            </div>  
                        )
                }

                
                
                { randomProducts ? 
                <div className="article-card">
                    <H2 className="mt-6 mb-1">Sensors</H2>
                    <List items={randomProducts} imageRoot={`${imageRoot}/images/products/140x140/`} ItemComponent={Product} sm={6} md={2} lg={2} xl={2}/> 
                    <Button 
                    fullWidth 
                    variant="contained" 
                    color="primary" 
                    component={Link} 
                    to="/products/sensors" 
                    className="mt-2 bg-texture01" 
                    size="large"                    
                    > 
                        {/*  <Image src={`${imageRoot}/images/content/four-sensors-together.png`} className="" style={{height: "100px", width: "100px"}} /> */}
                        View all sensors!
                    </Button>
                </div> :
                ""
                }

                { randomKits ? 
                <div className="article-card">
                    <H2 className="mt-6 mb-1">NeuLog Kits: designed for NeuLog Sensors</H2>
                    <Typography variant="body1" className="mb-2">NeuLog Science kits are sare customized for the use of NeuLog Sensors, together they provide the best learning experience.</Typography>
                    <List items={randomKits} imageRoot={`${imageRoot}/images/products/140x140/`} ItemComponent={Product} sm={6} md={2} lg={2} xl={2}/> 
                    <Button 
                    fullWidth 
                    variant="contained" 
                    color="primary" 
                    component={Link} 
                    to="/products/science-kits" 
                    className="mt-2 bg-texture01" 
                    size="large"> View all kits!</Button>
                </div> :
                ""
                }
                

                <div className="article-card">
                { 
                    typeof experimentCategories === "string" ? 
                    
                        // if string, it's an error text
                        <H2 color="error">{experimentCategories}</H2>
                        // if null, it's still loading
                        : experimentCategories === null ?
                        <Container className="my-2"><CircularProgress/></Container> 
                        :
                        // otherwise, it's loaded
                        <div className="mt-4">
                            <H2>NeuLog Logger Sensors, Kits and Contents in:</H2>
                            <List 
                                items={experimentCategories}
                                imageRoot={`${process.env.REACT_APP_MEDIA}/`} 
                                ItemComponent={ExpCat} 
                                sm={6} md={4} lg={4} xl={4}
                                />                 
                        </div>
                    
                }
                </div>
                    
                <Courses 
                    className="article-card" 
                    items={courses} 
                    config={coursesConfig} 
                    itemProps={{xs: 12, md:6, className: "h-card-container"}} 
                    inContainer={false}
                />
                   
                
                
                
            </Container>
        )
    }
}

export default Sensors;