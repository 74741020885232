import React, {useState} from 'react'
import {H} from '../../template/headers'
import './responsive-drawer.scss'
import {ArrowDropUpRounded as Arrow} from '@material-ui/icons'
import {scrollToElement} from '../../../utils/functions'
// import { Container } from '@material-ui/core'

const ResponsiveDrawer = props => {
    const {
        headerLevel = 3, 
        title 
    } = props

    const [ open, setOpen ] = useState(false)

    const toggleOpen = ev =>{         
        setOpen(!open)
        const target = ev.currentTarget
        // scroll into view after  0.5 sec when the animation's over
        setTimeout( () => scrollToElement(target, -190) , 600, {behavior: "auto"} )
        
        // .scrollIntoView({behavior: "smooth"})
    }


    return(
        <div className={`responsive-drawer${ open ? " show" : ""}`}>
            <div className="header">
                { title && <H level={headerLevel}>{title}</H>}
                <div className="button top close-button" onClick={toggleOpen}>
                    <Arrow className={ `arrow ${open ? "open" : "closed"}`} /> { open ? "Close" : "Show" } 
                </div> 
            </div>
            {/* { open ? 
            <div className="button top close-button" onClick={() => toggleOpen(false)}>
                <Arrow /> Close 
            </div> 
            :
            <div className="button top show-button" onClick={() => setOpen(true)}><ArrowDn />Show</div>
            } */}
            
            <div className="content">
                {props.children}                
            </div>
        </div>
    )
}

export default ResponsiveDrawer
