import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardActionArea, CardMedia, CardContent, CardActions, Box, Typography, Button } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';
import Usb from '@material-ui/icons/Usb';
import Pdf from '@material-ui/icons/PictureAsPdf';
import  Reveal  from '../reveal';


const useStyles = makeStyles(theme => ({

    card: {
        height: "100%",
        display:  "flex",
        flexDirection: "column",
        "&.primary": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText
        },
        "&.primaryDark": {
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.primary.contrastText
        },
        "&.primaryLight": {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.common.white
        },
        "&.secondary": {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText
        },        
    },
    cardActions :{        
        flexGrow: 1,
        alignItems: "flex-end"        
        
    }
}));

export default function LinkListItem(props) {

    const classes = useStyles()

    let { item, height, bgcolor, linkBase = "/downloads", showDownloadButton = true } = props


    height = height || 140
    bgcolor = bgcolor || ""


    // console.log("item", item)
    let { title, link, icon, image, id } = item
    link = link || "";
    const completeLink = link.startsWith("http") ? link : process.env.REACT_APP_MEDIA + link;
    const itemLink = `${linkBase}/${id}`
    let iconComp = (icon == "Usb") ? Usb : Pdf


    const imageRoot = process.env.REACT_APP_MEDIA

    return (
        <Reveal className="h-100">
        <Card className={`${classes.card} ${bgcolor}`}>
            <CardActionArea component={Link} to={itemLink} align="center">
                {
                    (item.image) ?
                        <CardMedia
                            component="img"
                            alt=""
                            height={height}
                            image={`${imageRoot}${image}`}
                            title={title}
                        /> : (
                            icon ? <Typography component={iconComp} color="primary" align="center" style={{ fontSize: "4em" }}></Typography> : ""
                        )

                }

                <CardContent>
                    <Typography gutterBottom variant="body2">{title}</Typography>
                </CardContent>
            </CardActionArea>
            { showDownloadButton &&             
            <CardActions className={`${classes.cardActions}`}>
                <Button size="small" color="primary" component={"a"} href={completeLink} >Download</Button>
            </CardActions>
            }
        </Card>
        </Reveal>
    )
}