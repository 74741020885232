import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import cssVars from './config/cssVars.json'

import { Provider } from "react-redux";
import store from './redux/store'
// import SiteMap from './services/map'

// start fetching the sitemap data.
// This attempts to update head meta values before crawler starts to analyse the page
// SiteMap.updatePage()
    


// console.log(cssVars)
// Set CSS variables
Object.keys(cssVars.images).map ( key => {
    document.documentElement.style.setProperty( key , `url('${process.env.REACT_APP_MEDIA + cssVars.images[key]}')`  )
})
Object.keys(cssVars.colors).map ( key => {
    document.documentElement.style.setProperty( key , cssVars.colors[key] )
})

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
