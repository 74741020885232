import React from 'react'
import {Container, Grid, Typography, Button} from '@material-ui/core'
import {Pageview} from '@material-ui/icons'
// import {Cached} from '@material-ui/icons'
import Placeholder from '../../common/placeholder'
import {Link} from 'react-router-dom'
import Image from '../../horizontal-card/image'
import './product-popup.scss'
export default props => {
    const {title, largeImage, description, link, code, html, closeModal } = props

    return (
    <Container className="pop-up-product">
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h3" color="primary">{title} <span className="subtitle">{code}</span></Typography>
                
            </Grid>
            <Grid item xs={12} md={4}>
                <div className="left-side">
                    <Image image={largeImage} />
                    {/* <hr /> */}
                    <Button className="mt-2" fullWidth variant="contained" color="primary" component={Link} to={link} onClick={closeModal}> <Pageview/> Read more...</Button>
                </div>
            </Grid>
            <Grid item xs={12} md={8} className="details">
        
                <Typography variant="subtitle1" color="primary">{description}</Typography>
                { 
                    html ? 
                    <div dangerouslySetInnerHTML={{__html: html}}></div>                                
                    :
                    // <div className="placeholder"><Cached fontSize="large"/><div>This product's data is being updated</div></div>                        
                    <Placeholder/>
                }
        
            </Grid>
        </Grid>
    </Container>
    )
}