import React from 'react'
import Service from '../../../services/courses'
import SetsService from '../../../services/sets'
import List from '../../list/simpleList'
import { CircularProgress, Typography, Grid, Button, Tabs, Tab } from '@material-ui/core'
import { FormatQuote, Info, PanTool } from '@material-ui/icons'
import Image from '../../common/image'
import Placeholder from '../../common/placeholder'
// import Drawer from '../../common/responsive-drawer'
// import {H} from '../../template/headers'
import ProductTpl from './course-product'
import Experiment from './course-experiment'
import Download from '../../horizontal-card/download'
import { connect } from 'react-redux'
import A from '../../../redux/actionTypes'

// import Categories from '../../common/categories'

import Order from './order'

import './course.scss'

const initialState = {
    set: null,
    course: null,
    products: null,
    experiments: null,
    downloads: null,
    error: "Unknown error",
    quote: [],
    tabValue: 0
    // sitemap: null
}
const imgRoot = process.env.REACT_APP_MEDIA

const Section = props => (
    props.listProps.items ?
        (
            props.listProps.items.length > 0 ?
                // <Container className={props.className || "section"}>
                <>
                    <Typography variant="h3" color="primary" gutterBottom>{props.title}</Typography>
                    {
                        props.Before ?
                            props.Before
                            : ""
                    }

                    <div className={props.className || "section"}>
                        <List {...props.listProps} />
                    </div>
                </>
                // </Container>
                // If no items found, just show nothing
                : "")
        // items are falsy
        :
        // if null, show loader
        props.listProps.items === null ?
            <CircularProgress /> :
            // else (false) show error message
            <Typography color="error">Error loading {props.title}. Please, try realoading the page</Typography>
)

const OrderForm = props => {
    const { quote, action } = props
    const totalProducts = quote.length
    return (
        <Grid container spacing={2} className="order-form p-2 mb-4 MuiPaper-elevation1">

            <Grid item xs={12} md={4}>
                <Typography variant="body1">Total products: <span className="quantity">{totalProducts}</span></Typography>
                <Button onClick={action} className="order-button" fullWidth><FormatQuote />Order Quote</Button>
            </Grid>
            <Grid item xs={12} md={8}>
                <div class="text"><Info className="info-icon" />You can order a price quote for this course's products set by selecting products' options in the list below and pressing the "Order quote" button.</div>
            </Grid>
        </Grid>
    )
}

const arrayNotEmpty = arr => Array.isArray(arr) && arr.length > 0


const TabPanel = props => (
    <div id={`course-tabs-${props.id}`} className={props.id === props.value ? "tab-panel selected" : "tab-panel"}>{props.children}</div>
)

const reduceProducts = (products, onChangeInQuote, onSelect) => {
    console.log("Products:", products)
    const obj = products.reduce((result, p) => {

        console.log(" current p", p)
        // ({ ...result, [p.multiple] : [...result[p.multiple] || [] , {...p, image: process.env.REACT_APP_MEDIA + '/images/products/140x140/' + ( p["images"][0] || "no-image.png")} ] })
        return {
            // result object
            ...result,
            // group into arrays by p.multiple. Replace with 0 if null
            [p.multiple]:
                [
                    // what's already under this key in the result, or an empty array if there's nothing
                    ...result[p.multiple] || [],
                    // plus the current element with parsed image
                    {
                        ...p,
                        image: imgRoot + '/images/products/140x140/' + (p["images"][0] || "no-image.png"),
                        largeImage: imgRoot + '/images/products/466x466/' + (p["images"][0] || "no-image.png"),
                        link: `/products/item/${p['id']}`,
                        onSelect, // add callback on select from multiple option
                        onChangeInQuote // add callback for state change
                    }
                ]
        }
    }
        , {})
    console.log("First pass result", obj)
    const res = Object.keys(obj).reduce((result, key) => {
        console.log("key:", key, "key===null", key === "null")
        return key === "null" ? [...result, ...obj[key]] : [...result, obj[key]]
    }, [])

    console.log("Reduced products: ", res)
    return res
}

const reduceExperiments = items =>
    items.map(item => ({
        ...item,
        image: `${imgRoot}/images/experiments/thumb/${item.images[0] || "no-image.png"}`,
        largeImage: `${imgRoot}/images/experiments/${item.images[0] || "no-image.png"}`,
        link: `/experiments/${item['category']}/${item['name']}`
    }))


class Course extends React.Component {

    state = initialState


    componentDidMount() {        
        this.fetchData()
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.course != prevProps.match.params.course) {
            this.fetchData();
        }
    }

    fetchData = async () => {
        this.setState(initialState)        

        const course = await Service.getOneBySlug(this.props.match.params.course)
        const set = await SetsService.getOne(this.props.match.params.id)
        if (course) {
            this.setState({ course, set })
            this.fetchExperiments(course.course_id)
            this.fetchDownloads(course.course_id)
            this.fetchProducts(course.course_id)
        } else {
            this.setState({ course: false, error: Service.error })
        }
    }

    

    fetchExperiments = async (id) => {
        let experiments = await Service.getExperiments(id)
        if (experiments)
            experiments = reduceExperiments(experiments)
        this.setState({ experiments })
    }
    fetchDownloads = async (id) => {
        const downloads = await Service.getDownloads(id)
        console.debug("Downloads: ", downloads )
        this.setState({ downloads })
    }
    fetchProducts = async (id) => {
        let products = await Service.getProducts(id)
        let quote = []
        if (products) {
            products = reduceProducts(products, this.onChangeInQuote, this.onSelectMultiple) // products.map( product => ({...product, image: process.env.REACT_APP_MEDIA + '/images/products/140x140/' + ( product["images"][0] || "no-image.png") }))
            // populate quote array with each item if it's an object, or item's first record if it's an array
            quote = products.map(p => Array.isArray(p) ? p[0] : p)
            console.log("Quote:", quote)
        }


        this.setState({ products, quote })
    }

    /**
     *  To be triggered when item's quantity switch (count in quote) is changed.
     *  Removes existing item from quote if false and adds to quote if true
     * 
     */
    onChangeInQuote = (id, value) => {
        console.log("in quote state changed", id, value)
        let { quote, products } = this.state
        // get all other items aside of the passed one
        quote = quote.filter(p => p.id !== id)
        // if value is true, add product with given id
        if (value) {
            // add product with given id
            quote = [...quote, ...products.filter(p => p.id === id)]
        }
        console.log("New quote set", quote)
        this.setState({ quote })
    }

    /**
     * This one is tricky: it receives the previously selected item and the newly selected item.
     * It then finds the index in quote for the old selected item and replaces it with newly selected one (updates state)
     */
    onSelectMultiple = (newItem, oldItem) => {
        let { quote } = this.state
        const itemId = quote.findIndex(p => p.id === oldItem.id)

        if (itemId >= 0) {
            quote[itemId] = newItem
            this.setState({ quote })
        } else {
            // console.error("OOps", newItem, oldItem, quote)
        }


    }

    setTabValue = (ev, tabValue) => {this.setState({tabValue})}

    render() {

        const { course, experiments, products, downloads, error, set, quote, tabValue } = this.state
        const { id, course: course_id } = this.props.match.params        
        const { doOrder } = this.props
       
        if (course === null) return <CircularProgress />
        if (course === false) return <><Typography variant="h1" color="primary">Error</Typography><Typography color="error">{error}</Typography></>
        
        const image = course.images.length > 0 ? imgRoot + course.images[0] : null
        const text = course.description ? <div class="course-text">{course.description}</div> : <Placeholder text="This course's data is being updated" />
        let md = 12

        const courseData = [
            { 
                data: experiments, title: "Experiments", 
                listProps: {
                    ItemComponent: Experiment ,
                    spacing: 4,
                    sm: 12, md: 4, lg: 3, xl: 3
                }
            }, 
            {   data: products, title: "Products",                 
                before: <OrderForm quote={quote} action={() => { doOrder((<Order quote={quote} courseRef={course.reference} />)) }} />,
                listProps: {
                    ItemComponent: ProductTpl ,
                    spacing: 4,
                    xs: 12, sm: 12, md: 12, xl: 12, lg: 12
                }
            }, 
            {   
                data: downloads, 
                title: "Downloads", 
                listProps: {
                    ItemComponent: Download ,
                    spacing: 4,
                    xs: 12, sm: 12, md: 12, xl: 12, lg: 12
                } 
            }, 
        ]
        // filter out empty elements
        .filter( i => arrayNotEmpty(i.data ) )

        // const experimentsExist = arrayNotEmpty(experiments)
        // const productsExist = arrayNotEmpty(products)
        // const downloadsExist = arrayNotEmpty(downloads)

        // if both experiments and products are present, split screen in two columns, otherwise show one column.
        // if ( experimentsExist && productsExist ) md = 6


        return (
            <div>
                
                <div className="set-course">
                    {/* <Typography>{course.description}</Typography>                     */}
                                            
                    <Grid container spacing={4}>                                
                        {image ?
                            <>
                                <Grid item xs={12} sm={12} md={4}>
                                    <Image src={image} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={8} className="placeholder-container">
                                    {text}
                                </Grid>
                            </>
                            :
                            <>
                                <Grid item xs={12}>                                            
                                    {text}
                                </Grid>
                            </>

                        }
                        {/* <Grid item xs={12}>
                            <Section listProps={{ items: downloads }} title="Downloads" />
                        </Grid> */}
                        { courseData.length > 1 && 
                            // show tabs only when there's more than one element present
                            <Grid item xs={12}>
                                <Tabs 
                                    onChange={this.setTabValue} 
                                    value={tabValue} 
                                    centered 
                                    className="tabs"
                                    // variant="scrollable"
                                    // scrollButtons="auto"
                                >
                                    { courseData.map( (section, ind) =>  <Tab className="tab-item" label={section.title} id={ind}/> )}
                                    {/* {downloadsExist && <TabItem label="Downloads" id={1}/>}                                
                                    {productsExist && <TabItem label="Products" id={2}/>}                                
                                    {experimentsExist && <TabItem label="Experiments" id={3}/>}                                 */}
                                </Tabs>
                            </Grid>
                        }
                         
                        <Grid item xs={12}>
                            { courseData.map( (section, ind) =>    
                            <TabPanel value={tabValue} id={ind}>
                                <Section
                                listProps={{
                                    items: section.title === "Downloads" ? section.data :  section.data.map(p => ({ item: p })) ,
                                    ...section.listProps
                                }}
                                className="product-list"
                                title={section.title}
                                Before={section.before}
                                />
                            </TabPanel> 
                            )}
                            {/* {downloadsExist && <TabPanel value={tabValue} id={1}/>}                                
                            {productsExist && <TabPanel value={tabValue} id={2}/>}                                
                            {experimentsExist && <TabPanel value={tabValue} id={3}/>}    */}
                        </Grid>
                    
                        {/* <Grid item xs={12} md={md}>                            
                            <Section
                                listProps={{
                                    items: products ? products.map(p => ({ item: p })) : products,
                                    ItemComponent: ProductTpl,
                                    spacing: 4,
                                    xs: 12, sm: 12, md: 12, xl: 12, lg: 12
                                }}
                                className="product-list"
                                title="Products"
                                Before={<OrderForm quote={quote} action={() => { doOrder((<Order quote={quote} courseRef={course.reference} />)) }} />}
                            />
                        </Grid>
                        <Grid item xs={12} md={md}>
                            <Section
                                listProps={{
                                    items: experiments ? experiments.map(e => ({ item: e })) : experiments,
                                    ItemComponent: Experiment,
                                    md: 6, lg: 6, xl: 6
                                }}
                                title="Experiments" />
                        </Grid>                             */}

                    </Grid>
                </div>
            </div>
        )
    }
}

const dispatchToProps = dispatch => ({
    onAdd: payload => dispatch({ type: A.ADD_TO_QUOTE, payload }),
    doOrder: payload => dispatch({ type: A.SHOW_MODAL, payload }),

})

export default connect(
    null,
    dispatchToProps
)(Course)
// export default Course;