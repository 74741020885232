import A from './actionTypes'
const localStorageKey = "NeulogAppState"

let localStorageState 

try {
    localStorageState = localStorage.getItem(localStorageKey) ? JSON.parse(localStorage.getItem(localStorageKey) ) : {}
} catch(e) {
    localStorageState = {}
}

const initialState = 
{
    ...{
        showContact: false,
        showModal: false,
        modalContents: null,
        message: null, // {message: String, type: String (danger|warning|success|info)}
        messageIsSent: false,
        tpl: 1, // template config
        templateClass: 'light-dark',
        quote: []
    },
    // overwrite values from local state, if any
    ...localStorageState
}


export default function root( state = initialState, action ) { 

    let newState

    switch( action.type ){
        case A.SHOW_CONTACT:
            console.log( "showing contact form")
            newState = {...state, showContact: true }
            break;
        case A.HIDE_CONTACT:
            console.log( "hiding contact form")
            newState = {...state, showContact: false }
            break;
        case A.SHOW_MODAL:            
            newState = {...state, showModal: true, modalContents: action.payload }
            break;
        case A.HIDE_MODAL:            
            newState = {...state, showModal: false, modalContents: null }
            break;
        case A.SHOW_MESSAGE:
            newState = {...state, message: action.payload }
            break;
        case A.HIDE_MESSAGE:
            newState = {...state, message: null }
            break;
        case A.SEND_CONTACT:
            newState = {...state, messageIsSent: Date.now() }
            break;
        case A.RESET_CONTACT:
            newState = {...state, messageIsSent: false }
            break;
        case A.SET_TPL:
            newState = {...state, tpl: action.payload }            
            break;            
        case A.ADD_TO_QUOTE:
            newState =  {...state, quote: [...state.quote, ...action.payload]}
            break;
        case A.REMOVE_FROM_QUOTE:
            newState =  {...state, quote: [...state.quote, ...action.payload]}
            break;
        default:
            newState = state;
    }

    localStorage.setItem( localStorageKey, JSON.stringify(newState) )
    
    return newState;
}