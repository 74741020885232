import React from 'react'
import { Container, Typography, CircularProgress } from '@material-ui/core'
import Service from '../../services/blog'
// import Item from './item'
// import Reveal from '../reveal'
import Tpl from './article-template'

import './article.scss'

class Article extends React.Component {

    state = {
        article: null,        
        error: null
    }
    
    componentDidMount(){
        this.fetchArticle()        
    }

    componentDidUpdate (prevProps ){
        if( prevProps.match.params.slug !== this.props.match.params.slug  ){
            this.fetchArticle()
        }
    }

    fetchArticle = async () => {

        const {slug, cat } = this.props.match.params
        console.log("Cat:", cat)
        if( slug ){
            const article = await Service.getOne( slug, cat )
            if( article ){
                console.debug("Article is:", article)
                this.setState({article})            
            } else {
                this.setState({error: Service.error})
            } 
        } else {
            this.setState({error: Service.error})
        }


    }

    render(){

        const { error, article } = this.state

        if( error ) return <Container><Typography variant="h3">Error</Typography><Typography variant="body2" color="error">{error}</Typography></Container>
        if( !article ) return <Container><CircularProgress color="primary"/></Container> 
        // const {title, subtitle, html } = article
        return (
            <Tpl {...article} />
        )
    }
}

export default Article