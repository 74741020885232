import React from 'react'
import { Grid, Typography, CircularProgress } from '@material-ui/core'
import {ChevronRightRounded as Chevron} from '@material-ui/icons'
import {Link} from 'react-router-dom'
import Item from './item'
import {Pagination, PaginationItem } from '@material-ui/lab';
import {H} from '../template/headers'
import './category.scss'
import moment from 'moment'

// const Pagination = props => (<>Pagination</>)

const errorLoading = process.env.REACT_APP_ERROR_LOADING || "Error loading"
class Blog extends React.Component {


    render() {

        let {
            title, 
            description = "", 
            category,
            items,
            page = 1,
            perPage = 1,
            titleTag = "h3",        
            titleClass = "",        
            gridProps = {spacing: 2},
            itemProps = { xs: 12, md: 4, className: "category-item" },
            itemComponentProps = {},
            descriptionClass = "description",
            itemClass = "category",
            ItemComponent = Item,
            showPagination = true,
            readMoreLink = null,
            bottomLinkText = null,
            bottomLinkClass = "bottom-link" // "align-right color-white block-link bg-texture01 bg-primary"
        } = this.props
    
        page = parseInt(page)
        console.log("Page:", page, "item component:", ItemComponent)
        const totalPages = category ? Math.ceil( category.totalActive / perPage ) : 0;
        title = title ? title : category ? category.title : "";
        return (
            <>
            <Grid container className={itemClass} >
                <Grid item xs={12}>
                    {readMoreLink ? 
                    <Link to={readMoreLink}>
                        <H variant={titleTag} className={titleClass}>{title }</H>
                    </Link>
                    :
                    <H variant={titleTag} className={titleClass}>{title }</H>
                    }
                {description ? <Typography className={descriptionClass} variant="body2">{description}</Typography> : ""}
                </Grid>
                { items === null ? // if null, show progress
                 <CircularProgress color="primary"/>     :
    
                 items === false ?  // if false, there's been an error
                    <Typography color="error" variant="h4">{errorLoading}</Typography> :
                    <>
                    {showPagination && totalPages > 1 ? 
                        <Pagination 
                        page={page} 
                        count={totalPages } 
                        renderItem={(item) => (
                            <PaginationItem
                              component={Link}
                              to={`/${category.path}${item.page === 1 ? '' : `/p/${item.page}`}`}
                              {...item}
                            />
                          )}
                        /> 
                        : ""}   
                    <Grid item xs={12} >
                        <Grid container {...gridProps} className="category-items">
                            { 
                            items.map(
                                item => (
                                    <Grid {...{ ...itemProps, item: true }}>
                                        <ItemComponent {
                                            ...{
                                                ...item, 
                                                ...itemComponentProps,
                                                link: `/${item.full_slug}`,
                                                subtitle: item.published_from ? `${moment(item.published_from).format("Y MMM DD")}${ item.subtitle ? ` | ${item.subtitle}` : ""} ` : item.subtitle

                                            }} />
                                    </Grid>
                                )
                            )
                            }
                        </Grid>
                    </Grid>
                    {showPagination && totalPages > 1 ? 
                        <Pagination 
                        page={page} 
                        count={totalPages } 
                        renderItem={(item) => (
                            <PaginationItem
                              component={Link}
                              to={`/${category.path}${item.page === 1 ? '' : `/p/${item.page}`}`}
                              {...item}
                            />
                          )}
                        /> 
                        : ""}
                    </>
                
                }
                { readMoreLink && bottomLinkText ? 
                <Grid item xs={12}>
                    <Link to={readMoreLink} className={bottomLinkClass}>{bottomLinkText}<Chevron /></Link>
                </Grid>
                : ""
                }
            </Grid>
            </>
        )
    }
}

export default Blog