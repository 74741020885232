import React from 'react'
import './image.scss'

const Image = props => {


    let {image, src, imageClass = "ratio-image", style = {} } = props

    image = image || src

    return (
        <div className={imageClass} style={{...style, backgroundImage: `URL(${image})`}} ></div>
    )
}

export default Image