import React from 'react';
import Typography from '@material-ui/core/Typography';
import {Breadcrumbs, Box, Container} from '@material-ui/core';
import {makeStyles} from '@material-ui/core'
import Link from '@material-ui/core/Link';
import { Link as To } from 'react-router-dom'
import { withStyles, useTheme } from '@material-ui/styles';




/**
 * Pass array @items to props
 * @param {*} props  
 */
export default function SimpleBreadcrumbs(props) {
    
    const theme = useTheme()
    
    const BreadBox = withStyles( {
        root: {
            "paddingTop": "0.3em",
            "paddingBottom": "0.3em",
            position: "relative",
            // top: "-4em",
            width: "100%",
            backgroundColor: theme.palette.primary.main            
        },
        'a': {
            color: theme.palette.common.white            
        }         
    })(Box)

    const { items } = props || []

    return (
        <BreadBox>
            <Container >
                <Breadcrumbs aria-label="breadcrumb">
                    {items.map((item, ind) => {
                        if (ind == items.length - 1) {
                            return (<Box key={ind} color="common.white">{item.title}</Box>)
                        } else {
                            return (
                                <Link key={ind} to={item.link} component={To}>
                                    <Box color="common.white">{item.title}</Box>
                                </Link>
                            )
                        }
                    })}
                </Breadcrumbs>
            </Container>
        </BreadBox>

    );
}