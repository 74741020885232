
import React from 'react'
import './youtube.scss'

const Video = props => {
    
    // const playerProps = { ...{...{height: 640, width: 390}, ...props } }

    let {id} = props
    
    let playerProps = {
        frameborder: "0",
        allow: "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
        allowfullscreen: true     
    }
    playerProps = {...playerProps, ...props, src: "https://www.youtube.com/embed/" + id }
    return (
        <div className="video-container">
            <iframe {...playerProps}></iframe>
        </div>
    )
}

export default Video