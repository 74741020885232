import React from 'react'
import './styles.scss'

const Image = props => {


    const {image, imageClass = "card-left", className, ...other } = props



    return (
        <div {...other} className={`${imageClass}${className ? ` ${className}` : ""}`} style={{backgroundImage: `URL(${image})`}} ></div>
    )
}

export default Image