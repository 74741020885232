import React from 'react'
import {Cached} from '@material-ui/icons'
import "./placeholder.scss"

export default props => {

    const { text = "This product's data is being updated" } = props

    return (
        <div className="placeholder-box"><Cached fontSize="large"/><div>{text}</div></div>
    )
}