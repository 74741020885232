
import React from 'react'
import './youtube.scss'

import {loadScript } from '../../utils/functions'
import process from 'react'
import uniquid from 'uniquid'
import { Button } from '@material-ui/core'

const SITE_URL = process.env ? process.env.SITE_URL || window.location.origin  :  window.location.origin
const youtubeApiUrl = process.env ? process.env.REACT_YOUTUBE_API_URL || 'https://www.youtube.com/iframe_api' : 'https://www.youtube.com/iframe_api'
// const youtubeEmbedLink = process.env ? process.env.REACT_YOUTUBE_EMBED_LINK || "https://www.youtube.com/embed/%s%" : "https://www.youtube.com/embed/%s%"
const eventName = "youtube-react-player.onloaded"

// add youtube default callback for on load event.
// youtube API actually calls for function with this name when it's loaded.
window.onYouTubeIframeAPIReady = () => {
    console.debug("Youtube API loaded")
    window.dispatchEvent( new Event( eventName ) );
}

class Video extends React.Component{
    
    // const playerProps = { ...{...{height: 640, width: 390}, ...props } }

    state = {
        playerReady: false
    }
    

    constructor(props){
        super(props)        
        this.id = `youtube_player_${uniquid()}`
        this.player = null

        // listen for the youtube onload event 
        window.addEventListener( 
            eventName, this.init
        )
    }

    init = () => {
        console.debug("initializing player " + this.id )
        

        const events = { 
            // default values
            ...{
                onReady: this.startPlayer
            },
            ...(this.props.events || {} )
        }

        const playerVars = {
            ...{
                color: 'black',
                // attempt to fix "Failed to execute 'postMessage' on 'DOMWindow'" youtube error. (didn't work)
                widget_referrer: SITE_URL
            },
            ...(this.props.playerVars || {} )
        }


        const {id} = this.props
        let ids = []
        let videoId 
        if( Array.isArray(id) ){
            ids = [...ids, ...id]
        } else {
            ids = [...ids, id]
        }

        // pop first one and make it the first id
        videoId = ids.shift()

        // let the rest of them to be playlist, if any.
        // convert them to comma-separated string
        if( ids.length > 0 ){
            playerVars.playlist = ids.join(",")
        }

        const playerParams = {
            ...{
                width: 600,
                height: 400,
                videoId
            },
            ...this.props,

            // override events separately
            events,

            // override player vars separately
            playerVars
        }

        // this is called once YT is defined        
        console.debug("Player params", playerParams )
        this.player = new window['YT'].Player( this.id , playerParams );
    }

    startPlayer = () => {
        this.setState({playerReady: true})
        console.debug("Starting player " + this.id )
        // this.playVideo()
    }

    componentDidMount(){

        // load youtube api or, once loaded - init the player
        if( !window['YT'] || !window['YT'].Player  ){
            console.debug("Loading youtube api")
            loadScript( youtubeApiUrl, this.onYoutubeScriptLoaded )
        }else{
            this.init()
        }
    }

    playVideo = () => {
        if (this.player)
            this.player.playVideo()
    }
    pauseVideo = () => {
        if (this.player)
            this.player.pauseVideo()
    }

    togglePlay = () => {
        if( this.player)
            if (this.player.getPlayerState() === 1 )
                this.pauseVideo()
            else
                this.playVideo()
    }
    toggleSound = () =>  {
        if( this.player.isMuted() )
            this.player.unMute()
        else
            this.player.mute()
    }
    
    render(){

        let {id, showButtons = false } = this.props

        const {playerReady} = this.state
        
        // let playerProps = {

        //     frameborder: "0",
        //     allow: "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
        //     allowfullscreen: true     
        // }
        // playerProps = {...playerProps, ...this.props, src: textFormat( youtubeEmbedLink.preg_, [id]) }
        return (
            <div className="video-container">
                    <div className="wrapper">
                            {/* <iframe {...playerProps}></iframe> */}
                        <div id={ this.id }></div>
                    </div>
                    { playerReady && showButtons ? 
                        <div class="buttons">
                            <Button className="player-button" variant="contained" onClick={this.togglePlay}>Play</Button>
                            <Button className="player-button" variant="contained" onClick={this.toggleSound}>Sound</Button>
                        </div>
                        : ''
                    }
            </div>
        )
    }
}

export default Video