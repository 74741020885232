import React from 'react'
import {TextField} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {debounce} from '../util/functions'

const useStyles = makeStyles(theme => ({
    search: {        
        marginBottom: "12px"
    }
  }));



const Search = props => {

    const {callback, value, label = "Search products", debounceTime = 300, searchClass ="" } = props
    const classes = useStyles();

    const onSearch = debounce( s => 
    {                
        if( typeof callback === "function" ) callback(s)
    }
    , debounceTime )

    return (
        <>
            <TextField 
                variant="outlined"
                label={label}
                fullWidth
                className={searchClass}
                type="search"                
                onChange={ ({target}) => { onSearch(target.value) } }
                value={value}
            />
        </>
    )
}


export default Search