import React from 'react'
import Breadcrumbs from '../../breadcrumbs'
import { Grid, CircularProgress, Paper } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { Link, Box, Typography, Button, Container } from "@material-ui/core"
import { List, ListItem, ListItemIcon } from '@material-ui/core'
import { Usb, GetApp, Unarchive, Directions } from '@material-ui/icons'
// import Pdf from '@material-ui/icons/PictureAsPdf';
// import Hero from '../../hero/hero.display'
import Page404 from '../404'
import './software.scss'
import Service from '../../../services/downloads'
import MapService from '../../../services/map'
import Reveal from '../../reveal'
import { Alert } from '@material-ui/lab'


const DownloadText = props => {


    const { item } = props

    switch (item.download_type) {

        // the Firmware update
        case 5:
            return (
                <List>
                    <Alert severity="warning" variant="filled">Please, only upgrade your product's firmware if you really know what you are doing. A fail to follow the exact instructions during firmware update may damage the product!</Alert>
                    <ListItem>The update software works on MS windows (PC) only.</ListItem>
                    <ListItem>These instructions apply to the firmware update of all sensors and viewer modules:</ListItem>
                    <ListItem><ListItemIcon><Usb /></ListItemIcon>The USB module should be connected to the computer with USB cable (without the sensor or module yet).</ListItem>
                    <ListItem><ListItemIcon><GetApp /></ListItemIcon>Download the installation folder to your computer.</ListItem>
                    <ListItem><ListItemIcon><Unarchive /></ListItemIcon>Extract the zip file and run the “Prog.exe” .</ListItem>
                    <ListItem><ListItemIcon><Directions /></ListItemIcon>Follow update program directives.</ListItem>
                </List>
            )
            break;
        case 6:
            return (
                <>
                    <Typography variant="h4" color="primary">Instructions for offsetting the module</Typography>
                    <List>
                        <ListItem><ListItemIcon><GetApp /></ListItemIcon>Download the installation Zip file to your computer (with a windows operating system)</ListItem>
                        <ListItem><ListItemIcon><Unarchive /></ListItemIcon>Extract the Zip file.</ListItem>
                        <ListItem><ListItemIcon><Directions /></ListItemIcon>Run the "Ion_sensor_offset_Vx_x.exe" file that is inside the folder and follow the instructions. </ListItem>
                    </List>
                    <Typography variant="h4" color="primary">Required Equipment</Typography>
                    <List>
                        <ListItem><ListItemIcon><Usb /></ListItemIcon>USB module</ListItem>
                        <ListItem><ListItemIcon><Usb /></ListItemIcon>Standard Solution 1000 ppm (of the same type of the electrode)</ListItem>
                        <ListItem><ListItemIcon><Usb /></ListItemIcon>Ionic Strength Adjuster (ISA) (of the same type of the electrode)</ListItem>
                        <ListItem><ListItemIcon><Usb /></ListItemIcon>Wash bottle with distilled or deionized water</ListItem>
                        <ListItem><ListItemIcon><Usb /></ListItemIcon>Several clean beakers</ListItem>
                        <ListItem><ListItemIcon><Usb /></ListItemIcon>1 mL and 10 mL pipettes</ListItem>
                    </List>
                </>
            )

            break;
        default:
            return "";
            break;
    }
}


export default class SoftwareItem extends React.Component {

    state = {
        item: null,
        error: null,
        breadcrumbs: []
    }


    componentDidMount() {
        const { id } = this.props.match.params
        this.fetchData(id)
        this.updateBreadcrumbs()
    }

    componentDidUpdate( newProps ){
        if( newProps.match.params.id != this.props.match.params.id){
            this.updateBreadcrumbs()
        }
    }

    async updateBreadcrumbs(){


        const route = window.location.pathname
        const sitemap = await MapService.arrayMap ("/downloads")

        // console.log( "sitemap:", sitemap )
        let breadcrumbs = []
        if( sitemap && sitemap.index ){
            breadcrumbs = sitemap.index[route] ? sitemap.index[route].breadcrumbs : [];
        }

        this.setState({breadcrumbs})

    }

    fetchData = async (slug) => {
        const item = await Service.getOne(slug)
        if (!item) {
            this.setState({ error: Service.error })
        } else {
            this.setState({ item })
        }
    }

    // static getDerivedStateFromProps(props, state) {
    //     const { id } = props.match.params
    //     if (id && Object.keys(downloads.software).includes(id)) {
    //         return { ...state, item: downloads.software[id] }
    //     }
    //     return null
    // }





    render() {
        const { item, error, breadcrumbs } = this.state

        // render 404 if not found
        if (error) {
            return (
                <Page404>
                    <Typography variant="h4">{error}</Typography>
                    <Button component={RouterLink} to="/downloads" variant="contained" color="primary">Back to Downloads page</Button>
                </Page404>
            )
        }

        if (!item) return <CircularProgress />

        // console.log("Item: ", item)

        let { title, link, icon, image, download_type, download_type_title, product_id} = item

        // const crumbs = [
        //     { title: "Home", link: "/" },
        //     { title: "Downloads", link: "/downloads" },
        //     { title: title }
        // ]

        link = link || "";
        // const completeLink = link.startsWith("http") ? link : process.env.REACT_APP_MEDIA + process.env.REACT_APP_SOFTWARE_ROOT + link;
        const completeLink = link.startsWith("http") ? link : process.env.REACT_APP_MEDIA + link;

        // let iconComp = (icon == "Usb") ? Usb : Pdf

        const imageRoot = process.env.REACT_APP_MEDIA

        const imageUrl = `${imageRoot}${image  ? image : 
            // if download type is 5 (firmware update) use sensor name as file name
            download_type === 5 ? `/images/products/sensor-icons/${product_id}.png` : "/images/software/software-default.png"}`
        return (

            <Box>
                { breadcrumbs.length > 0 && <Breadcrumbs items={breadcrumbs} />} 
                {/* <Breadcrumbs items={crumbs} /> */}
                {/* <Hero 
                    title={title}
                    images={[image]}
                    imgRoot={imageRoot}
                    description={description}
                /> */}
                <Reveal>
                    <Container className="pt-4">
                        
                            <Grid container spacing={4}>
                                <Grid item xs={12} md={4}>
                                    <div className="bg-image" style={{ backgroundImage: `url(${imageUrl})`, maxHeight: "400px" }}></div>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Paper>
                                        <Box padding="2em">
                                            <Typography className="mt-0" variant="h1" color="primary">{title}</Typography>
                                            <Typography gutterBottom variant="h5">{download_type_title}</Typography>
                                            <DownloadText item={item} />
                                            <Button size="large" color="primary" variant="contained" component={Link} href={completeLink} >
                                                <GetApp />Download
                                            </Button>
                                        </Box>
                                    </Paper>
                                </Grid>

                            </Grid>
                        
                    </Container>
                </Reveal>
            </Box>

        )
    }
}