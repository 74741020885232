import React from 'react'
import { Paper, Box, Typography, Grid, Button, Container } from '@material-ui/core'
import A from '../../redux/actionTypes'
import {connect} from 'react-redux'

const getYear = () => {
    return 2020
}

function Footer(props) {
    
    const {showContact, showForm} = props
    const year = getYear()
    return (
        <Box bgcolor="primary.dark" className="mt-10 py-5">
            <Container>
            <Grid container>
            <Grid item xs={12} sm={4}></Grid>
            <Grid item xs={12} sm={4}>
                { showContact ? "" :
                    <>
                        <Typography variant="h6" align="center" color="secondary">
                            Have questions?
                        </Typography>

                        <Button onClick={showForm} fullWidth variant="contained" color="secondary" size="large">Contact us!</Button>
                    </>                    
                }
            </Grid>
            <Grid item xs={12} sm={4}>
            </Grid>
                <Grid item xs={12}>
    <Typography variant="subtitle1" color="primary" align="center">© SES Scientific Educational Systems Ltd., {year}</Typography>
                </Grid>
            </Grid>
            </Container>
        </Box>
    )
}

const mapStateToProps = (state) => {
    const { showContact } = state
    return { showContact };
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
        showForm : () => dispatch( { type: A.SHOW_CONTACT } )
    };
  };
  export default connect(
    mapStateToProps, 
    mapDispatchToProps
  )(Footer);