
const bottomItems = (itemCount = 3 ) => {
    let xs = 12, sm

    switch(itemCount){
        case 1:
            sm = 12
            break;
        case 2:
            sm = 6
            break;
        case 4:
            sm = 3
            break;
        case 6:
            sm = 2
            break;
        case 12:
            sm = 1
            break;
        case 3:
        default:
            sm = 4
            break;
    }
    return [...Array(itemCount).keys()].map( (i, ind) => {
        // console.log("Creating bottom item ", ind)
        return bottomItem(ind + 1, xs, sm)
    })

    

    // return res
}
const bottomItem = (index, xs = 12, sm = 4 ) => {
    return {
        component: "grid", props: { xs, sm, item: true, className: "bottom-item" },
        items: [
            {
                component: "a",
                props: {
                    to: `bottom_link_${index}`,
                },
                items: [
                    // { component: "image", props: { src: `bottom_image_${index}`, className: "bottom_image_class" } },
                    {
                        component: "box",
                        props:
                        {
                            className: "display4-image",
                            bgImage: `bottom_image_${index}`,                            
                            HEIGHT: "bottom_height"
                        },
                    },
                    {
                        component: "box",
                        props: { "data-description": true },
                        items: [
                            { component: "typography", props: { variant: "h4" }, items: `header_b_${index}` },
                            { component: "typography", props: { HTML: `text_b_${index}` }, },
                            // { component: "typography", items: `text_b_${index}` }
                        ]
                    }
                ]

            }


        ]
    }
    // return {
    //     component: "grid", props: { xs: 12, sm: 4, item: true },
    //     items: [
    //         { component: "image", props: { src: `bottom_image_${index}`, cls: "bottom_image_class" } },
    //         { component: "typography", props: { variant: "h4" }, items: `header_b_${index}` },
    //         { component: "typography", items: `text_b_${index}` }
    //     ]
    // }  
}

const structure = params => {

    const { bottom_items_count = 3 } = params

    return [
        {
            component: "box", props: { className: "display4" },
            items: [
                {
    
                    component: "grid", props: { className: "widget_class", container: true, spacing: "container_spacing" },
                    items: [
                        {
                            component: "grid", props: { xs: 12, item: true },
                            items: [
                                { component: "typography", props: { variant: "h3" }, items: "top_header" },
    
                                {
                                    component: "a",
                                    props: { to: "top_link" },
                                    items: [
                                        {
                                            component: "grid",
                                            props: {
                                                container: true,
                                                className: "display4-image",
                                                bgImage: "top_image",
                                                "data-top": true,
                                                HEIGHT: "top_height"
                                            },
                                            items: [
                                                {
                                                    component: "grid",
                                                    props: {
                                                        xs: 12, sm: 12, md: 5, item: true
                                                    },
                                                    items: "main_component"
    
                                                },
                                                {
                                                    component: "grid", props: { xs: 12, md: 7, item: true, "data-top-text": true },
                                                    items: [
                                                        {
                                                            component: "typography",
                                                            items: "top_text",
                                                            props: { HTML: "top_html" },
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
    
                                    ]
                                },
                            ]
                        },
                        
                        // inject bottom items
                        ...bottomItems( bottom_items_count ) 
                        ,
                        // bottomItem(1),
                        // bottomItem(2),
                        // bottomItem(3),
    
                        {
                            component: "grid", props: { item: true, xs: 12 },
                            items: [
                                {
                                    component: "a",
                                    props: { to: "final_link", className: "final-link" },
                                    items: "final_link_text"
                                }
                            ]
                        }
                    ]
                },
            ]
        }
    ]
}






export default structure