import React from 'react'
import { Container, Grid, Typography, CircularProgress, Button, Switch } from '@material-ui/core'

import './course-product.scss'
import Reveal from '../../reveal'
import {connect} from 'react-redux'
import A from '../../../redux/actionTypes'
import {Link} from 'react-router-dom'
import {Pageview, Info, CheckBox as Check} from '@material-ui/icons'
import Image from '../../horizontal-card/image'
import PopupProduct from '../products/product-popup'
class Product extends React.Component{

    state = {
        selected: 0,
        item: null,
        countInQuote: true
    }

    componentDidMount(){
        let {item } = this.props

        // item can be either single object or an array of such objects
        if( !Array.isArray(item) ){
            // wrap item with array
            item = [ item ]
        }

        this.setState({item})
    }

    setCountInQuote = ( ev, countInQuote ) => {
        console.log(ev.target.name, countInQuote)
        this.setState({countInQuote})

        const {item, selected} = this.state
        
        console.log( "item", item, "item[selected]", item[selected])
        if( item && item[selected].onChangeInQuote ){
            item[selected].onChangeInQuote(ev.target.name, countInQuote)
        }
    }

    select = (selected) => {

        const oldId = this.state.selected
        const {item} = this.state
        this.setState({selected})

        console.log( "item", item, "item[selected]", item[selected])
        if( item && item[selected].onSelect ){
            // call callback, if passed
            item[selected].onSelect( item[selected], item[oldId] )
        }
    }

    popUp = item => {
        const {showModal,closeModal} = this.props
        // const {title, largeImage, description, link, code, html } = item
        console.debug(" pop up", item)
        showModal( <PopupProduct {...item} closeModal={closeModal} />)
    }
    render(){
        
        
        const {item, selected, countInQuote } = this.state
        console.log(" Selected:", selected)
        // quantity and required params are fetched from the first item in array (they should be equal for each multiple-select items anyway)
        const {required, quantity} = item ? item[0] : {}

        const selectedItem = item ? item[selected] : null

        const isMultiple = item && item.length > 1

        return( 
            <Reveal duration={600} left={true} bottom={false} distance={'50px'}>                
                <Grid container spacing={2} className={`course-product card${ required ? " required" : " optional"}`}>
                    <Grid item xs={12} sm={10}>
                        <Grid container spacing={2}>
                        { item ? 
                            item.map( (product, ind) => {
                                const {title, image} = product
                                const isSelected = ind === selected
                                return(
                                    <Grid item xs={12} key={ind}>                        
                                        <Grid 
                                        container spacing={2} 
                                        className={`itemlist${ isSelected ? " selected" : ""}`} onClick={() => this.select(ind)}                                                                                
                                        >
                                            <Grid item xs={12} sm={2} className="image-container">                                                
                                                <Image image={image} />                                                
                                            </Grid>
                                            <Grid item xs={12} sm={10} >
                                                <Grid container>
                                                    <Grid item xs={9}>
                                                        {title}                                                       
                                                    </Grid>
                                                    <Grid item xs={1} >
                                                        { isMultiple && isSelected ? <Check className="select-check" fontSize={"large"} /> : "" }
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Info className="info" fontSize={"large"} onClick={() => this.popUp(product) } />
                                                    </Grid>
                                                </Grid>
                                                
                                            </Grid>
                                        </Grid>
                                        
                                    </Grid>
                                )
                            })                            
                        : <CircularProgress color="secondary"/>
                        }
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={2} className="quantity" >
                    <Typography>Qty: {required ? quantity : countInQuote ?  quantity : 0  }</Typography>
                        
                        {!required ? <Switch name={selectedItem ? selectedItem.id : null} checked={countInQuote} title="Count in Quote" onChange={this.setCountInQuote } /> : "" }                        
                        {/* { countInQuote ? 
                            <Check className="select-check" fontSize="default" /> : 
                            "" 
                        }   */}
                        { !required ? 
                            <Typography className="optional-text">Optional</Typography>
                            : ""
                        }               
                    </Grid>
                </Grid>                
            </Reveal>
        )
    }
}



const mapDispatchToProps = (dispatch) => {
    return {
        showModal: content => dispatch( {type: A.SHOW_MODAL, payload: content} ),
        closeModal: () => dispatch( {type: A.HIDE_MODAL })
    };
};
export default connect(
    null,
    mapDispatchToProps
)(Product)