import React from 'react'
import { Container } from '@material-ui/core'
import Preview from './category'
import Service from '../../services/blog'
import Card from './item-horizontal'

class Blog extends React.Component {

    state = {
        cat: null,
        items: null,
        page: 1,
        perPage: 2,
        category: null,
        titleTag: "h2"         
    }

    componentDidMount(){
        // const {cat, page = 1 } = this.props.match.params
        // this.setState({cat, page})
        this.fetchData()
    }
    
    componentDidUpdate( prevProps ){
        if( prevProps.cat != this.props.cat || prevProps.page != this.props.page ){
            this.fetchData()
        }
    }

    async fetchData(){
        const {cat, page = 1 } = this.props.match.params
        const {perPage} = this.state
        const items = await Service.getCategoryPreview(cat, page, perPage)
        const category = await Service.getCategory(cat)
        if( Array.isArray(items)){
            this.setState({items, category, page})
        } else {
            this.setState({items: false, category, page:1 })
        }
    }

    
    render() {
        let {state} = this
        state = {
            ...state, 
            itemClass: "category page",
            ItemComponent: Card,
            itemProps: {
                xs: 12, sm: 12, md: 12
            },
            itemComponentProps: {
                titleTypographyProps: {
                    variant: "h3",
                    color: "primary",
                    className: "mt-0 pt-0"
                }  
            }
            

        }
        return (
            <Container>
                <Preview {...state} />
            </Container>
        )
    }
}

export default Blog