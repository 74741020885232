import React from 'react'
import Modal from '../modal'
import { Typography, Grid, TextField, CircularProgress, Button, Container } from '@material-ui/core'
import { connect } from 'react-redux'
import A from '../../redux/actionTypes'
import {H3} from '../template/headers'
import Image from '../common/image'
// import Fade from '../reveal'
// import {Fade, Grow} from '@material-ui/core'
import Service from '../../services/contact'
import CSelect from '../common/select'
import Autocomplete from '../common/autocomplete'
import ConfigSelect from './configuration-select'
const reasons = [
    {title: "I'd like to buy your products"},
    {title: "I need technical help about your products"},
    {title: "I'd like to become a distributor of your products"},
    {title: "Something else"},

]

const media = process.env.REACT_APP_MEDIA
const defaultFormData = {
    country: "",
    name: "",
    email: "",
    phone: null,
    related: null,
    url: null,
    reason: "",
    text: "",    
    config: null
}

const maxMillisecondsSinceLastMessage = process.env.REACT_APP_CONTACT_DELAY || 60 * 1000 * 2 // tweo minutes

const IsSentMessage = props => 
    <>
        <H3>Your message was successfully sent!</H3>        
        <Grid container spacing={4}>
            <Grid item xs={12} sm={4}>
                <Image src={`${media}/images/content/message-sent.jpg`} />
            </Grid>
            <Grid item xs={12} sm={8}>
                <Typography>Our representatives will contact you promptly. </Typography>
                <Typography>Please, wait a few minutes before sending another message.</Typography>
                <hr className="color-primary-light mb-4" />
                <Button variant="contained" color="primary" onClick={props.onClose}>Ok</Button>
            </Grid>
        </Grid>
    </>


class ContactForm extends React.Component {

    state = {        
        countries: null,
        formData: {...defaultFormData},
        configNeeded: false,
        formIsValid: false
    }

    componentDidMount(){
        const url = window.location.pathname
        this.setFormValue( "url", url )
        this.fetchCountries()        
        this.checkIfCanShowMessage()
    }

    componentDidUpdate(){
        const url = window.location.pathname
        if( url != this.state.formData.url )
            this.setFormValue( "url", url )

        this.checkIfCanShowMessage()    
    }

    fetchCountries = async () => {

        const countries = await Service.countries()
        if( countries ){
            this.setState({countries})
        }
    }

    updateValue = ({ target }) => {
        const { name, value } = target
        this.setFormValue(name, value)
    }

    /**
     * Checks if enough time has passed since last message to show the contact form
     */
    checkIfCanShowMessage = () => {
        const {isSent} = this.props
        console.debug("Checking if enough times has passed", isSent )
        if( isSent ){
            const passed = Date.now() - isSent
            console.debug("Passed time:", passed, 'Treshold:', maxMillisecondsSinceLastMessage)
            // if more time that treshold has passed, reset 
            if(  passed >= maxMillisecondsSinceLastMessage  ){
                this.props.resetMessage()
            }
        }        
    }

    setFormValue = ( name, value ) => {
        let {configNeeded} = this.state

        switch(name){
            case "reason":
                // config os only needed for the [1] item of reasons                
                if( value === reasons[1].title ){
                    configNeeded = true;
                } else {
                    configNeeded = false;
                }
                break;
        }

        if (this.state.formData.hasOwnProperty(name)) {

            // if config not needed, reset it to null
            const update = configNeeded ? {[name]: value} : {[name]: value, config: null}

            const {formData} = this.state
            this.setState(
                { 
                    formData: {
                        ...formData, 
                        ...update                   
                    },
                    configNeeded
                })
        } else {
            console.log(`value ${name} not allowed`)
        }
    }

    updateConfigValue = val => {
        this.setFormValue('config', val )
    }

    validateForm = ev => {
        // TODO: validate form
        const form = ev.currentTarget.form

        const {configNeeded} = this.state
        const {config} = this.state.formData
        return form.reportValidity() && (  !configNeeded || ( configNeeded && config ) )
    }

    send = async ev => {

        if( this.validateForm( ev ) ){
            
            const {formData} = this.state

            const result = await Service.send( 
                {...formData,
                // send just the alpha2Code of the country
                country: formData.country ? formData.country.alpha2Code : null
                } 
                )
            if( result ){
                // this.props.onClose()
                this.props.sent()
    
                // reset form data
                this.setState({formData: {...defaultFormData}, configNeeded: false })
    
                // set timer to reset message
                // setTimeout( 
                //     this.props.resetMessage , 60 * 1000 * 2
                // )
            } else {
                this.props.error( Service.error )
            }
        }

        

    }

    render() {
        const { text, country, email, name, phone, reason } = this.state.formData
        const { countries, configNeeded, formIsValid} = this.state
        const { open = false, onClose, isSent } = this.props
        return (
             
            <Modal open={open} onClose={onClose} transition="fade" duration={800}>                
                    <Container>
                <Typography variant="h4" color="primary" className="mt-0">Contact us</Typography>
                <hr className="color-primary-light mb-4" />
                    { 
                    // if message is sent, show 'thank you' text
                    isSent ? <IsSentMessage onClose={onClose} /> :             
                    countries ? 
                    <form >
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField 
                            fullWidth 
                            name="name" 
                            type="text"
                            label="Your name" 
                            value={name} 
                            required                             
                            onChange={this.updateValue}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Autocomplete 
                                autoComplete={true}
                                autoHighlight={true}
                                name="country" 
                                value={country} 
                                label="Select your country"                                 
                                onChange={ (ev, newValue) => this.setFormValue("country", newValue ) }
                                fullWidth
                                options={countries}
                                // titleField="name"
                                // valueField="alpha2Code"
                                getOptionLabel={option => option.name}
                                getOptionSelected = {(option, value) => option.alpha2Code === value }
                                renderInput={(params) => <TextField {...params} required label="Select country" />}
                                // renderOption={ option => <Typography noWrap value={option.alpha2Code}>{option.name}</Typography>}
                            />
                            {/* <CSelect 
                            name="country" 
                            value={country} 
                            label="Select your country" 
                            required
                            onChange={this.updateValue}
                            fullWidth
                            items={countries}
                            titleField="name"
                            valueField="alpha2Code"
                            /> */}
                            {/* <Select name="country" value={country} label="Select your country" 
                            required
                            onChange={this.updateValue}
                            >
                                {countries.map( item => {
                                    return(
                                    <MenuItem value={item.alpha2Code}>{item.name}</MenuItem>
                                    )
                                })}
                            </Select> */}
                        </Grid>                        
                        <Grid item xs={12} md={6}>
                            <TextField 
                            fullWidth 
                            name="email" 
                            type="email"
                            label="Your email" 
                            value={email} 
                            required                             
                            onChange={this.updateValue}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField 
                            fullWidth 
                            name="phone" 
                            type="phone"
                            label="Your phone" 
                            value={phone}                     
                            onChange={this.updateValue}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CSelect 
                            name="reason" 
                            value={reason} 
                            label="What are you inerested in?" 
                            required
                            onChange={this.updateValue}
                            fullWidth
                            items={reasons}
                            titleField="title"
                            valueField="title"
                            />
                        </Grid>
                        { configNeeded ? 
                            <Grid item xs={12}>
                                <ConfigSelect callback={this.updateConfigValue} />
                            </Grid> 
                            : ""    
                        }
                        <Grid item xs={12}>
                            <TextField 
                            fullWidth 
                            name="text" 
                            label="Message text" 
                            value={text} 
                            required  
                            multiline
                            rows={4}
                            variant="outlined"                           
                            onChange={this.updateValue}
                            />
                        </Grid>
                        
                        <Grid item xs={12}>
                            <Typography>* - items marked with asterisk are required</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" onClick={this.send}>Send</Button>
                        </Grid>
                    </Grid> 
                    </form>
                    :
                    <CircularProgress color="primary" />

                    }
                    
                    </Container>
                    
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    const { showContact, messageIsSent } = state
    return { open: showContact, isSent: messageIsSent  };
}

const mapDispatchToProps = (dispatch) => {
    return {
        onClose: () => dispatch({ type: A.HIDE_CONTACT }),
        sent : () => dispatch({type: A.SEND_CONTACT} ), 
        error : message => dispatch({type: A.SHOW_MESSAGE, payload:{message, type: "error"}}), 
        resetMessage: () => dispatch( {type: A.RESET_CONTACT} )
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ContactForm)