import React from 'react';
import './App.scss';
import './components/template/template-selector/light-dark.scss';
import {ThemeProvider, createMuiTheme, Container, CssBaseline} from '@material-ui/core'
import themeData from './config/theme1.json'
import {BrowserRouter as Router, Switch} from 'react-router-dom'
import Footer from  './components/template/footer'
import Navbar from './components/template/navbar'
import Routes from './components/routes'
import routes from './data/routes'
import "typeface-roboto"
import ContactForm from './components/contact-form';
// import ContactForm from './components/custom-modal';
// import {connect} from 'react-redux'
// import A from './redux/actionTypes'
import Toast from './components/toast';
import ReduxModal from './components/modal/redux-modal';


function App( props ) {

  const body = document.getElementsByTagName("body")[0]

  // handle window scroll
  window.window.onscroll =  () => {
    const doc = document.documentElement
    const top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);
    if( top > 140 )  {
      body.classList.add("scrolled")
    } else {
      body.classList.remove("scrolled")
    }
  }

  // const thm = {...themeData, 
  //   typography: {
  //     fontFamily: "Roboto, sans-serif",
  //     // fontWeight: 300
  //   }
  // }


  const theme = createMuiTheme(themeData)
  const {location } = props
  return (
    <React.Fragment>
      
      <ThemeProvider theme={theme}>
        <CssBaseline/>
        <Router>
          <Navbar location={location}/>
          <div id="main-content">
            <Routes routes={routes} />
          </div>          
          <ContactForm />
          <ReduxModal/>
          <Toast />
          <Footer/>  
        </Router>        
      </ThemeProvider>
    </React.Fragment>
  );
}
// const mapStateToProps = (state) => {
//   const {  message } = state
//   return { message };
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//       // closeContact : () => dispatch( { type: A.HIDE_CONTACT } )
//   };
// };
// export default connect(
//   mapStateToProps, 
//   mapDispatchToProps
// )(App);

export default  App;
