import React from 'react'


const ImageComponent = props => {

    const {src, height = "100px", cls } = props
    return (
        <div className={`display4-image ${cls || ''}`} 
        style={{
            backgroundImage: `url(${src})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            height: height      
        }} >            
            {/* <img src={src} height={height} /> */}
        </div>
    )
}

export default ImageComponent