import React from 'react'
import moment from 'moment'

/**
 * Converts date from database format to readable
 */
export default props => {    

    const {value, format = "DD MMM YYYY" } = props

    return (
    <>{moment( value ).format(format)}</>
    )
}