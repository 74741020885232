import React from 'react'

import { Grid } from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import Image from '../image'
import './categories.scss'

const defaultListProps = {
    className: "nested-cats"
}

export default props => (<div className={`categories${props.className ? ` ${props.className}` : ""}`}><Nested {...props} /></div>)

class Nested extends React.Component {

    render(){

        let { children = [], listProps, itemProps, spacingNested = 1, spacingMain = 1, ...other } = this.props
    
        listProps = {...defaultListProps, ...listProps }
        console.log("Category children:", children)

        return (
            <>
                {
                children && Array.isArray(children) && children.length > 0 &&
                <Grid container {...listProps} spacing={spacingMain} >
                    {children.map((i, ind) => i ? 
                        <Grid item {...itemProps} >
                            <CategoriesItem id={ind} item={i} {...this.props } />
                        </Grid> 
                        : ""
                    )}
                </Grid>
                }
            </>
        )
    }

}

/**
 * NB: The component must be placed within a dom element with 'display: flex' in order to fill in the container. 
 * The .h-card-container class can be used for this purpose.
 * @param {*} props 
 */
export const CategoriesItem = props => {

    const {
        title,
        link,
        image,
        subtitle,        
        children
    } = props.item

    let {
        className,
        leftPartSize = 2,
        item,
        itemProps,
        listProps,
        spacingMain = 0,
        spacingNested = 1,
        ...other
    } = props

    // whether NavLink component highlights using exact value
    let exact = true;

    // const {spacing = 1} = listProps


    // if there are nested items, initiate recursion
    if (children && Array.isArray(children) && children.length > 0) {
        // the NavLink is not exact anymore, it should be highlighted when nested items are selected
        exact = false;
    }

    // only offset items with nested children.
    const bottomOffset = !exact ? {marginBottom: spacingNested * 8 } : {}

    return (

        <div className={`cat-card${className ? ` ${className}` : ""}`}>
            <NavLink {...other} to={link} exact={exact} className="link">
                <Grid container className="item" style={bottomOffset}>
                    <Grid item xs={12} sm={leftPartSize} className="image-container">
                        <Image image={image} />
                    </Grid>
                    <Grid item xs={12} sm={12 - leftPartSize} className="card-text-container">
                        <div className="title p-1">{title}</div>
                        {subtitle ? <div className="subtitle p-1">{subtitle}</div> : ""}
                    </Grid>
                </Grid>
            
            {
                // add nested elements in a dublicate pair of items
                !exact &&
                <Grid container className="children" spacing={spacingNested}>
                    <Grid item xs={12} sm={leftPartSize-1}>
                        <div className="offset"></div>
                    </Grid>
                    <Grid item xs={12} sm={12 - leftPartSize+1} className="card-text-container">
                        <Nested {...{listProps, itemProps, className,leftPartSize }} children={children} />
                    </Grid>
                </Grid>
            }
            </NavLink>
        </div>


    )


}


// export default Nested