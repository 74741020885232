import React from 'react'
import { Grid, Card, CardMedia, CardContent, CardActionArea, Typography, Container, CircularProgress } from '@material-ui/core'
// import products from '../data/products.json'
import {Link} from 'react-router-dom'

export default function featProducts(props) {
    const { items } = props

    if( !items ) return <CircularProgress color="secondary" />

    // const list = products.filter(item => items.includes(item.id))
    const list = items
    list.sort((a, b) => a.title < b.title)

    const imageRoot =  process.env.REACT_APP_MEDIA + process.env.REACT_APP_PRODUCTS_SMALL_ROOT  // "http://neulog.images/images/products/140x140/"


    if( items.length ){

        return (
            <Container>
                <Typography variant={'h4'} align={"center"} color="primary">Featured products</Typography>
                <Grid container spacing={2} justify={"center"}>
                    {list.map(item => {
                        let { id, title, images } = item
                        console.log("Product item", item)
                        images = Array.isArray(images) && images.length ? images : [ "no-image.png"]
    
                        return (
                            <Grid item xs={12} sm={3} key={id}>
                                <Card>
                                    <CardActionArea component={Link} to={`/products/item/${id}`}>
                                        <CardMedia
                                            component="img"
                                            alt=""
                                            height="100"
                                            image={`${imageRoot}${images[0]}`}
                                            title={title}
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="caption">
                                                {title}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        )
                    }
                    )}
                </Grid>
            </Container>
        )
    } else {
        return ""        
    }
}