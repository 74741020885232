import React from 'react'
import { Avatar, Grid, Card, CardActionArea, CardHeader, CardContent, CardMedia } from '@material-ui/core'
import {NavLink } from 'react-router-dom'
import Reveal from '../reveal'
const BlogItem = props => {

    let {
        title,
         link = "",
         intro,
         subtitle,
         itemClass="blog-item",
         titleClass="blog-item-title",
         titleTypographyProps ,
         images,
         category,
         imageHeight = "200px",
         itemHeight = "430px"
         } = props

    titleTypographyProps = titleTypographyProps || {className: titleClass, variant: "body1"}         
    console.log("titleTypographyProps", titleTypographyProps)
    // let avatarLetters = "" 
    // if( category ){
    //     switch(category.publishing_cat_id){
    //         case 2:
    //             avatarLetters = "BL" 
    //             break;
    //         case 3:
    //             avatarLetters = "NW"
    //             break;
    //     }
    // }
    const image = 
    process.env.REACT_APP_MEDIA + "/images/content/" +
    (Array.isArray(images) && images[0] ? images[0] : "neulog-blog-default.jpg")
    console.log("Image:", image)

    return (
        <Reveal  >
            <Card className={`${itemClass}`} style={{height: itemHeight}}>
                <CardActionArea component={NavLink} to={link}>
                    <CardMedia className="image" image={image} style={{height: imageHeight}}></CardMedia>
                    <CardHeader 
                        // avatar ={
                        //     <Avatar className="bg-secondary color-white">{avatarLetters}</Avatar>
                        // }
                        titleTypographyProps={ titleTypographyProps }
                        title={title}
                        subheader={subtitle}
                    />
                    { intro ? <CardContent dangerouslySetInnerHTML={{__html: intro}}></CardContent> : "" }
                    
                </CardActionArea>
            </Card>
        </Reveal>
    )
}

export default BlogItem