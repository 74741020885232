import React from 'react'
import { Typography } from '@material-ui/core'


export const H = props => {
    return (<Typography variant={`h${props.level}`} color="primary" {...props} />)
}
export const H1 = props => {
    // return (<Typography variant="h1" color="primary" {...props} />)
    return (<H level={1} {...props} />)
}
export const H2 = props => {
    // return (<Typography variant="h2" color="primary" {...props} />)
    return (<H level={2} {...props} />)
}
export const H3 = props => {
    // return (<Typography variant="h3" color="primary" {...props} />)
    return (<H level={3} {...props} />)
}
export const H4 = props => {
    // return (<Typography variant="h4" color="primary" {...props} />)
    return (<H level={4} {...props} />)
}
export const H5 = props => {
    // return (<Typography variant="h4" color="primary" {...props} />)
    return (<H level={5} {...props} />)
}
export const H6 = props => {
    // return (<Typography variant="h4" color="primary" {...props} />)
    return (<H level={6} {...props} />)
}