import React from 'react'
import Hero from '../../hero/hero.display'
import products from "../../../data/products.json"
import experiments from "../../../data/experiments-total.json"
import solutions from "../../../data/labs.json"
import service from '../../../services/products'
import P404 from '../404'

import { Container, Grid, Typography, Box, CircularProgress } from '@material-ui/core'
import Breadcrumbs from '../../breadcrumbs'
import { makeStyles } from '@material-ui/core/styles'
import ExperimentList from '../../experiments/featuredExperiments'
import Requirements from "../../requirements"
// import MList from '../list/multilist'
import Video from '../../youtube'
import Courses from '../courses'

import Downloads from '../../../services/downloads'
import DownloadItem from '../../horizontal-card/download'
import List from '../../list/simpleList'

import Reveal from '../../reveal'

const breadcrumbsStyle = {
    "paddingTop": "1em",
    "paddingBottom": "1em",
    position: "absolute",
    top: "-2em"

}

const styles = makeStyles(
    theme => ({
        breadcrumbsStyle: {
            paddingTop: "1em",
            paddingBottom: "1em",
            position: "absolute",
            top: "-4em",
            width: "100%"

        },
        container: {
            position: "relative"
        }
    })
)





export default class Product extends React.Component {

    state = {
        item: {},
        experiments: null,
        courses: null,
        loading: true,
        downloads: null,
        error: null
    }

    constructor(props) {
        super(props)
        // console.log(props)
        
    }

    componentDidMount(){        
        this.fetchItem( this.props.match.params.id )                   
    }

    componentDidUpdate( oldProps ){
        if( oldProps.match.params.id !== this.props.match.params.id ){
            this.fetchItem( this.props.match.params.id )           
        }
    }

    async fetchItem( id ){
        const item = await service.getOne(id)
        if( item ){
            this.setState( {item, loading: false } )

            this.fetchCourses(id)
            this.fetchDownoads( id )

        } else {
            this.setState( {item: false, error: service.error, loading: false })
        }
    }

    async fetchExperiments( id ){
        const experiments = await service.getProductExperiments(id)
        console.log("Fetched experiments:", experiments )
        if( experiments ){
            this.setState( {experiments} )
        }
    }
    async fetchCourses( id ){
        const courses = await service.getProductCourses(id)        
        if( courses ){
            this.setState( {courses} )
        }
    }

    async fetchDownoads( code ){
        const downloads = await Downloads.getProductDownloads( code )
        this.setState({downloads})
    }
    

    render() {
        let { id, title, manual, images, description, youtube = [] ,html, config } = this.state.item
        // let {loading, experiments} = this.state
        let {loading, courses, downloads, error } = this.state

        if( loading ){
            return (
                <Container>
                    <div style={{display: "flex", width: "100%", height: "100%", alignItems: "center", justifyContent: "center"}}>
                        <div>
                            <CircularProgress/>                            
                        </div>
                    </div>                 
                </Container>
            )
        }

        if( ! id ){
            id = this.props.match.params.id
            return (
                <P404>
                    {/* <Typography variant="h5" color="error">Product with code '{id}' does not exist or is not published anymore.</Typography> */}
                    <Typography variant="h5" color="error">{error}</Typography>
                </P404>
                )
        }


        description = description || ""
        // solutions = Array.isArray(solutions) ? solutions: []
        // experiments = Array.isArray(experiments) ? experiments: []

        const pdfRoot = process.env.REACT_APP_MEDIA + process.env.REACT_APP_PDF_PRODUCTS_ROOT
        const imgRoot = process.env.REACT_APP_MEDIA + process.env.REACT_APP_PRODUCTS_BIG_ROOT  


        images = Array.isArray(images) && images.length ? images : ["no-image.png"]

        const crumbs = [
            { title: "Home", link: "/" },
            { title: "Products", link: "/products" },
            { title: title }
        ]

        
        console.log("youtube:", youtube)
        return (
            
            <Box style={{ position: "relative" }}>
                <Breadcrumbs items={crumbs} />
                <Reveal>
                 <Hero 
                 title={title} 
                 subtitle={id}
                 images={images} 
                 description={description} 
                 imgRoot={imgRoot} //"http://neulog.images/images/products/466x466/"
                 />
                <Container style={{ marginTop: "2em" }}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={6}>
                            <Container className="bg-opaque-6 py-1 bg-rounded">
                                {
                                    youtube.length > 0 ? <Video id={youtube[0]} mute="1" autoplay="1"  /> : ""
                                }
                                { 
                                html ? 
                                    <div><Typography color="primary" variant={'h4'} align={"center"}>Description</Typography>
                                    <div dangerouslySetInnerHTML={{ __html: html }}></div></div>
                                    : ""
                                }                                
                            </Container>                            
                        </Grid>
                        <Grid item xs={12} md={6} >  
                        {/* <Container>
                                <SolutionList items={solutions} />
                            </Container> */}
                            {/* {manual ? 
                            <Container>
                                <Typography color="primary" variant={'h4'} align={"center"}>Manual</Typography>
                                <a href={`${pdfRoot}${manual}`} target="_blank">
                                    <Grid direction={"column"} align={"center"} >
                                        <PdfIcon fontSize="large" color="secondary" />
                                        <Typography>
                                            Download Manual in PDF
                                        </Typography>
                                    </Grid>
                                </a>
                            </Container> 
                            : <Container><Typography variant="h5">No manual uploaded</Typography></Container>
                        } */}

                            <Container align={"center"}>
                                <Typography color="primary" variant="h4" gutterBottom>Downloads</Typography>
                                <List 
                                noItemsText="This product is being updated"
                                xs={12} sm={12} md={12} lg={12} xl={12}
                                items={ downloads ? downloads.map(dwnl =>{ return {...dwnl, link: process.env.REACT_APP_MEDIA + dwnl.link, spacing: 3 }}) : null }
                                ItemComponent={DownloadItem}
                                />
                            </Container>
                            <Courses items={courses} config={config.coursesConfig} />                       
                            <ExperimentList id={id} />     
                            {/* <ExperimentList items={experiments || []} />                             */}
                        </Grid>
                        <Grid item xs={12}>
                            <Requirements product={this.state.item}/>
                        </Grid>
                    </Grid>                    
                </Container>      
                </Reveal>          
            </Box>
            
        )
    }
}