import React from 'react'
import {Container, Typography, Grid, CircularProgress, Button} from '@material-ui/core'
import Static from '../../../blog/article-template'
import Courses from '../../courses'
import service from '../../../../services/products'
import StaticService from '../../../../services/blog'
import Video from '../../../common/video'
import Youtube from '../../../youtube-multi'
import Image from '../../../common/image'
import Service from '../../../../services/blog'

import './sense.scss'

const mediaRoot = process.env.REACT_APP_MEDIA

const htmlPlayerProps = {
    loop: 1,
    autoplay: 1,
    muted: true,
    controls: false
}

const youtubePlayerVars = {
    enablejsapi: 1,
    autoplay: 1,
    mute: true,
    loop: 1
}

class Sense extends React.Component{

    state = {
        courses: null,
        article: null,
        items: null
    }

    componentDidMount(){
        this.fetchData()
        this.fetchCourses("SENSE")
    }

    async fetchCourses( id ){
        const courses = await service.getProductCourses(id)
        console.log("Fetched courses:", courses )
        if( courses ){
            this.setState( {courses} )
        }
    }

    async fetchData  (){
        // const article = await StaticService.getStatic( "SENSE" )
        // if( article ){
        //     this.setState({article})
        // } else {
        //     this.setState({ article: { title: "Error", html: StaticService.error }})
        // }

        const item1 = await Service.getStatic("sense-is")
        const item2 = await Service.getStatic("sense-includes")
        const item3 = await Service.getStatic("sense-challenges")

        if( item1 && item2  ){
            console.log("fetched atricles", item1, item2)
            this.setState({items:[
                {...item1, itemId: "sense-is"}, 
                {...item2, itemId: "sense-includes"}, 
                {...item3, itemId: "sense-challenges"}
            ]})
        } else {
            this.setState({items: false})
        }
    }






    render(){
        const {courses, article, items } = this.state
        
        const coursesConfig = {
            title: "Sense Autonomous",
            subtitle: "Coding Intelligent Robots",
            html : "<p><a href=\"/labs/robotics\">Sense Robotics Programs</a> originate innovation, creativity and coding skills with the following course units.</p> <p>Each unit contains 15 lessons.</p>"
        }

        return(
            <Container className="sense">
                {/* <Static {...article} /> */}
                {
                    items === null ? <CircularProgress/> :
                    items === false ? <Typography variant="h3" color="error">Error loading data. Please, try reloading the page.</Typography> :
                    items.map( item =>     
                            
                            <Grid container spacing={4} id={item.itemId}>
                                
                                {item.title && item.title != " " ?                             
                                // if not empty, than show it. Empty title  single space as a title will remove the header.
                                <Grid item xs={12}>
                                    <Typography variant="h2" color="primary">{item.title}</Typography>
                                </Grid> :
                                ""
                                }
                                <Grid item xs={12} md={4} className="card bg-none">
                                    { item.youtube.length > 0 ? 
                                    <Youtube 
                                    id={item.youtube} 
                                    playerVars={youtubePlayerVars}
                                    
                                    />
                                    :
                                    // if local video is set, show HTML5 player
                                    item.video.length > 0 ? <Video source={item.video.map(v => `${mediaRoot}/${v}`) } playerProps = {htmlPlayerProps} /> 
                                    :
                                    <Image image={ `${mediaRoot}/images/content/${item.images[0] || "/no-image.png"}`} />
                                    }
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Typography component="div" variant="body1" dangerouslySetInnerHTML={{__html: item.html || ""}}></Typography>
                                </Grid>
                            </Grid>
                            
                        )
                }
                <Courses items={courses} config={coursesConfig} itemProps={{xs: 12, md:6}} inContainer={false} />
            </Container>
        )
    }
}

export default Sense