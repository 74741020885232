import React from 'react'
import { Grid, Typography, CircularProgress, Container } from '@material-ui/core'
import Service from '../../services/blog'
import List from './categoryPreview'
import './right-block.scss'

const errorLoading = process.env.REACT_APP_ERROR_LOADING || "Error loading"

class RightBlock extends React.Component {

    state ={
        news: null,
        blog: null,
        newsCat: null, 
        blogCat: null 
    }

    componentDidMount(){
        this.fetchData()
    }

    componentDidUpdate( prevProps ){
        if(prevProps.current_id !== this.props.current_id ){
            this.fetchData()
        }
    }

    async fetchData () {
        const {current_id} = this.props


        let news = await Service.getCategoryPreview('news', 1, 3 )
        let blog = await Service.getCategoryPreview('articles', 1, 3 )

        const newsCat = await Service.getCategory("news")
        const blogCat = await Service.getCategory("articles")

        
        // filter out current record
        news = news ? news.filter( item => item.publishing_id !== current_id ) : news
        blog = blog ? blog.filter( item => item.publishing_id !== current_id ) : blog
        
        this.setState({news, blog, newsCat, blogCat })
        
    }


    render() {
        const {news, blog, newsCat, blogCat } = this.state 

        return (
             !newsCat || !blogCat ? <Container><CircularProgress/></Container> : 
             <Grid container spacing={4} className="right-block">
                <Grid item xs={12}>                    
                    { news === null ? <Container><CircularProgress/></Container> : news === false ? <Typography variant="h4" color="error">{errorLoading}</Typography> : 
                    <List 
                    titleClass="bg-primary bg-texture01 color-white px-2 py-1"
                    showPagination={false} 
                    title="News"
                    readMoreLink={`/${newsCat.path}`}
                    itemProps={{ 
                        xs:12                        
                    }}
                    category={{category_id: 3, title: "News"}}
                    
                    items={ news.map( n => (
                        {...n, 
                            itemHeight: "auto",
                            titleTypographyProps: { className: "card-title" },
                            intro: null
                        })) }
                    />  }
                </Grid>
                <Grid item xs={12}>                    
                    { blog === null ? <Container><CircularProgress/></Container> : blog === false ? <Typography variant="h4" color="error">{errorLoading}</Typography> : 
                    <List 
                    titleClass="bg-primary-light bg-texture01 color-white px-2 py-1"
                    showPagination={false} 
                    title="Articles"
                    readMoreLink={`/${blogCat.path}`}
                    itemProps={{ 
                        xs:12                        
                    }}
                    category={{category_id: 3, title: "Articles"}}
                    
                    items={ blog.map( n => (
                        {...n, 
                            itemHeight: "auto",
                            titleTypographyProps: { className: "card-title" },
                            intro: null
                        })) }
                    />  }
                </Grid>
            </Grid>
            
        )
    }
}

export default RightBlock