import React from 'react'
import {Grid, Typography, Container, Box } from '@material-ui/core'
import {Link } from 'react-router-dom'



const parsePropWithData = (prop, data ) => 
    // if set in data object, use value from there, otherwise use the value itself
    typeof prop === "string" && typeof data[prop] !== "undefined" ? data[prop] 
    // otherwise, just leave it alone
    : prop ;

const parsePropsWithData = (props, data ) => Object.keys(props).reduce( (result, propKey ) => {
    const prop = props[propKey]

    // if set in data object, use value from there, otherwise use the value itself
    // const dataProp = typeof prop === "string" && typeof data[prop] !== "undefined" ? data[prop] 
    // // otherwise, just leave it alone
    // : prop ;
    const dataProp = parsePropWithData( prop, data )

    return {...result, [propKey]: dataProp }
}, {})

const parseProps = (props, item, style ) => Object.keys(props).reduce( (result, propKey) => {
                        
    const dataProp = props[propKey]
    
    switch( propKey ){

        // special key HTML: prepare dangerously set inner html
        case "HTML":
            // if items are set, use items, cause it's either items or inner html
            console.log( "item.items", item)
            // if( item.items ) 
            //     return result;
            // else 
            return {
                // add to result...
                ...result, 
                // an item with prop key as key
                dangerouslySetInnerHTML: 
                {
                    __html: dataProp
                }                                    
            }                        
            break;
        // speacial key bgImage: add background image to style
        case "bgImage":
            // if style has been already set, extend it
            style = result.style || {}
            return {
                // add to result...
                ...result, 
                // an item with prop key as key
                style: {
                    ...style,
                    backgroundImage: `url(${dataProp})`
                }
            }     
            break;
        // special key HEIGHT: set style height value
        case "HEIGHT":
            // if style has been already set, extend it
            style = result.style || {}
            return {
                // add to result...
                ...result, 
                // an item with prop key as key
                style: {
                    ...style,
                    height: dataProp
                }
            }     
            break;
        
        // default case: process the value or leave alone
        default:return {
            // add to result...
            ...result, 
            // an item with prop key as key
            [propKey]: dataProp                                
        }                        
            break;
    }
    
}, {} )


/**
 * Component that renders a structure (array of objects) passed to it.
 * Single object sturcture:
 * 
 * {
 *  string component - index of component in components (defaultComponents) object
 *  object props - props to pass to the component
 *  array items - list of children items to render ( children are treated recursively and have the same structure)
 * }
 * 
 * @param {*} props 
 * props contain:
 * - array items - an array of items to render
 * - object components - an object holding hashed renderable components to chose from
 * - object data - optional data for rendering
 * 
 * components and data are passed on to nested items.
 * 
 * Parametrisation: 
 * data object's keys are matched to every string value in items and in props, if key matches - value from data object is used.
 */
const PageItem = myProps => {
    let {items =[], components = {}, data = {} } = myProps


    // merge default and passed components
    components = {...defaultComponents, ...components }

    return (
        <>
        {items.map( 
            (item, key) => {
                    let {component, props = {} } = item
                    let style;

                    // match props keys to replace key values with corresponding values from data, if any
                    console.log("before", props)


                    // fetch data elements, if any
                    props = parsePropsWithData( props, data )

                    // parse special values
                    props = parseProps( props, item, style )

                    console.log("after", props)

                    // if HTML is not set, use nested items
                    if( !props.dangerouslySetInnerHTML || ( props.dangerouslySetInnerHTML && !props.dangerouslySetInnerHTML.__html )){

                        // if nested items is a string, attempt to parse it with data
                        const nestedItems = typeof item.items === "string"   ?  parsePropWithData ( item.items, data ) : item.items
                        const childrenProps = { items: nestedItems, components, data }
    
                        const children = // if it's an array, pass it to nested PageItem, otherwise just use the string
                            Array.isArray(nestedItems) ? <PageItem {...childrenProps}/> : nestedItems
    
                            // Old variant: it wasn't parsed with data at this moment:
                            // if children is a string, try to match it to a key in data object
                            // and if so - use value from data
                            // :  typeof nestedItems === "string" ? data[nestedItems]  :    
                            // otherwise, use the string as it is
                            // nestedItems;
                        props = {...props, children }
                    }

                    props = {...props, key  }
                    
                    const MyComponent = components[component]
                    

                    return ( 
                        <MyComponent {...props}/>
                        // <MyComponent {...props}>                          
                        //     {                             
                        //     // if it's an array, pass it to nested PageItem
                        //     Array.isArray(children) ? <PageItem {...childrenProps}/> 

                        //     // if children is a string, try to match it to a key in data object
                        //     // and if so - use value from data
                        //     : data[children]  ? data[children] : 

                        //     // otherwise, use the string as it is
                        //     children }
                        // </MyComponent>
                    )
                }
            )
        }
        </>
    )
}

const defaultComponents = {    
    'grid' : Grid,
    'typography': Typography,
    'container': Container,
    'Page': PageItem,
    'box': Box,
    'a': Link,    
}

export default PageItem;