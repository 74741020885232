const menuItems = [
    { title: "Products",link: "/products"},
    { title: "Labs",link: "/labs"},
    { title: "Experiments",  link: "/experiments" },
    { title: "Articles",  link: "/articles" },
    { title: "Downloads",  link: "/downloads" },
    { title: "About",  link: "/about" }
]


export default menuItems