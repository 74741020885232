import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
// import Button from '@material-ui/core/Button';
import { Typography, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom'
import PopUp from '../pages/products/product-popup'
import './productListItem.scss'

// redux
import {connect} from 'react-redux'
import A from '../../redux/actionTypes'

import Reveal from '../reveal'





const ProductListItem = props => {
  // const classes = useStyles();

  // action = popup|link
  // if action=popup, a popup will show reduced info on the product with a link to product page
  // if action=link (or not popup) - click on the product will redirect to product page
  let { item, imageRoot, bgcolor } = props


  bgcolor = bgcolor || ""
  
  const { id, title, code, action = "popup" } = item
  // console.log("props:", props,  "action:", action)
  
  const link = `/products/item/${id}`
  const image = Array.isArray(item.images) && item.images.length ? item.images[0] : "no-image.png"
  const height = props.height || 140

  const popUp = item => {
    const {showModal,closeModal} = props
    // const {title, largeImage, description, link, code, html } = item
    console.debug(" pop up", item)
    showModal( <PopUp {...item} closeModal={closeModal} />)
  }
  
  const PopUpWrapper = props => (
    <CardActionArea onClick={() => popUp(item) }>{props.children}</CardActionArea>
  )
  
  const LinkWrapper = props => (
    <CardActionArea component={Link} to={props.link}>{props.children}</CardActionArea>
  )

  const WrapperComponent = (action === "popup") ? PopUpWrapper : LinkWrapper;
  

  return (

    <Reveal className="h-100">
    <Card className={`product-list-item ${bgcolor}`}>
      {/* <CardActionArea component={Link} to={link}> */}
      {/* <CardActionArea onClick={() => popUp(item) }> */}
      <WrapperComponent link={link}>
        <CardMedia
          component="img"
          alt=""
          height={height}
          image={`${imageRoot}${image}`}
          title={title}
        />
        <CardContent>
          <Typography className="caption">{title}</Typography>
          <Typography className="subtitle">{code}</Typography>
        </CardContent>
      {/* </CardActionArea> */}
      </WrapperComponent>
      {/* <CardActions className={`${classes.cardActions}`}>
        <Button size="small" color="primary" component={Link} to={link} >
          Learn More
            </Button>
      </CardActions> */}
    </Card>
    </Reveal>

  );
}


const mapDispatchToProps = (dispatch) => {
  return {
      showModal: content => dispatch( {type: A.SHOW_MODAL, payload: content} ),
      closeModal: () => dispatch( {type: A.HIDE_MODAL })
  };
};
export default connect(
  null,
  mapDispatchToProps
)(ProductListItem)