import React, {useState} from 'react'
import { Grid, Card, CardMedia, CardContent, CardActionArea, Typography, Container, CircularProgress } from '@material-ui/core'
// import experiments from '../data/experiments.json'
import {Link} from 'react-router-dom'
import Service from '../../services/experiments'
import PService from '../../services/products'
import './experiments.scss'

export default function FeatExperiments(props) {
    // const { items } = props || []
    const {id} = props

    const [ experiments, setExperiments ] = useState( null )

    const fetchExperiments = async () => {
        console.log("fetching experiments...", id )
        const expList = await PService.getProductExperiments(id)
        
        // const  expList = await Service.getAll()
        if( expList ){
            console.log("Experiments fetched", expList )
            setExperiments(expList)
        } else {
            console.log("Error fetching product experiments!")
        }
    }


    // start fetching experiments
    fetchExperiments();


    if( experiments ){
        console.log("experiment items" , experiments)
        // const list = experiments.filter( exp => items.includes( exp.code) )
        const imageRoot = process.env.REACT_APP_MEDIA + process.env.REACT_APP_EXPERIMENTS_ROOT // "http://neulog.images/images/experiments/"
        if (experiments.length)
            return (
                <Container>
                    <Typography variant={'h4'} color="primary" align={"center"}>Experiments</Typography>
                    <Grid container spacing={2} justify={"center"}>
                        {experiments.map(item => {
    
                            const { name, title, images, category } = item
    
                            return (
                                <Grid item xs={12} sm={3}>
                                    <Card className="experiment-item">
                                        <CardActionArea component={Link} to={`/experiments/${category}/${name}`}>
                                            <CardMedia
                                                component="img"
                                                alt=""
                                                height="100"
                                                image={`${imageRoot}${images[0] || "no-image.png"}`}
                                                title={title}
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="caption">
                                                    {title}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            )
                        }
    
                        )}
                    </Grid>
                </Container>
            )
        else
            return ""
    } else {        
        return <CircularProgress/>
    }

}