import React from 'react'
import { Grid, Container } from '@material-ui/core'
import Drawer from '../../common/responsive-drawer'
import Categories from '../../common/categories'
import Breadcrumbs from '../../breadcrumbs'
import MapService from '../../../services/map'
import Course from './course'
import Lab from './lab'

import '../../common/categories/tpl-light.scss'
import { H } from '../../template/headers'

class MainSets extends React.Component {

    state = {
        sitemap: null,
        index: null,
        breadcrumbs: [],
        title: null,
        categories: null
    }

    componentDidMount() {
        this.fetchSitemap( this.updateBreadcrumbs )
        this.fetchCategories()
    }

    componentDidUpdate( oldProps ){
        const {id, course: course_id} = oldProps.match.params        
        const {id : newid, course: newcourse_id} = this.props.match.params

        // TODO: if route changed, update breadcrumbs
        if( id !== newid || course_id !== newcourse_id ){
            this.updateBreadcrumbs()    
        }
    }

    async fetchCategories(){
        const labs = await MapService.getElement("/labs")
        if( labs && labs.children ){
            this.setState({categories: labs.children})
        }
    }

    updateBreadcrumbs = () => {
        const {sitemap} = this.state
        const {title, breadcrumbs} = sitemap
        console.log( "title, breadcrumbs:", title, breadcrumbs )

        // replace long title with short "Labs" in second breadcrumb (TODO: REFACTOR THIS!!!)
        if( breadcrumbs && breadcrumbs[1] ){
            breadcrumbs[1].title = "Labs"
        }

        this.setState({breadcrumbs, title})
        // const {root = "/labs"} = this.props
        // const {id, course: course_id} = this.props.match.params
        // const {sitemap, index} = this.state

        // const setLink = `${root}/${id}`        
        // const fullLink = `${setLink}${ course_id ? `/${course_id}` : ''}`

        // // console.log( "map node:", index)
        // // const node = MapService.traverseMap( { link:"", children: sitemap}, fullLink, null, '/labs' )
        // const node = index[fullLink]
        // // console.debug("map node:", node, fullLink)
        
        // if( node ){
        //     const {title, breadcrumbs} = node
        //     // replace long title with short "Labs" in second breadcrumb (TODO: REFACTOR THIS!!!)
        //     // if( breadcrumbs[1] ){
        //     //     breadcrumbs[1].title = "Labs"
        //     // }
        //     this.setState({breadcrumbs, title})
        // }      

    }

    fetchSitemap = async (  callback ) => {
        
        // let data = await MapService.arrayMap("/labs")        
        let sitemap = await MapService.getElement( window.location.pathname )
        console.log("Element map:", sitemap )
        // if( data && data.tree && data.index ){
        //     const sitemap = data.tree[0].children
        //     const index = data.index
        //     // console.debug("Labs map:", sitemap )
        //     this.setState({ sitemap, index }, callback )
        // }
        this.setState({sitemap}, callback)
        
       
       
    }

    render(){
        const {categories, spacing = 4, breadcrumbs = [] , title } = this.state
        const {id, course: course_id } = this.props.match.params  
        console.log("Categories:", categories)
        return(
            <>
            <Breadcrumbs items={breadcrumbs} />
            <Container className="labs">            
            <Grid container spacing={spacing}>
                <Grid item xs={12}>      
                    <H level={2}>{title}</H>              
                </Grid>
                <Grid item xs={12} sm={6} md={3} >
                    <Drawer title="Labs">
                        <Categories children={categories} leftPartSize={3} itemProps={{ xs: 12, sm: 12 } } spacingMain={2} spacingNested={1}  />
                    </Drawer>
                </Grid>
                <Grid item xs={12} sm={6} md={9}>
                    { course_id && <Course {...this.props} /> }
                    { !course_id && id && <Lab {...this.props} /> }
                </Grid>
            </Grid>
            </Container>
            </>
        )
    }
}

export default MainSets