import React from 'react'
import List from '../../list/simpleList'
import { Typography, Container } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import {H} from '../../template/headers'
import Service from '../../../services/downloads'
import MapService from '../../../services/map'
import Item from '../../list/linkListItem'
import Breadcrumbs from '../../breadcrumbs'

class Firmware extends React.Component{

    state = {
        allItems: [],
        items: [],
        breadcrumbs: []
    }

    componentDidMount(){
        this.fetchData()
    }

    async fetchData(){
        let allItems = await Service.byTypes( [ 5 ] )        
        if(Array.isArray(allItems)){
            allItems = allItems.map( item => 
                // put item within object
                ({ 
                    item,
                    // set link base route
                    linkBase: "/downloads/firmware",
                    bgcolor: "primaryLight",
                    showDownloadButton: false // hide download button: we need the user to visit the page and read the instructions
                }) )
        }

        // const sitemap = await MapService.arrayMap ("/downloads")
        const sitemap = await MapService.getElement("/downloads/firmware")

        // console.log( "sitemap:", sitemap, "download items:" , allItems )
        let breadcrumbs = []
        if( sitemap && sitemap.breadcrumbs ){
            breadcrumbs = sitemap.breadcrumbs;
        }

        this.setState({allItems, items: allItems, breadcrumbs })
        
    }

    onSearch = s => {
        
        const items = s ? // if search term not empty
        // filter all items with non-empty title that, when in lower case, contains search term in lwer case
        this.state.allItems.filter( i => i.item.title && i.item.title.toLowerCase().includes(s.toLowerCase()) ) :
        // if search term is empty, use all items
        this.state.allItems
        this.setState({items})
    }

    render(){
        const {items, breadcrumbs } = this.state
        const searchProps = {
            callback: this.onSearch,
            label: "Search firmware updates"
        }
        return(
            <>
            { breadcrumbs.length > 0 && <Breadcrumbs items={breadcrumbs}/> }
            <Container>
                <H level={1}>Firmware update</H>
                <Alert severity="warning" variant="filled">Please, only upgrade your product's firmware if you really know what you are doing. A fail to follow the exact instructions during firmware update may damage the product!</Alert>
                <List 
                {...{items, searchProps}} 
                showSearch={true} 
                className="mt-2" 
                ItemComponent={Item} 
                sm={6}
                md={2}
                lg={2}
                xl={2}
                />
            </Container>
            </>
        )
        
    }
}

export default Firmware