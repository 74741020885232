import React from 'react'



export default props => {
 
    
    const {playerProps, source = []} = props


    return (
        <video {...playerProps}>
            {source.map( (src,ind) => (<source key={`video-pl-${ind}`} src={src} />) )}
        </video>
    )
}