import React from 'react'
import './display4.scss'
import Page from '../page'
import Image from './image'
import Youtube from '../youtube-multi'
import structure from './structure'


const components = {
    image: Image, 
    youtube: Youtube
}

/**
 * default widget values. Override them by passing 'data' prop
 */
const defaultData = {    
    top_image_class: "top-image",    
    widget_class: "default", // change to customize widget style. It is the class for second level from the top of component's DOM.
    main_component: [],
    // container_spacing: number from 0 to 6 - Matreial UI Grid spacing value
    // top_header: text for widget header
    // top_height: string, e.g. "200px" - height of the top section.
    // bottom_height: string, e.g. "200px" - height of each element in the bottom section
    
    // top_text: String for the top section text. If present, top_html is discarded and top_text is used instead
    // top_html: string for the top section HTML. 
    // top_image: URL of top image
    
    // bottom_image_1: URL for bottom image 1
    // bottom_image_2: URL for bottom image 2
    // bottom_image_3: URL for bottom image 3
    
    // header_b_1: Text for bottom section header 1
    // header_b_2: Text for bottom section header 2
    // header_b_3: Text for bottom section header 3
    
    // text_b_1: Text for bottom section description 1
    // text_b_2: Text for bottom section description 2
    // text_b_3: Text for bottom section description 3
    
    // top_link: URL for top section link
    // bottom_link_1: URL for lower section link 1
    // bottom_link_2: URL for lower section link 2
    // bottom_link_3: URL for lower section link 3
    
    // final_link: URL for bottom closing link
    // final_link_text: text for bottom closing link
}

const Display4 = props => {
    // const { bottom_items_count = 3} = props.data //oh... that's sloppy coding, but oh well
    const items = structure(props.data)
    const myProps ={
        components,        
        items,
        ...props, 
        ...{ data: { ...defaultData, ...props.data} } // shallow-copy override default data}
    }

    return (
        <Page {...myProps}/>
    )
}

export default Display4