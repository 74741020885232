import React from 'react'
import { Grid, Container, Typography, CircularProgress  } from '@material-ui/core'
import {Link} from 'react-router-dom'
import Service from '../../services/blog'
import Item from './item'
import {H1, H3} from '../template/headers'
import Reveal from '../reveal'

import Preview from  './categoryPreview'

class About extends React.Component {

    state = {
        article: null,
        news: null,
        blog: null,
        articleError: null,
        newsError: null
    }
    
    componentDidMount(){
        this.fetchArticle()
        this.fetchNews()
        this.fetchBlog()
    }

    fetchArticle = async () => {
        const article = await Service.getStatic("about")
        if( article ){
            this.setState({article})            
        } else {
            this.setState({articleError: Service.error})
        }
    }

    fetchNews = async () => {
        const news = await Service.getCategoryPreview("news", 1, 2)
        if( news ){
            this.setState({news})            
        } else {
            this.setState({newsError: Service.error})
        }
    }

    fetchBlog = async () => {
        const blog = await Service.getCategoryPreview("articles", 1, 2)
        if( blog ){
            this.setState({blog})            
        } else {
            this.setState({blogError: Service.error})
        }
    }

    render(){

        const {newsError, articleError, blogError, article, news, blog } = this.state

        const error  = articleError || blogError || newsError

        // if( error ) return <Container><Typography variant="h1">Error</Typography><Typography variant="body2" color="error">{error}</Typography></Container>        
        // if( !article ) return <Container><CircularProgress color="primary"/></Container> 
        const {title, subtitle, html } = article || { title: "..."}
        return (
            <Reveal>
            <Container>
                <H1>{title}</H1>
                <Typography variant="subtitle1">{subtitle}</Typography>
                {
                    article ? 
                    <>
                    <Typography variant="body1" dangerouslySetInnerHTML={{__html: html}}></Typography>                
                    <Grid container spacing={4} className="preview">
                        <Grid item xs={12} sm={6}>
                            <Preview 
                                showPagination={false}
                                items={news}
                                titleClass="bg-texture01"
                                itemProps={{xs:12, sm: 6}}
                                title={"NeuLog News"}
                                category={{publish_category_id: 2}}
                                readMoreLink="/about/news"
                                bottomLinkText="More NeuLog News"
                                bottomLinkClass="bottom-link bg-texture01"
                                
                            />
                            {/* <Typography gutterBottom variant="h3" className="bg-texture01">NeuLog News</Typography>
                            { newsError ? <Typography variant="h5">{newsError}</Typography> :                         
                                <Grid container spacing={2}>
                                    { news ? news.map(newsItem => {
                                    return <Grid item xs={12} md={6}><Item {...{...newsItem, link: `/about/news/${newsItem.slug}`, html: newsItem.intro }} /></Grid>
                                    }) : <CircularProgress/> }
                                    <Grid item xs={12}>
                                        <Link to="/about/news" className="align-right color-white block-link bg-texture01 bg-primary">More NeuLog News</Link>
                                    </Grid>
                                </Grid>                        
                            } */}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        { articleError ? <Typography variant="h5">{articleError}</Typography> : 
                            <Preview 
                                showPagination={false}
                                items={blog}
                                titleClass="bg-texture01 bg-primary-light"
                                itemProps={{xs:12, sm: 6}}
                                title={"NeuLog Articles"}
                                category={{publish_category_id: 2}}
                                readMoreLink="/articles"
                                bottomLinkText="More NeuLog Blog"
                                bottomLinkClass="bottom-link bg-texture01 bg-primary-light"
                                
                            />
                        }
                            
                        </Grid>
                        
                        
                    </Grid> 
                    </>
                :

                error ?
                    <>
                        <Typography variant="h4" color="error">{error}</Typography>
                    </>
                 
                : <CircularProgress/>
                }

                
            </Container>
            </Reveal>
        )
    }
}

export default About