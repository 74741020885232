import axios from 'axios'
import {removePrefix} from '../utils/functions'

const mediaRoot = process.env.REACT_APP_MEDIA
const setMetaTag = (tag, value) => {
    try {
        document.querySelector(`meta[name="${tag}"]`).setAttribute("content", value)
    } catch (e) {
        console.error("Error finding tag ", tag)
    }
}
const setOgTag = (tag, value) => {
    try {
        document.querySelector(`meta[property="og:${tag}"]`).setAttribute("content", value)
    } catch (e) {
        console.error("Error finding tag og:", tag)
    }
}

class SiteMapService {
    static async get(route) {
        console.debug("Tag updates is called: ", route)
        if (SiteMapService.routes[route])
            return SiteMapService.routes[route]
        else
            try {
                console.debug("Tag updates: fetching data. Cached data: ", SiteMapService.routes[route])
                const { data } = await axios.get(`${process.env.REACT_APP_API}/map${route}`)
                if (data) {
                    if (data['title'].toLowerCase().indexOf("neulog") !== 0) {
                        data['title'] = `Neulog:: ${data['title']}`
                    }
                    SiteMapService.routes[route] = data
                    console.debug("Tag updates Data cached, data value: ", SiteMapService.routes[route])
                    return SiteMapService.routes[route]
                } else {
                    console.error("Unknown error fetching site map. Expected object, got:", data)
                    return false
                }
            } catch (e) {
                console.error("Error fetching sitemap", e)
                SiteMapService.error = e.response ? e.response.data : e.message
                return false;
            }
    }

    static async getElement( route ){
        console.debug("Map requested for route: ", route)
        const completeMap = await this.getCompleteMap()
        if( completeMap && completeMap.index ){
            return completeMap.index[route] || false
        } else return false
    }

    static async map(route) {
       

            if (SiteMapService.maps[route])
                return SiteMapService.maps[route]
            else
                try {
                    console.debug("Map request: fetching data. Cached data: ", SiteMapService.maps[route])
                    const { data } = await axios.get(`${process.env.REACT_APP_API}/map/_map${route}`)
                    if (data) {                    
                        SiteMapService.maps[route] = data
                        console.debug("Map request Data cached, data value: ", SiteMapService.maps[route])
                        return SiteMapService.maps[route]
                    } else {
                        console.error("Unknown error fetching site map. Expected object, got:", data)
                        return false
                    }
                } catch (e) {
                    console.error("Error fetching sitemap", e)
                    SiteMapService.error = e.response ? e.response.data : e.message
                    return false;
                }
        

    }


    static async getCompleteMap(){
        if( !this.completeSitemap ){
            try{            
                const { data } = await axios.get(`${process.env.REACT_APP_API}/map/flat`)
                if( data && data.index ){

                    this.completeSitemap = data
                    return this.completeSitemap
                }
            } catch(e){
                console.error(e.message)
                this.error = "Error fetching data from server"
            }
        } else {
            return this.completeSitemap
        }
    }

    /**
     * Returns site map from route as an object holding map tree and a flattenned array of objects hashed by route.
     * @param {*} route 
     */
    static async arrayMap(route) {
        console.debug("array Map requested for route: ", route)
        if (SiteMapService.arrayMaps[route])
            return SiteMapService.arrayMaps[route]
        else{
            let map
            try {

                if( SiteMapService.maps[route]){
                    map = SiteMapService.maps[route]
                } else {
                    console.debug("array Map request: fetching data. Cached data: ", SiteMapService.arrayMaps[route])
                    const { data } = await axios.get(`${process.env.REACT_APP_API}/map/_map${route}`)
                    if (data) {                       
                        map = data
                    } else {
                        console.error("Unknown error fetching site map. Expected object, got:", data)
                        return false
                    }    
                }
                
                // traverse map and convert children to arrays of objects
                const result = this.mapToArray( map, route )

                // cache the result
                SiteMapService.arrayMaps[route] = result;

                /// return cached result
                return SiteMapService.arrayMaps[route];
                
            } catch (e) {
                console.error("Error fetching sitemap", e)
                SiteMapService.error = e.response ? e.response.data : e.message
                return false;
            }
            
        }
            
    }

    static traverseMap ( map, root, idField = "link", base ="/", childrenField = "children" ){

        // remove base
        root = removePrefix(root, base)


        const chunks = root.split("/")
        console.debug("map node chunks:" , chunks)

        if( Array.isArray(chunks) && chunks.length > 0 ){
            // remove first empty chunk, if any
            if(chunks[0] === "" ) chunks.shift()

            // get first element
            const id = chunks.shift()
            console.debug("map node next chunk:" , id)

            // if 
            if( map[idField] === id && chunks.length === 0 ) return map; 

            console.debug("map node is not this" )            

            if(  Array.isArray(map.children) ){
                console.debug("map node children is array:" , id)

                const node = map.children.find( item => item[idField] === id )
                if( node ){
                    console.debug("map node going fursther:" , chunks.join("/"), node )
                    return this.traverseMap( node, chunks.join("/"), idField, base, childrenField )
                } 
            } 
        } 
        return false;
    }

    /**
     * 
     * @param {*} map 
     * @param {*} index 
     * @param {*} keyField 
     * @param {*} breadcrumbs 
     * @param {*} indexedItems 
     * @returns { { tree: array, index: object } } tree is an array of objects with nested arrays of objects. index is a flattened tree indexed by keyfield (link by default)
     * TODO: it adds an item indexed 'undefined' with the last (I think) element parsed. Catch it and remove it.
     */
    static mapToArray( map, index = "", keyField = "link", breadcrumbs = [ {title: "Home", link: "/" } ], indexedItems = {} ){
        // store the index as the item's field "key"
        map[keyField] = index
        map.image = `${mediaRoot}${map.image}` 
        

        map.breadcrumbs = [...breadcrumbs, { title: map.title, link: map[keyField] } ]

        if( map.children ){
            map.children = Object.keys( map.children ).reduce( (res, key ) => {
                // recursion
                let item = this.mapToArray( map.children[key] , `${index}/${key}`, keyField, map.breadcrumbs, indexedItems )
                const { tree } = item
        
                // add child to indexed object
                indexedItems[ tree[keyField] ] = tree

                // add item to the array
                return [...res, ...tree ]                    
            }, [])
        }

        // add item itself to indexed object
        indexedItems[ map[keyField]] = map

        const res = {
            tree: [ map ],
            index: indexedItems
        } 
        // console.log("Map result", res)
        return res

    }

    /**
     * Mounts tags values from passed data
     * @param {*} data 
     */
    static mountTags(data) {
        const { href } = window.location

        // set title
        document.title = data['title']

        // set meta description
        setMetaTag("description", data["description"])

        // og tags
        setOgTag("description", data["description"])
        setOgTag("image", data["image"])
        setOgTag("title", data["title"])
        setOgTag("url", href)

    }

    /**
     * updates all head tags on the page to the current location
     */
    static async updatePage() {
        const data = await SiteMapService.get(window.location.pathname)
        if (data)
            SiteMapService.mountTags(data)
    }
}

SiteMapService.completeSitemap = null
SiteMapService.routes = {}
SiteMapService.maps = {}
SiteMapService.arrayMaps = {}

export default SiteMapService