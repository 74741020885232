import React from 'react'
import { Grid, Typography, Paper, Card, CardMedia, Box } from '@material-ui/core'
import { withTheme, makeStyles, withStyles } from '@material-ui/core/styles'
import './hero.scss'

const styles = makeStyles(
    theme => (
        {
            bigtitle: {
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center"
            }
        }
    )
)

const BBox = withStyles(
    {
        root : {
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",            
            padding:"2em 1em",
            
            "& h2": {
                fontSize: "3em",
                marginTop: "0",
                marginBottom: "0"
            },

            "& h5":{
                marginTop: "0.2em"
            }
        },

        
    }
)(Box)

class HeroDisplay extends React.Component {


    render() {        
        const imgRoot = this.props.imgRoot || process.env.REACT_APP_MEDIA + process.env.REACT_APP_EXPERIMENTS_ROOT

        console.log("props", this.props)
        const { theme, title, subtitle, images, description, midSectionMd = 2, midSectionSm= 6  } = this.props

        const { dark, light, main } = theme.palette.primary


        return (
            <Box elevation={0} style={{ backgroundColor: light, marginBottom: "3em" }}  >
                <Grid container spacing={0} alignItems={"center"} direction={"row"}>
                    <Grid item xs={12} sm={(12 - midSectionSm) } md={ (12 - midSectionMd) / 2 }  >
                        <BBox>
                            <Typography variant={'h2'} align={'right'} style={{ color: 'white' }}>
                                {title}
                            </Typography>
                            {subtitle ? <Typography color="primary" variant={'h5'}  align={'right'}>{subtitle}</Typography> : ""}
                        </BBox>
                    </Grid>
                    <Grid item sm={midSectionSm} md={midSectionMd} xs={12}>
                        <Card elevation={0} square={true} >
                            <CardMedia
                                image={`${imgRoot}${images[0]}`}
                                title={title}
                                style={{ height: 0, paddingTop: '80%' }}
                                rounded={0}
                            />
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={(12 - midSectionMd) / 2} >
                        <BBox>
                            <Typography  className="subtitle" align="left">
                                {description}
                            </Typography>
                        </BBox>
                    </Grid>
                </Grid>
            </Box>

        )
    }
}

export default withTheme(HeroDisplay);