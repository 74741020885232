import React from 'react'
import List from '../../list/multilist'
// import downloads from '../../../data/downloads.json'
import Service from '../../../services/downloads'
import { Container, Typography, CircularProgress } from '@material-ui/core'
import Item from '../../list/linkListItem'
import { H } from '../../template/headers'
import {Link} from 'react-router-dom'


const Error = props => {

    return (
        <Container>
            <Typography variant="h1" color="primary">Software Downloads</Typography>
            <Typography variant="h5" color="error">Error loading data, please try reloading the page</Typography>
        </Container>
    )
}

class Software extends React.Component {


    state = {
        softwareDownloads: null,
        firmwareDownloads: null,
        calibrationDownloads: null,
        allDownloads: null,
        errorLoading: false,
        firmwareSearch: ""
    }

    componentDidMount(){
        this.prepareData();
    }

    async prepareData() {

        let data = await Service.byProduct()        

        if( ! data ||!Array.isArray(data) ) {
            this.setState({ errorLoading: true }) 
            console.log("Error loading downloads data", data )
        }
        else {
            

            // populate default images
            data = data.map( item => {
                if( item.download_type === 5) item.image = `/images/products/sensor-icons/${item.product_id}.png`

                return { ...item, image: item.image || "/images/software/software-default.png" } 
            })

            // store the data
            this.setState({ allDownloads: data })
    
            //const items = Object.keys(downloads.software).reduce((result, key) => [...result, { ...downloads.software[key], id: key }], [])
            const softwareWin   = data.filter( item => item.download_type === 3)
            const softwareMac   = data.filter( item => item.download_type === 4)
            // const firmware      = data.filter( item => item.download_type === 5)

            // just one calibration link is needed, as they are all the same
            const calibration   = data.filter( item => item.download_type === 6).slice(0,1)

            //const items2 = Object.keys(downloads.firmware).reduce((result, key) => [...result, { ...downloads.firmware[key], id: key }], [])
    
            const softwareDownloads = [
    
                {
                    title: "Software for Windows",
                    description: "All the necessary items to receive and display data from NeuLog sensors on a PC with Windows operating system.",
                    items: softwareWin, //items.slice(0, 3),
                    itemComponent: Item,
                    bgcolor: "primaryLight",
                    itemsPerRow: 4
                },
    
                {
                    title: "Software for MAC",
                    description: "All the necessary items to receive and display data from NeuLog sensors on a MAC computer.",
                    items: softwareMac, // items.slice(3, 7),
                    itemComponent: Item,
                    itemsPerRow: 4,
                    bgcolor: "primaryDark",
                },
    
            ]
    
            // const firmwareDownloads = [
            //     {
            //         title: "Firmware Updates for Windows",
            //         description: "Use these files to upgrade the firmware of your sensors",
            //         bgcolor: "primaryLight",
            //         items:  firmware, //items2,
            //         itemComponent: Item,
            //         itemsPerRow: 6,
            //         showSearch: true,
            //         searchProps: {
            //             label: "Search firmware"
            //         }
            //     },
            // ]

            const calibrationDownloads = [
                {
                    title: "Sensor calibration software for Windows",
                    description: "This software is required for offsetting the following sensors: Calcium logger sensor NUL-238, Chloride logger sensor NUL-239, Ammonium logger sensor NUL-240, Nitrare logger sensor NUL-241",
                    items:  calibration,
                    itemComponent: Item
                },
            ]

            // this.filterFirmware( data, "" )
           

            this.setState({
                softwareDownloads, 
                calibrationDownloads 
            })
        }



    }

    // filterFirmware = ( allDownloads, firmwareSearch ) => {
    
    //     let firmwareDownloads, firmware;

    //     if( Array.isArray(allDownloads) ){
    //         // filter 
    //         firmware = allDownloads.filter( item => item.download_type === 5 )

    //         if( firmwareSearch !== "" ){
    //             firmware = firmware.filter(  item => item.id.includes(firmwareSearch) || item.title.includes(firmwareSearch) )
    //         }

    //         firmwareDownloads = [
    //             {
    //                 title: "Firmware Updates for Windows",
    //                 description: "Use these files to upgrade the firmware of your sensors",
    //                 bgcolor: "primaryLight",
    //                 items:  firmware, //items2,
    //                 itemComponent: Item,
    //                 itemsPerRow: 6,
    //                 showSearch: true,
    //                 searchProps: {
    //                     label: "Search firmware",
    //                     callback: this.onSearch
    //                 }
    //             },
    //         ]
    //     } else {
    //         firmwareDownloads = [
    //             {
    //                 title: "Firmware Updates for Windows",
    //                 description: "Use these files to upgrade the firmware of your sensors",
    //                 bgcolor: "primaryLight",
    //                 items:  null, //items2,
    //                 itemComponent: Item,
    //                 itemsPerRow: 6,
    //                 showSearch: true,
    //                 searchProps: {
    //                     label: "Search firmware"
    //                 }
    //             },
    //         ]
    //     }

    //     this.setState({firmwareDownloads})
    // }


    // onSearch = firmwareSearch => {
    //     this.filterFirmware( this.state.allDownloads, firmwareSearch )
    // }

    render() {

        const {softwareDownloads, firmwareDownloads, calibrationDownloads, errorLoading } = this.state

        if( errorLoading ) return <Error />
        return (
            <Container>
                {/* <Typography color="primary" variant="h1" gutterBottom>NeuLog Downloads</Typography> */}
                {/* <List lg={3} items={items} ItemComponent={LinkItem} /> */}

                {/* { !softwareDownloads || !firmwareDownloads ?  */}
                { !softwareDownloads  ?
                <>
                    <Typography variant="h1" color="primary">Software Downloads</Typography>
                    <CircularProgress color="primary"/> 
                </>
                :
                <>
                    <List title="Software Downloads" items={softwareDownloads} height="200px" />
                    <List title="Calibration Software" items={calibrationDownloads} />

                     {/* <List title="Firmware Updates" items={firmwareDownloads} /> */}
                     <H level={3}>Firmware upgrade</H>
                     <Typography>There are cases when you need to upgrade the firmware of your product. It does not happen frequently and is not recommended unless you are completely aware of what you are doing. You can find the list of firmware downloads and instructions <Link to="/downloads/firmware">here</Link></Typography>
                </>
                }
            </Container>
        )
    }
}

export default Software