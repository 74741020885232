import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Grid, GridList, Paper, CardContent, Typography } from '@material-ui/core';
import {Skeleton} from '@material-ui/lab'
import Reveal from '../reveal'
import Search from '../search'
import './simpleList.scss'

// const useStyles = makeStyles(theme => ({
//   root: {
//     display: 'flex',
//     flexWrap: 'wrap',
//     justifyContent: 'space-around',
//     overflow: 'hidden',
//     backgroundColor: "none" //theme.palette.background.paper,
//   },
//   gridList: {
//     width: 500,
//     height: 450,
//   },
//   icon: {
//     color: 'rgba(255, 255, 255, 0.54)',
//   },
// }));

/**
 * The example data is structured as follows:
 *
 * import image from 'path/to/image.jpg';
 * [etc...]
 *
 * const tileData = [
 *   {
 *     img: image,
 *     title: 'Image',
 *     author: 'author',
 *   },
 *   {
 *     [etc...]
 *   },
 * ];
 */


const ListSkeleton = props =>{
  const showSekeleton = props.showSekeleton || true
  const {justifyContent, xs, sm, md, lg, xl} = props
  return (
    <>
    {showSekeleton ? 
    
    <Grid container spacing={4} justify={justifyContent}>
      
    {["", "", "", "", "", ""].map((item, ind) =>
    
      <Grid
        key={ind} 
        item
        xs={xs} sm={sm} md={md} lg={lg} xl={xl}
      >
        
          <Paper>
            <Skeleton variant="rect" width="100%" height="140px" />
            <CardContent>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Typography variant="body2" style={{color: "rgba(0,0,0,0.3)"}}>Loading...</Typography>
            </CardContent>
          </Paper>         
      </Grid>)}      
    </Grid>    
    

    : "" }
    </>
  )
}

export default class itemList extends React.Component {

  render() {
    let { 
      items, 
      // imageRoot, 
      // additional = {}, 
      // itemProps = {},
      justifyContent = "center",
      noItemsText = "No items found",
      showSearch = false,
      searchProps = {},
      spacing = 4,
      itemClass="list-item",
      className
    } = this.props 
    
    // Define grid
    const xs = this.props.xs || 12
    const sm = this.props.sm || xs
    const md = this.props.md || 6
    const lg = this.props.lg || 4
    const xl = this.props.xl || lg
    const height = this.props.height || 140
    const bgcolor = this.props.bgcolor || ""
    const showSekeleton = this.props.showSekeleton || true

    const skelProps = {showSekeleton,justifyContent, xs, sm, md, lg, xl}
    
    if( !Array.isArray(items) ) return <ListSkeleton {...skelProps} />

    const { ItemComponent = ( a => { return <div>{a.title}</div> }) } = this.props

    return (
      <>            
      <Grid container spacing={spacing} justify={justifyContent} className={`simple-list${ className ? ` ${className}` : ""}`}>
        {showSearch ? <Grid item xs={12}><Search {...searchProps}/></Grid> : "" }
        
        { items.length > 0 ?
         items.map((item, ind) =>
          <Grid
            className={itemClass}
            key={ind} 
            item
            xs={xs} sm={sm} md={md} lg={lg} xl={xl}
          >
            <ItemComponent
              {...item}
            />                             
          </Grid>)
          : <Typography variant="h5" color="primary">{noItemsText}</Typography> 
          }          
      </Grid>
      </>      
    )
  }
}