import React from 'react'
import {Container, Typography, CircularProgress} from '@material-ui/core'
import List from '../../list/itemList'
// import solutions from '../../../data/labs.json'
import Service from '../../../services/sets'
// import {sets} from '../../../data/routes.js'
import Breadcrumbs from '../../breadcrumbs'
import ItemSol from '../../list/solutionListItem'
import "./solutions.scss"

export default class Solutions extends React.Component {


    state = {
        sets: null
    }

    componentDidMount(){
        this.fetchSets()
    }

    fetchSets = async () => {
        const sets = await Service.getAll()
        // console.log(" sets", sets )
        if( Array.isArray(sets)  ){
            this.setState({sets})
        } else {
            this.setState({sets: false })
        }
    }

    render() {

        const {sets} = this.state

        const breadcrumbs = [
            { title: "Home", link: "/"},
            { title: "Labs"},         
        ]
        
        return (            
            <>
            <Breadcrumbs items={breadcrumbs} />
            <Container>                
                <Typography variant="h1" color="primary">Complete training lab solutions</Typography>
                <Typography>
                <p>We offer an extensive set of carefully crafted solutions for training lab in vocational schools, technical colleges, universities and industry.</p>
                <p>
                    Each set consists of all the necessary equipment, configured to match the training lab workflow. Our training lab solutions have been tested and are successfully used all around the world.
                </p>
                <p>
                    Find the solution that fits your needs!
                </p>
                </Typography>
                {
                    sets === null ? <CircularProgress /> : 
                    sets === false ? <Typography variant="h4" color="error">Error fetching data, please try reloading the page.</Typography> :
                    <List items={sets} itemClass="list-item" ItemComponent={ItemSol} imageRoot={`${process.env.REACT_APP_MEDIA}/images/`} />        
                    /* <List items={list}  ItemComponent={ItemSol} imageRoot={`${process.env.REACT_APP_MEDIA}/images/`} />         */
                }
            </Container>            
            </>
        )
    }
}