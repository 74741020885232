import React from 'react'
// import {PopupMessage, MyState} from '../../redux/reducers'
import A from '../../redux/actionTypes'
import {connect} from 'react-redux'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Toast extends React.Component {

    
    render(){

        const {duration = 6000, close } = this.props
        const messageData = this.props.message
        if( messageData ){
            console.debug(`message data:`, messageData)
            const {message, type} = messageData
            return(
                <Snackbar open={true} autoHideDuration={duration} onClose={close}>
                    <Alert onClose={close} severity={type}>{message}</Alert>
                </Snackbar>
                )
        } else {
            return "";
        }

    }
}




const mapStateToProps = (state ) => {
    const { message } = state
    return { message };
}

const mapDispatchToProps = (dispatch , ownProps ) => {
    return {
        close : () => dispatch( { type: A.HIDE_MESSAGE })
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Toast);