import axios from  'axios'
import Sitemap from './map'
// import SiteMap from '../../../api.neulog.com/services/map'

export default class BlogService {

    static async getCategory( slug ){
        if( ! BlogService.categories[slug] ){
            try{
                let {data} = await axios.get(process.env.REACT_APP_API + "/blog/category/" + slug )                                
                BlogService.categories[slug] = data
                return data
            } catch ( e ){
                //TODO: log
                console.log(e)
                BlogService.error = e.response ? e.response.data : e.message
    
                return false;
            }
        } else {
            return  BlogService.categories[slug]
        }
    }


    static async getCategoryPreview( slug, page = 1, perPage=10 ){
        const hash = `${slug}/${page}/${perPage}`
        if( ! BlogService.previews[hash] ){
            try{
                let {data} = await axios.get(process.env.REACT_APP_API + "/blog/category/preview/" + slug  + `?p=${page}&perPage=${perPage}`)                                
                BlogService.previews[hash] = data
                return data
            } catch ( e ){
                //TODO: log
                console.log(e)
                BlogService.error = e.response ? e.response.data : e.message
    
                return false;
            }
        } else {
            return  BlogService.previews[hash]
        }

    }
    
   
    static async getOne( slug ){

        if( ! BlogService.articles[slug] ){
            try{

                let {data} = await axios.get(process.env.REACT_APP_API + "/blog/item/" + slug )                                               
                let breadcrumbs

                // // adjust root
                // const mapRoot = root === "news" ? "about" : root
                // root = root === "news" ?  "about/news" : root
                // const adjustedRoot = `/${root}/${slug}`


                // console.log("Adjusted root:", adjustedRoot, "map root:", mapRoot)
                // // fetch corresponding breadcrumbs
                // const map = await Sitemap.arrayMap( `/${mapRoot}` )
                // console.log("Map:", map)
                
                // if( map.index && map.index[adjustedRoot]){
                //     breadcrumbs = map.index[adjustedRoot].breadcrumbs
                // }
                const map = await Sitemap.getElement( window.location.pathname )
                if( map && map.breadcrumbs ){
                    breadcrumbs = map.breadcrumbs
                }
                // mix the breadcrumbs in
                data = {...data, breadcrumbs }

                BlogService.articles[slug] = data                
                
                return data
            } catch ( e ){
                //TODO: log
                console.log(e.response)
                BlogService.error = e.response ? e.response.data : e.message
    
                return false;
            }
        } else {
            return  BlogService.articles[slug]
        }

    }
    
    static async getStatic( slug ){

        if( ! BlogService.articles[slug] ){
            try{

                let {data} = await axios.get(process.env.REACT_APP_API + "/blog/static/" + slug )                                               
                BlogService.articles[slug] = data
                // console.log(data)
                return data
            } catch ( e ){
                //TODO: log
                console.log(e.response)
                BlogService.error = e.response ? e.response.data : e.message
    
                return false;
            }
        } else {
            return  BlogService.articles[slug]
        }

    }


}

BlogService.previews = {}
BlogService.categories = {}
BlogService.articles = {}
BlogService.error = null