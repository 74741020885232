import React from 'react'
import List from '../list/itemList'
import { Container, Typography, CircularProgress, Grid } from '@material-ui/core'
import list from '../../data/experiments-total.json'
// import categories from '../../data/experiment-categories.json'
import ItemComponent from './experimentListItem'
import CategoryItem from '../horizontal-card'
import Breadcrumbs from '../breadcrumbs'
import Service from '../../services/experiments'
import Reveal from '../reveal'
import Image from '../common/image'

const imageLink = images => `${process.env.REACT_APP_MEDIA}/${ Array.isArray(images) ? images[0] : images }`
const neulogExperiments = { 
    name: "", 
    title: "All NeuLog Experiments", 
    exact: true, 
    images: ["images/content/neulog-experiments.png"] 
}
export default class ExperimentCategory extends React.Component {

    state = {
        categories: null,
        search: "",
        list: null
    }

    componentDidMount() {
        this.fetchCategories()
        this.fetchItems()
    }

    fetchCategories = async () => {
        let categories = await Service.getCategories()
        
        // prepend "all" category
        if( Array.isArray( categories ) ){
            categories = [ neulogExperiments, ...categories ]
        }
        this.setState({ categories })
    }

    fetchItems = async () => {
        const list = await Service.getAll()
        this.setState({ list })
    }


    onSearch = search => this.setState({ search: search.toLowerCase() })

    render() {
        const { name = "" } = this.props.match.params

        const { categories, list, search } = this.state

        // if (categories === null) return <Container><CircularProgress /></Container>
        // if (categories === false) return <Typography variant="h4">Error fetching categories data</Typography>


        const items =
            list ?
                // if name is passed, then filter by it as category, otherwise - use all items
                (name ? list.filter(item => item.category === name) : list)
                    .filter(item => search === "" || item.title.toLowerCase().includes(search) || item.code.toLowerCase().includes(search))
                : null // []

        // const category =
        //     name ?
        //         categories.find(cat => cat.name === name) || { title: "Category not found" } :
        //         { title: "All NeuLog Experiments" }
        const category = categories ?  categories.find(cat => cat.name === name) || { title: "Category not found" }  : neulogExperiments
        console.log(category)
        const crumbs = [
            { title: "Home", link: "/" },
            { title: "Experiments", link: "/experiments" }
        ]

        if (name) {
            crumbs.push({ title: category.title })
        }

        return (
            <div style={{ position: "relative" }}>
                <Breadcrumbs items={crumbs} />
                <Container className="experiment-category">
                    <Reveal>
                        
                        <Grid container className="mt-2">
                            <Grid item xs={3} md={1} className="image-container">
                                <Image image={imageLink( category.images )}/>
                            </Grid>
                            <Grid item xs={9} md={11}>
                                <Typography variant="h1" color="primary" className="mt-2 mb-1">                                                                
                                    {category.title}                                 
                                </Typography>
                            </Grid>
                        </Grid>
                            
                        
                        {
                            categories ? 

                            <Grid container spacing={4}>                        
                            <Grid item xs={12} sm={4} md={3}>
                                <div className="experiment-categories">
                                    <Typography variant="h3" color="primary">Categories</Typography>  
                                    <List 
                                    spacing={1}
                                    xs={6} sm={12} md={12} lg={12} xl={12}
                                    itemProps = {{exact: true}}
                                    items={categories.map( cat => { 
                                        return {...cat, 
                                            image: imageLink( cat.images ),
                                            link: `/experiments/${cat.name}`
                                            }                                        
                                        })                                    
                                        }                                 
                                    ItemComponent={CategoryItem} />                              
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={8} md={9}>                                
                                {list ?
                                    <List
                                        className="mt-8"                                                                                
                                        lg={3}
                                        items={items}
                                        imageRoot={`${process.env.REACT_APP_MEDIA}/images/experiments/`}
                                        ItemComponent={ItemComponent}
                                        showSearch={true}
                                        searchProps={{ callback: this.onSearch, label: "Search experiments" }}
                                    />
                                    : list === false ? <Typography color="error">Error fetching experiments data. Please, reload the page.</Typography> : <CircularProgress color="secondary" />
                                }
                            </Grid>

                        </Grid>
                        :
                        categories === null ? <CircularProgress/>
                        :                        
                        <Typography variant="h4" color="error">Error fetching data. Please, try reloading the page.</Typography>
                        }
                        

                    </Reveal>
                </Container>
            </div>
        )
    }
}