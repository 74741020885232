import React from 'react'
import { Card, Grid, CardActionArea, CardContent } from '@material-ui/core'
import {NavLink} from 'react-router-dom'
import Image from './image'
import './styles.scss'

/**
 * NB: The component must be placed within a dom element with 'display: flex' in order to fill in the container. 
 * The .h-card-container class can be used for this purpose.
 * @param {*} props 
 */

const HorizontalCard = props => {


    const { title, link, image, exact = false, subtitle } = props.item
    const {leftPartSize = 4} =  props

    return (
        
        <NavLink to={link} className="card" exact={exact}>
            <Grid container >
                <Grid item xs={12} sm={leftPartSize} className="image-container">
                    {/* <CardActionArea link={link}> */}
                    
                        <Image image={image} />
                    
                    {/* </CardActionArea> */}
                </Grid>
                <Grid item xs={12} sm={12 - leftPartSize} className="card-text-container">
                    <div className="title p-1">{title}</div>
                    { subtitle ? <div className="subtitle p-1">{subtitle}</div> : "" }
                    {/* <CardContent className="title">{title}</CardContent>
                    { subtitle ? <CardContent className="subtitle">{subtitle}</CardContent> : "" } */}
                </Grid>
            </Grid>
        </NavLink>
        
    )


}


export default HorizontalCard