import React from 'react'
import {Container, Typography, Grid, TextField, Button, CircularProgress, FormControl } from '@material-ui/core'
import  CSelect from '../../common/select'

import Service from '../../../services/contact'


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import A from '../../../redux/actionTypes'
import {connect} from 'react-redux'
import "./order.scss"


class ResultOrder extends React.Component {

    state = {
        countries: null,
        total: 0,
        formData: {
            country: "",
            name: "",
            email: "",
            phone: null,
            related: null,
            url: null,
            reason: "",
            text: "",
            sets: 1,
            courseRef: null,
            quote: null            
        }
    }

    componentDidMount(){

        const {quote, courseRef} = this.props
        const {formData} = this.state
        this.setState({ formData: {...formData, quote: {...quote}, courseRef } }, () => this.updateQuoteValues() )

        this.fetchCountries()
    }

    fetchCountries = async () => {
        
        const countries = await Service.countries()
        if( countries ){
            this.setState({countries})
        }
    }

    /** 
     * Update quote quantities based on sets number
     */
    updateQuoteValues = () => {
        const {formData} = this.state
        const {sets} = formData
        const {quote} = this.props
        
        let total = 0;
        const newQuote = quote.map( p => { 
            total += p.quantity * sets
            return {...p, quantity: p.quantity * sets }
        })  
        this.setState( { total, formData: {...formData, quote: newQuote } } )
    }

    updateValue = ({ target }) => {
        const { name, value } = target
        if (this.state.formData.hasOwnProperty(name)) {
            const {formData} = this.state

            switch(name){
                case "sets":
                    if( value < 1 ){
                        console.debug("Minimum value is 1")
                        return false;
                    }
                    break;
            }

            this.setState(
                { 
                    formData: {
                        ...formData, 
                        [name]: value 
                    }
                }, () => {
                    // if number of sets have changed, update the quote quantities
                    if( formData.sets !== this.state.formData.sets ){
                        this.updateQuoteValues()
                    }
                })
        } else {
            console.log(`value ${name} not allowed`)
        }
    }

    send = () => {
        this.props.sent()
        this.props.onClose()
    }

    render(){

        const { text, country, email, name, phone, sets, quote } = this.state.formData        
        const { countries, total } = this.state
        // const {quote} = this.props
        console.log("order quote" , quote)
        return(
            <Container className="quote-order">
            <Typography variant="h4" color="primary" className="mt-0">Order quote</Typography>
                { 
                countries ? 
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField  name="sets" label="How many product sets for this course do you need?" type="number" min="1" onChange={this.updateValue} value={sets}/>
                    </FormControl>
                    <div className="table-header">Your quote list. Total products: <span>{ quote? total : "..."}</span></div>
                        <TableContainer component={Paper} style={{maxHeight: "200px"}}>
                        <Table stickyHeader aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell>Code</TableCell>
                                <TableCell >Prouct title</TableCell>
                                <TableCell align="right">Qty</TableCell>                                
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            { quote ? quote.map((item) => (
                                <TableRow key={item.id}>                                
                                    <TableCell align="left">{item.code}</TableCell>
                                    <TableCell align="left">{item.title}</TableCell>
                                    <TableCell align="right">{item.quantity}</TableCell>                                
                                </TableRow>
                            )) : ""}
                            </TableBody>
                        </Table>
                        </TableContainer>                        
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField 
                        fullWidth 
                        name="name" 
                        type="text"
                        label="Your name" 
                        value={name} 
                        required                             
                        onChange={this.updateValue}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CSelect 
                        name="country" 
                        value={country} 
                        label="Select your country" 
                        required
                        onChange={this.updateValue}
                        fullWidth
                        items={countries}
                        titleField="name"
                        valueField="alpha2Code"
                        />
                        {/* <Select name="country" value={country} label="Select your country" 
                        required
                        onChange={this.updateValue}
                        >
                            {countries.map( item => {
                                return(
                                <MenuItem value={item.alpha2Code}>{item.name}</MenuItem>
                                )
                            })}
                        </Select> */}
                    </Grid>
                    
                    <Grid item xs={12} md={6}>
                        <TextField 
                        fullWidth 
                        name="email" 
                        type="email"
                        label="Your email" 
                        value={email} 
                        required                             
                        onChange={this.updateValue}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField 
                        fullWidth 
                        name="phone" 
                        type="phone"
                        label="Your phone" 
                        value={phone}                     
                        onChange={this.updateValue}
                        />
                    </Grid>                  
                    <Grid item xs={12}>
                        <TextField 
                        fullWidth 
                        name="text" 
                        label="Additional message" 
                        value={text} 
                        required  
                        multiline
                        rows={4}
                        variant="outlined"                           
                        onChange={this.updateValue}
                        />
                    </Grid>
                    
                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" onClick={this.send}>Send</Button>
                    </Grid>
                </Grid> :
                <CircularProgress color="primary" />
    
                }
                
                </Container>
        )
    }
}

const mapStateToProps = (state) => {
    // const { showContact } = state
    // return { open: showContact };
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        onClose: () => dispatch({ type: A.HIDE_MODAL }),
        sent : () => dispatch({type: A.SHOW_MESSAGE, payload:{message: "Your request was successfully sent!", type: "success"}}), 
        error : message => dispatch({type: A.SHOW_MESSAGE, payload:{message, type: "error"}}), 
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResultOrder)