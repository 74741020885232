import React from 'react'
import { Avatar, Grid, Card, CardActionArea, CardHeader, CardContent, CardMedia, Button, Typography, Box } from '@material-ui/core'
import {Link } from 'react-router-dom'
import Image from '../horizontal-card/image'
import Reveal from '../reveal'
import './item-horizontal.scss'
const BlogItem = props => {

    let {
        spacing=2,
        title,
         link = "",
         intro,
         subtitle,
         itemClass="blog-item",
         titleClass="blog-item-title",
        //  titleTag="h3",
         images,
        //  category,
         readMoreText="Read more...",
         titleTypographyProps,
         } = props

         titleTypographyProps = titleTypographyProps || {className: titleClass, variant: "body1"}
    // let avatarLetters = "" 
    // if( category ){
    //     switch(category.publishing_cat_id){
    //         case 2:
    //             avatarLetters = "BL" 
    //             break;
    //         case 3:
    //             avatarLetters = "NW"
    //             break;
    //     }
    // }
    const image = 
    process.env.REACT_APP_MEDIA + "/images/content/" +
    (Array.isArray(images) && images[0] ? images[0] : "neulog-blog-default.jpg")
    console.log("Image:", image)

    return (
        <Reveal>
            <Grid container spacing={spacing} className={`${itemClass} item-horizontal`} >
                <Grid item xs={12} sm={6} md={4}>
                    <Box component={Link} to={link}>
                        <Image image={image} className="ratio-image"/>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={8}>
                    <CardActionArea component={Link} to={link}>
                        <CardHeader 
                            // avatar ={
                            //     <Avatar className="bg-secondary color-white">{avatarLetters}</Avatar>
                            // }
                            titleTypographyProps={ titleTypographyProps }
                            title={title} 
                            subheader={subtitle}
                            className="card-header"
                        />
                    </CardActionArea>
                        <CardContent dangerouslySetInnerHTML={{__html: intro}}>
                        </CardContent>
                        <CardContent>
                            <Button variant="contained" color="primary" component={Link} to={link}>{readMoreText}</Button>
                        </CardContent>
                </Grid>
            </Grid>
            {/* <Card className={`${itemClass} item-horizontal`}>
                <CardActionArea className="content" component={Link} to={link}>
                    <CardMedia className="image" image={image}></CardMedia>
                    <CardContent className="text">

                        <CardHeader 
                            // avatar ={
                            //     <Avatar className="bg-secondary color-white">{avatarLetters}</Avatar>
                            // }
                            titleTypographyProps={ {className: titleClass, variant: "body1"} }
                            title={title} 
                            subheader={subtitle}
                        />
                        <CardContent dangerouslySetInnerHTML={{__html: intro}}>
                        </CardContent>
                        <CardContent>
                            <Button variant="contained" color="primary" component={Link} to={link}>{readMoreText}</Button>
                        </CardContent>
                    </CardContent>
                </CardActionArea>
            </Card> */}
        </Reveal>
    )
}

export default BlogItem