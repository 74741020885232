import React from 'react'
import {Typography, Container, Box, Grid, Paper} from '@material-ui/core'
import List from '../../list/simpleList'
import CourseItem from '../../horizontal-card/index'
import {H} from '../../template/headers'


const Item = props => <Grid item {...props.itemProps}><CourseItem {...props}/></Grid>
// const mediaRoot = process.env.REACT_APP_MEDIA

const Wrap = props => props.inContainer ? <Container>{props.children}</Container> : <>{props.children}</>

const CourseList = props => {

    let {items, title, config = {}, itemProps={xs: 12}, inContainer=true, headerProps = {level: 3} } = props

    // title can be taken from config
    title = title ? title : config.title ? config.title : "Related courses"

    if( !Array.isArray(items) || (Array.isArray(items) && items.length === 0 )) return "";

    return(
        <Wrap {...{inContainer}}>
            {/* <Typography variant="h3" color="primary">{title}</Typography> */}
            <H {...headerProps}>{title}</H>
                {config.subtitle ? <Typography variant="subtitle2">{config.subtitle}</Typography> : ""}
                {config.html ? <Typography variant="body2" dangerouslySetInnerHTML={{__html: config.html}}></Typography> :"" }            
            <Grid container spacing={3}>
                {items.map(item=> <Item key={item.course_id} itemProps={itemProps} item={item}/>)}
            </Grid>
        </Wrap>
    )
}

export default CourseList