/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing.
 * Kudos to: https://davidwalsh.name/javascript-debounce-function
 */
export function debounce(func, wait, immediate) {
	var timeout;
	return function() {
        var context = this, args = arguments;
        // console.log("BDebounce called...")
		var later = function() {
            // console.log("BDebounce fired")
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};