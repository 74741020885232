const ActionTypes = {
    SHOW_CONTACT: "SHOW_CONTACT",
    HIDE_CONTACT: "HIDE_CONTACT",
    SEND_CONTACT: "SEND_MESSAGE",
    RESET_CONTACT: "RESET_MESSAGE",
    
    SHOW_MESSAGE: "SHOW_MESSAGE",
    HIDE_MESSAGE: "HIDE_MESSAGE",
    
    SHOW_MODAL: "SHOW_MODAL",
    HIDE_MODAL: "HIDE_MODAL",
    SET_TPL: "SET_TPL",
    ADD_TO_QUOTE: "ADD_TO_QUOTE",
    REMOVE_FROM_QUOTE: "REMOVE_FROM_QUOTE",
}


export default ActionTypes