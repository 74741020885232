import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Grid, GridList, Paper, CardContent, Typography } from '@material-ui/core';
import {Skeleton} from '@material-ui/lab'
import GridListTile from '@material-ui/core/GridListTile';
import ListSubheader from '@material-ui/core/ListSubheader';
import DefaultItem from '../experiments/experimentListItem'
import Reveal from '../reveal'
import Search from '../search'
import "./itemList.scss"

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: "none" //theme.palette.background.paper,
  },
  gridList: {
    width: 500,
    height: 450,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
}));

/**
 * The example data is structured as follows:
 *
 * import image from 'path/to/image.jpg';
 * [etc...]
 *
 * const tileData = [
 *   {
 *     img: image,
 *     title: 'Image',
 *     author: 'author',
 *   },
 *   {
 *     [etc...]
 *   },
 * ];
 */
// export default function ExperimentList() {
//   const classes = useStyles();

//   return (
//     <div className={classes.root}>
//       <GridList cellHeight={180} className={classes.gridList}>
//         <GridListTile key="Subheader" cols={2} style={{ height: 'auto' }}>Experiments</GridListTile>
//         {list.map( item => <Item item={item}/> )}


//         {/* {tileData.map(tile => (
//           <GridListTile key={tile.img}>
//             <img src={tile.img} alt={tile.title} />
//             <GridListTileBar
//               title={tile.title}
//               subtitle={<span>by: {tile.author}</span>}
//               actionIcon={
//                 <IconButton aria-label={`info about ${tile.title}`} className={classes.icon}>
//                   <InfoIcon />
//                 </IconButton>
//               }
//             />
//           </GridListTile>
//         ))} */}
//       </GridList>
//     </div>
//   );
// }

const ListSkeleton = props =>{
  const showSekeleton = props.showSekeleton || true
  const {justifyContent, xs, sm, md, lg, xl} = props
  return (
    <>
    {showSekeleton ? 
    
    <Grid container spacing={4} justify={justifyContent}>
      
    {["", "", "", "", "", ""].map((item, ind) =>
    
      <Grid
        key={ind} 
        item
        xs={xs} sm={sm} md={md} lg={lg} xl={xl}
      >
        
          <Paper>
            <Skeleton variant="rect" width="100%" height="140px" />
            <CardContent>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Typography variant="body2" style={{color: "rgba(0,0,0,0.3)"}}>Loading...</Typography>
            </CardContent>
          </Paper>         
      </Grid>)}      
    </Grid>    
    

    : "" }
    </>
  )
}

export default class itemList extends React.Component {

  render() {
    let { 
      items, 
      imageRoot, 
      additional = {}, 
      itemProps = {},
      justifyContent = "center",
      noItemsText = "No items found",
      showSearch = false,
      searchProps = {},
      spacing = 4,
      className ,
      itemClass = "list-item"
    } = this.props 

    className = `item-list${className ?  ` ${className}` : ""}`
    
    // searchProps = {...{ className: "search-container" }, ...searchProps }

    imageRoot = imageRoot || process.env.REACT_APP_MEDIA


    // Define grid
    const xs = this.props.xs || 12
    const sm = this.props.sm || xs
    const md = this.props.md || 6
    const lg = this.props.lg || 4
    const xl = this.props.xl || lg
    const height = this.props.height || 140
    const bgcolor = this.props.bgcolor || ""
    const showSekeleton = this.props.showSekeleton || true

    const skelProps = {showSekeleton,justifyContent, xs, sm, md, lg, xl}
    // console.log("Item list:", items )
    if( !Array.isArray(items) ) return <ListSkeleton {...skelProps} />

    const { ItemComponent } = this.props || DefaultItem
    // console.log("item component ", ItemComponent)
    // console.log("item list ", items)

    

    return (
      <>      
      
      <Grid container spacing={spacing} justify={justifyContent} className={className}>
        {showSearch ? <Grid item xs={12} className="search-container"><Search {...searchProps} className="search-container"/></Grid> : "" }
        
        {items.map((item, ind) =>
          <Grid
            key={ind} 
            item
            className={itemClass}
            xs={xs} sm={sm} md={md} lg={lg} xl={xl}
          >
            { items.length > 0 ?
            <ItemComponent
              {...{...itemProps,
                  item,
                  imageRoot,
                  height,
                  bgcolor,
                  additional
                }
              }
              // item={item}
              // imageRoot={imageRoot}
              // height={height}
              // bgcolor={bgcolor}
              // additional={additional}
            />
            : <Typography variant="h5" color="primary">{noItemsText}</Typography>      
            }      
            
          </Grid>)}          
      </Grid>
      
    
      </>
      
    )
  }
}