import axios from  'axios'

const api = process.env.REACT_APP_API
class ContactService {

    static async send( message ){
        try{
            await axios.post(api + "/contact", {message} )
            return true
        }catch(e){
            ContactService.error = e.response ? e.response.data : "Error sending message. Please try again."
            return false
        }
    }

    static async countries(){
        if( ContactService.country_list ) 
            return ContactService.country_list
        else {
            try{
                const {data} = await axios.get(api + "/contact/countries")
                ContactService.country_list = data
                return data
            }catch(e){
                return false
            }
        }
    }
}

ContactService.country_list = null
ContactService.error = null

export default ContactService