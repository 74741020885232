import React from 'react'
import { Container, Typography, Grid } from '@material-ui/core'
import List from '../../list/itemList'
// import products from '../../data/products.json'
import service from '../../../services/products'
import Drawer from '../../common/responsive-drawer'
import Categories from '../../common/categories'
// import catalog from '../../../data/catalog.json'
import ItemProd from '../../list/productListItem'
import CatTpl from '../products/product-category'
// import Search from '../../search'
// import { debounce } from '../../util/functions'
import './style.scss'

const imageRoot = process.env.REACT_APP_MEDIA


export default class Catalog extends React.Component {

    state = {
        own: null,
        ownTitle: "",
        nested: null,
        nestedTitle: "",
        products: null,
        path: "",
        search: "",
        searchValue: "",
        category: null,
        categoryList: null,
        categories: []
    }

    constructor(props) {
        super(props)
        // this.catalog = {
        //     items: {
        //         "": this.browse("/products/neulog", catalog)
        //     }
        // }

        // this.catalog.items[""].title = "All neulog products"


        // console.debug("catalog", this.catalog)

        // this.products = null
    }

    componentDidMount() {
        console.debug("component did mount")
        const { path = "" } = this.props.match.params
        this.setState({path})
        this.fetchCategories().then( () => this.selectCategory(path) )
    }

    componentDidUpdate(prevProps) {
        console.debug("component did update")
        if (prevProps.match.params.path !==  this.props.match.params.path) {
            console.debug("path doesnt match:",prevProps.match.params.path,this.props.match.params.path ) 
            this.selectCategory( this.props.match.params.path || "" )
        }
    }

    selectCategory( path ){
        const {categoryList} = this.state
        // console.debug("Selecting category from", categoryList)
        if( categoryList ){
            const category = categoryList.find( c => (c.path ? c.path : "/") === `/${path}`  )
            if(category){
                // console.debug("category found:", category)

                // set category in state and call filterProducts
                this.setState({category}, this.filterProducts )                
            } else {
                console.error(`Category not found for path '${path}'`)
            }
        }
    }


    async fetchCategories(){

        const categories = await service.getCategoryTree()
        const categoryList = await service.getCategories()

        this.setState({categoryList, categories })
        
    }


    async filterProducts(){
        let allProducts = await service.getAll()
        // const { search } = this.state

        const {category} = this.state

        if (Array.isArray(allProducts) && category ) {
            console.debug("Category is:", category)
            const products = allProducts.filter( 
                // filtering all products
                p => p.category.some( 
                    // only those products that have at least one of their categories...
                    c =>
                    // ...contained in current category's 'includes' list (list of all nested plus self)
                    category.category_includes.includes(c)  ))
                    // category.category_includes ? category.category_includes.includes(c) : false ))
            this.setState({products})
        }
    }


    /**
     * Browses catalog tree and returns item, indicated by root 
     * @param {string} root something like "/sense/sense-robots" (this can be a part of routing path)
     * @param {Object} parent an [items] element from catalog tree
     */
    // browse(root, parent) {

    //     if (root === "") return parent

    //     if (parent.items) {
    //         const chunks = root.split("/");

    //         // if first item is empty, remove it
    //         console.debug("root:", root, "chunks", chunks)
    //         console.debug("chunks[0]", chunks[0])
    //         if (chunks[0] == "") chunks.shift();

    //         console.debug(" product list browsing ", parent, root, chunks)
    //         const key = chunks.shift();
    //         const node = parent.items[key];

    //         if (node) {
    //             if (chunks.length === 0)
    //                 return node
    //             else {
    //                 if (node.items) {
    //                     return this.browse(chunks.join("/"), node)
    //                 }
    //             }
    //         }
    //     }

    //     return false;
    // }


    // mapToArray( map, index = "", keyField = "link" ){

    //     const {title, items} = map

    //     map = { title, [keyField]: index }
    //     // store the index as the item's field "key"
    //     // map[keyField] = index
    //     map.image = `${imageRoot}/${map.image ? map.image : 'no-image.png' }` 

    //     if( items ){
    //         map.children = Object.keys( items ).reduce( (res, key ) => {
    //             // recursion
    //             let item = this.mapToArray( items[key] , `${index}/${key}` )
                
    //             // add item to the array
    //             return [...res, ...item ]
    //         }, [])
    //     }
    //     const res = [ map ]
    //     // console.debug("Map result", res)
    //     return res

    // }


    // fetchProductsOld(){
    //     if( !this.products ){
    //         // fetch product ids 
    //         const idList  = this.fetchProductIds(catalog.products.items.neulog.items )
    //         // console.debug("id list", idList)            
    //         this.products = products.filter( item => idList.includes(item.id) ).sort( 
    //             (a,b) => {
    //                 const aa = a.title.toLowerCase()
    //                 const bb = b.title.toLowerCase()
    //                 return aa > bb ? 1 : ( aa < bb  ? -1 : 0 )
    //             }
    //         )
    //     }

    //     return this.products
    // }

    // static getDerivedStateFromProps( props, state ){
    //     const {path} = props.match.params
    //     console.debug("path", path, props.match.params)
    //     return state
    // }

    // search = (search) => {
    //         console.debug("searching for...", search)

    //         this.setState({ search })

    //     }
    
    // search = search => {
    //         console.debug("searching for...", search)
            
    //         this.setState({ searchValue: search }) 
    //         this.updateSearch(search)
            
    // }


    // updateSearch = debounce( s => 
    //     {
    //         console.debug("setting state to search value")
    //         this.setState({ search: s }) 
    //     }
    // , 300 )
    updateSearch = s => this.setState({ search: s }) 


    render() {

        // console.debug("rendering catalog")

        let { products, search, searchValue, category = {}, categories } = this.state // this.fetchProducts()
        // console.debug("products", products)
        // console.debug("Categories in render:", categories)


        search = search.toLowerCase()

        products = 
        // if products is an array (fetching has finished)...
        Array.isArray(products) ? 
        // ...then if search is empty, return all the products, otherwise...
        search === "" ? products : products.filter(
            // ...filter the products by search terms
            item => item.title.toLowerCase().includes(search) || item.id.toLowerCase().includes(search)) : 
            // if products is NOT an array, it as it is (null or false for the case of "loading..." or error state)
            products;

        // console.debug("products", products)


        return (
            <Container className="products">
                <Typography variant="h1" color="primary" gutterBottom>NeuLog Products Catalog</Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} md={3} className="categories">
                        <div className="categories-container">
                            <Drawer title="Categories">
                                <Categories children={categories} leftPartSize={2} itemProps={{ xs: 12, sm: 12 }}  />
                            </Drawer>
                            {/* <Typography variant="h5" color="primary">Categories</Typography>
                            <Tree node={this.catalog} path="/products" /> */}
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={9}>
                        <CatTpl {...{...category, subtitle: products ? `(${products.length} products)` : false} } />
                        {/* <Search callback={this.updateSearch} /> */}
                        {
                            products === false ?
                            <Typography variant="h4" color="error">Error fetching data. Please, try reloading page</Typography> :
                            <List
                                items={products}
                                sm={6}
                                md={3}
                                lg={2}
                                ItemComponent={ItemProd}
                                imageRoot={process.env.REACT_APP_MEDIA + process.env.REACT_APP_PRODUCTS_SMALL_ROOT} //"http://neulog.images/images/products/140x140/" 
                                showSearch={true}
                                searchProps={{callback: this.updateSearch}}
                            />
                        }
                    </Grid>
                </Grid>


            </Container>
        )
    }
}