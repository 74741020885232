import axios from  'axios'

const mediaRoot = process.env.REACT_APP_MEDIA

export default class CoursesService {

    static async getAll( ids = null ){

        if( !CoursesService.courses ){
            try{
                let {data} = await axios.get( process.env.REACT_APP_API + "/courses/all", { params: { ids}}  ) // pass ids as params
                
                data = data.map( item => { 
                    try{
                        const images = item.images ? JSON.parse(item.images) : []
                        item = {...item, images } 
                    } catch(e){
                        item = {...item, images: [] } 
                    }

                    return {...item,
                        link: `/labs/${item.set_slug}/${item.slug}`, 
                        image: mediaRoot + (item.images[0] || "/images/content/no-image.png"),
                        subtitle: item.grade_from && item.grade_to ? `Grade ${item.grade_from} to ${item.grade_to}` : null,
                    }
                    
                } 
                )

                
                
                CoursesService.courses = data

                // console.log(data)
                return data
            } catch ( e ){
                //TODO: log
                console.log(e)
                CoursesService.error = e.response ? e.response.data : e.message
                return false;
            }
        } else {
            return CoursesService.courses
        }

    }

    
    
    static async getProducts( id ){

        if( !CoursesService.products[ id ] ){
            try{
                let {data} = await axios.get(process.env.REACT_APP_API + "/courses/products-by-course/" + id)                
                CoursesService.products[ id ] = data
                // console.log(data)
                return data
            } catch ( e ){
                //TODO: log
                console.log("Error fetching related products:", e.code, e.message)    
                CoursesService.error = e.response ? e.response.data : e.message
                return false;
            }
        } else {
            return CoursesService.products[ id ]
        }

    }
    
    static async getExperiments( id ){

        if( !CoursesService.experiments[ id ] ){
            try{
                let {data} = await axios.get(process.env.REACT_APP_API + "/courses/experiments/" + id)
                CoursesService.experiments[ id ] = data
                // console.log(data)
                return data
            } catch ( e ){
                //TODO: log
                console.log("Error fetching related experiments:", e.code, e.message)    
                CoursesService.error = e.response ? e.response.data : e.message
                return false;
            }
        } else {
            return CoursesService.experiments[ id ]
        }

    }

    static async getDownloads( id ){

        if( !CoursesService.downloads[ id ] ){
            try{
                let {data} = await axios.get(process.env.REACT_APP_API + "/downloads/by-course/" + id)                
                CoursesService.downloads[ id ] = data
                // console.log(data)
                return data
            } catch ( e ){
                //TODO: log
                console.log("Error fetching related downloads:", e.code, e.message)    
                CoursesService.error = e.response ? e.response.data : e.message
                return false;
            }
        } else {
            return CoursesService.downloads[ id ]
        }

    }

    static async getByExperiment( id ){

        if( !CoursesService.byExperiment[ id ] ){
            try{
                let {data} = await axios.get(process.env.REACT_APP_API + "/courses/by-experiment/" + id)                

                if( Array.isArray(data) ){
                    data = data.map( item => ({
                        ...item,
                        link: `/labs/${item.set_slug}/${item.slug}`, 
                        image: mediaRoot + (item.images[0] || "/images/content/no-image.png"),
                        subtitle: item.grade_from && item.grade_to ? `Grade ${item.grade_from} to ${item.grade_to}` : null,
                    }))
                    CoursesService.byExperiment[ id ] = data                
                    return data
                }

            } catch ( e ){
                //TODO: log
                console.log("Error fetching experiment-related courses:", e.code, e.message)    
                CoursesService.error = e.response ? e.response.data : e.message
                return false;
            }
        } else {
            return CoursesService.byExperiment[ id ]
        }

    }

    static async getOne( id ){
        // if( !CoursesService.courses ){
        //     await ExperimentsService.getAll()
        //     return ExperimentsService.courses.find( item => item.code === id ) || {}
        // } else {
        //     return ExperimentsService.courses.find( item => item.code === id ) || {}
        // }

        if( !CoursesService.items[id] ){
            try{
                let {data} = await axios.get(process.env.REACT_APP_API + "/courses/item/"+id)                
                if( Array.isArray(data) ){
                    CoursesService.items[id] = data
                    // console.log(data)
                    return data
                } else {
                    CoursesService.error = "Error fetching course"
                    return false;
                }

            } catch ( e ){
                //TODO: log
                console.log(e)
                CoursesService.error = e.response ? e.response.data : e.message
                return false;
            }
        } else {
            return CoursesService.items[id]
        }
    }

    static async getOneBySlug( slug ){
        // if( !CoursesService.courses ){
        //     await ExperimentsService.getAll()
        //     return ExperimentsService.courses.find( item => item.code === id ) || {}
        // } else {
        //     return ExperimentsService.courses.find( item => item.code === id ) || {}
        // }

        if( !CoursesService.items[slug] ){
            try{
                let {data} = await axios.get(process.env.REACT_APP_API + "/courses/by-slug/"+slug)
                
                // data = data.map( item => { return {...item, images: JSON.parse(item.images) } } )
                // console.log(data)
                CoursesService.items[slug] = data

                // console.log(data)
                return data
            } catch ( e ){
                //TODO: log
                console.log(e)
                CoursesService.error = e.response ? e.response.data : e.message
                return false;
            }
        } else {
            return CoursesService.items[slug]
        }
    }
    
    
}


CoursesService.error = null
CoursesService.items = {}
CoursesService.products = {}
CoursesService.experiments = {}
CoursesService.byExperiment = {}
CoursesService.downloads = {}