import React, {useState} from 'react'
import {Switch} from 'react-router-dom'
import Route from '../util/scrollToTop' // route with auto-scroll to top on navigation
import { CircularProgress, Container } from '@material-ui/core'


const RoutesComponent = props => {

    let { routes } = props
    let [routeList, setRouteList] = useState( null ) 
    
    // routes = await routes()
    if( !routeList && typeof routes === "function" )
        routes().then( res => {
            setRouteList(res)
            console.debug("Routes received:", res)
        } )

    return (
        routeList ? 
        <Switch>            
            { routeList.map( (route, ind) => <Route {...{...route, key: route.key || `route-${ind}`}} /> )}
        </Switch>
        :
        <Container><CircularProgress /></Container>
    )
}

export default RoutesComponent;